/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getUserGroups } from '../../../API/groups';
import { IOptionValue } from '../../../interfaces';

interface Props {
    name: string;
    displayName: string;
    updateFilters: (name: string, value: any, type: string) => void;
    placeholder: string | undefined;
}

const GroupNameFilter: React.FC<Props> = ({ displayName, name, updateFilters, placeholder }) => {
    const [selectOptions, setSelectOptions] = useState<IOptionValue[]>([]);

    const getOptions = async () => {
        const res = await getUserGroups();
        if (res?.apiStatus === 'SUCCESS') {
            const data = res.myGroups;
            const optionItems = data.map((d) => ({
                value: d.id,
                label: d.name,
            }));
            setSelectOptions(optionItems);
        }
    };
    useEffect(() => {
        getOptions();
    }, []);
    return (
        <div className="select-from-options">
            <label htmlFor={name}>{displayName}</label>
            <Select
                name={name}
                id={name}
                isMulti={false}
                className="react-select"
                classNamePrefix="select"
                options={selectOptions}
                onChange={(option) => {
                    updateFilters(name, option?.value, 'SELECT_FROM_OPTION');
                }}
                placeholder={placeholder || '   '}
            />
        </div>
    );
};

export default GroupNameFilter;
