/* eslint-disable max-lines-per-function */
import React ,{useEffect, useState} from "react";

import HRBPInfoFilter from "./hrbp-filters/HRBPInfoFilters";
import { IHRBPInformation, IPaginationData, IUserOptionValue } from "../../interfaces";
import HRBPInfoTable from "./HRBPInfoTable";
import CONSTANTS from "../../constants";
import {getHRBPInfo} from "../../API/hrbp";
import { PlusIconSvg } from "../../assets/images";
import HRBPModal from "./hrbp-modal/HRBPModal";
import './HRBPInfoAdminSettings.scss'

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

const defaultIUserOptionValue: IUserOptionValue = {
    jobTitle: '',
    label: 'ALL',
    value: 0,
    role: '',
    roleId: 0,
    activeUser: true,
}

const defaultCreateHRBP: IUserOptionValue = {
    jobTitle: '',
    label: '',
    value: 0,
    role: '',
    roleId: 0,
    activeUser: true,
}

const HRBPInfoAdminSettings: React.FC = () => {
    const [hrbpInformation, sethrbpInformation] = useState<IHRBPInformation[]>([]);
    const [loadingState, setLoadingState] = useState<null>(null);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [showModal , setShowModal] = useState<boolean>(false);
    const [selectedHRBP, setselectedHRBP] = useState<IUserOptionValue>(defaultIUserOptionValue);

    const [createEntity, setCreateEntity] = useState<string>('');
    const [createHRBP, setCreateHRBP] = useState<IUserOptionValue>(defaultCreateHRBP);
    
    const update = async (pageIndex: number, pageSize: number) => {
        console.log("Update HRBP List");
        const id : number = selectedHRBP === null ? 0 : selectedHRBP.value as number;
        const limit = pageSize !== undefined ? pageSize : DEFAULT_PAGINATION_DATA.pageSize;
        const offset = limit*(pageIndex !== undefined ? pageIndex : DEFAULT_PAGINATION_DATA.pageIndex)
        const res = await getHRBPInfo(id, limit, offset);
        if(res?.apiStatus === 'SUCCESS'){
            sethrbpInformation(res.hrbpInfo);
            setPagination({
                pageIndex,
                pageSize,
                totalCount : res.count,
            })
        } else{
            sethrbpInformation([]);
        }
    }

    useEffect(() => {
        update(0,10);
    },[selectedHRBP, showModal]);

    return (
        <div className="hrbp-admin-settings">
            <div className="filter-create-button">
                    <HRBPInfoFilter 
                        hrbp={selectedHRBP}
                        updateHRBP={(hrbp) => setselectedHRBP(hrbp)}
                    />
                    <button
                            type="button"
                            className="primary-btn create-hrbp-button"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            Create
                    </button>
            </div>
            <HRBPInfoTable 
                data={hrbpInformation}
                fetchHRBPInfo={(pageIndex, pageSize) => update(pageIndex, pageSize)}
                loadingState={loadingState}
                pagination={pagination}
            />
            {showModal ? 
                <>
                    <HRBPModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        isCreate
                        entity={createEntity}
                        setEntity={setCreateEntity}
                        hrbp={createHRBP}
                        setHRBP={setCreateHRBP}
                    />
                </> 
            : null}
        </div>
    )
}

export default HRBPInfoAdminSettings;
