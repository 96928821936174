/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import {
    getUserTourDetails,
    createUserTourDetails,
    getInrojsMasterTableDetails,
} from '../../API/intojs';
import { Isteps } from '../../interfaces/introjs-mastertable';
import { getDocTypes, getPublishedDocumentTypes } from '../../API/document-type';
import { IDocTypePopUp } from '../../interfaces/document-type';

interface Props {
    stepsEnabled: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    initialStep: number;
    selectedDocType: IDocTypePopUp;
    setSelectedDocType: React.Dispatch<React.SetStateAction<IDocTypePopUp>>;
    docTypesList: IDocTypePopUp[];
    setDocTypesList: React.Dispatch<React.SetStateAction<IDocTypePopUp[]>>;
}

const CreateMemoTour: React.FC<Props> = ({
    initialStep,
    stepsEnabled,
    setStepsEnabled,
    setSelectedDocType,
    docTypesList,
    setDocTypesList,
}) => {
    const [steps, setSteps] = useState<Isteps[]>([]);

    const onExit = async () => {
        const data = await getUserTourDetails(2); // tourId is 2
        if (data?.apiStatus === 'SUCCESS' && data.response === null) {
            await createUserTourDetails(2);
        }

        setStepsEnabled(false);
        const res = await getDocTypes({ inputStr: '', sortBy: 'asc' });
        if (res?.apiStatus === 'SUCCESS' && setDocTypesList) {
            setDocTypesList(res.docTypeCard);
        }
    };

    const checkStep = (e: number) => {
        if (e === 1) {
            setSelectedDocType(docTypesList[0]);
        }
    };

    const startIntro = () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setStepsEnabled(true);
    };
    useEffect(() => {
        const fetchUserTourDetail = async () => {
            const dataUser = await getUserTourDetails(2); // tourId is 2
            const dataMaster = await getInrojsMasterTableDetails(2); // tourId is 2
            const currentTimeInSeconds = Math.floor(Date.now());

            if (dataMaster?.apiStatus === 'SUCCESS' && dataMaster.masterTableInfo !== null) {
                setSteps(dataMaster.masterTableInfo.steps);
            }

            if (
                dataUser?.apiStatus === 'SUCCESS' &&
                dataUser.response === null &&
                dataMaster &&
                currentTimeInSeconds >= dataMaster.masterTableInfo.starttime &&
                dataMaster.masterTableInfo.status &&
                (dataMaster.masterTableInfo.endtime === null ||
                    currentTimeInSeconds <= dataMaster.masterTableInfo.endtime)
            ) {
                startIntro();
            }
        };
        const fetchPublishedDocTypes = async (query: string) => {
            const res = await getPublishedDocumentTypes(query);
            if (res?.apiStatus === 'SUCCESS' && setDocTypesList) {
                setDocTypesList(res.docTypeCard);
            }
        };
        if (stepsEnabled) fetchPublishedDocTypes('memo');
        fetchUserTourDetail();
    }, [stepsEnabled]);

    return (
        <div>
            <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={initialStep}
                onBeforeChange={(e) => checkStep(e)}
                onExit={() => onExit()}
                options={{
                    tooltipClass: 'customTooltip',
                    showProgress: true,
                    showStepNumbers: true,
                    showBullets: false,
                    exitOnOverlayClick: false,
                    hideNext: false,
                    doneLabel: 'Done',
                    nextLabel: 'Next',
                    skipLabel: 'Skip',
                }}
            />
        </div>
    );
};

export default CreateMemoTour;
