/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { PlusIconSvg } from '../../assets/images';
import './ConfirmationModal.scss';
import STATIC_CONTENT from '../../constants/StaticContent';

export type IAcceptedType = 'REVERT' | 'DISCARD' | 'EMPTY_APPROVER' | 'CANCEL' | 'LEAVE';

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
    type: IAcceptedType;
    isDCO?: boolean;
}

const MESSAGES = {
    REVERT: {
        buttonName: 'Revert',
        heading: 'Are you sure you want to Revert to the previous version?',
        subHeading: 'All changes in the current version will be lost, including the version itself.',
    },
    DISCARD: {
        buttonName: 'Delete',
        heading: 'Are you sure you want to Delete',
        subHeading: 'The document would be lost permanently',
    },
    EMPTY_APPROVER: {
        buttonName: 'Continue',
        heading: 'Are you sure to continue?',
        subHeading:
            'There are no approvers added to the document. Are you sure you want to continue with submission?',
    },
    CANCEL: {
        buttonName: 'Yes',
        heading: 'Are you sure you want to Discard',
        subHeading: 'All unsaved changes would be lost',
    },
    LEAVE: {
        buttonName: 'Leave',
        heading: 'Do you want to leave before Saving',
        subHeading: 'All changes would be lost',
    },
};

/* eslint-disable max-lines-per-function */
const ConfirmationModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    type,
    onConfirm,
    isDCO,
}) => {
    const [btnClicked, setBtnCLicked] = useState(false);
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose(false)}
            contentLabel="confirmation modal"
            className="confirmation__modal"
        >
            <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
            <div className="heading">
                <h1>{`${MESSAGES[type].heading}`}</h1>
                <h4>
                    {type === 'EMPTY_APPROVER' && isDCO ? 'There are no approvers added to the DCO. Are you sure you want to continue with submission?' :
                        isDCO ? `The DCO would be lost permanently` :
                            `${MESSAGES[type].subHeading}`}
                </h4>
            </div>
            <div className="buttons">
                <button type="button" onClick={() => onModalClose(false)}>
                    {type === 'CANCEL' ? 'No' : `${STATIC_CONTENT.CONFIRMATION.BUTTON.CANCEL}`}
                </button>
                <button
                    type="button"
                    className="primary-btn"
                    disabled={btnClicked}
                    onClick={() => {
                        setBtnCLicked(true);
                        onConfirm();
                        onModalClose(false);
                    }}
                >
                    {`${MESSAGES[type].buttonName}`}
                </button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
