import React, { useEffect, useRef, useState } from 'react';
import Tree, { Orientation, RawNodeDatum } from 'react-d3-tree';
import * as XLSX from 'xlsx';
import Skeleton from 'react-loading-skeleton';
import {
    fetchChildReferenceDocumentsTreeNodes,
    fetchParentReferenceDocumentsTreeNodes,
} from '../../API/document';
import { IRefDocsData } from '../../interfaces/document';
import './RefDocsTreeNodes.scss';
import { ExcelIcon, HorizontalView, MinusIcon, PlusIcon, VerticalView } from '../../assets/images';

interface Props {
    activeDocVersionId: number;
}

const { innerHeight, innerWidth } = window;

const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
}: {
    nodeDatum: any;
    toggleNode: any;
    foreignObjectProps: any;
}) => (
    <g>
        <foreignObject {...foreignObjectProps}>
            <div
                className="nodeDiv"
                onClick={toggleNode}
                onKeyDown={toggleNode}
                role="button"
                tabIndex={0}
            >
                <h3 style={{ textAlign: 'center' }}>{nodeDatum.name}</h3>
            </div>
        </foreignObject>
    </g>
);

const SkeletonFields = (): JSX.Element => (
    <div>
        <Skeleton width="10%" />
    </div>
);

// eslint-disable-next-line max-lines-per-function
const RefDocsTreeNodes: React.FC<Props> = ({ activeDocVersionId }) => {
    const [refParentRefDocsData, setParentRefDocsData] = useState<IRefDocsData>({ name: '' });
    const [refChildRefDocsData, setChildRefDocsData] = useState<IRefDocsData>({ name: '' });
    const [parentData, setParentData] = useState<RawNodeDatum | RawNodeDatum[]>({ name: '' });
    const [childData, setChildData] = useState<RawNodeDatum | RawNodeDatum[]>({ name: '' });
    const [view, setView] = useState<Orientation | undefined>('horizontal');
    const [loader, setLoader] = useState(true);
    const [zoomValue, setZoomValue] = useState(1);
    const getReferenceTreeNodes = async () => {
        const res = await fetchParentReferenceDocumentsTreeNodes(activeDocVersionId);
        setParentRefDocsData(res?.data ? res.data : { name: '' });
        const resp = await fetchChildReferenceDocumentsTreeNodes(activeDocVersionId);
        setChildRefDocsData(resp?.data ? resp.data : { name: '' });
        setLoader(false);
    };

    useEffect(() => {
        getReferenceTreeNodes();
    }, []);

    useEffect(() => {
        setParentData(refParentRefDocsData);
    }, [refParentRefDocsData]);

    useEffect(() => {
        setChildData(refChildRefDocsData);
    }, [refChildRefDocsData]);
    const treeParentContainer = useRef<HTMLDivElement>(null);
    const treeChildContainer = useRef<HTMLDivElement>(null);
    const parentTree = useRef(null);
    const childTree = useRef(null);

    const [parentDimensions, setParentDimensions] = useState({
        width: innerWidth,
        height: innerHeight,
    });
    const [childDimensions, setChildDimensions] = useState({
        width: innerWidth,
        height: innerHeight,
    });
    const [parentTranslate, setParentTranslate] = useState({ x: 0, y: 0 });
    const [childTranslate, setChildTranslate] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (treeParentContainer.current) {
            setParentDimensions(treeParentContainer.current.getBoundingClientRect());
        }
    }, [treeParentContainer]);

    useEffect(() => {
        if (treeChildContainer.current) {
            setChildDimensions(treeChildContainer.current.getBoundingClientRect());
        }
    }, [treeChildContainer]);

    useEffect(() => {
        setParentTranslate({
            x: parentDimensions.width / 2 + 100,
            y: parentDimensions.height / 2 / 2 + 50,
        });
    }, [parentDimensions]);

    useEffect(() => {
        setChildTranslate({
            x: childDimensions.width / 2 - 200,
            y: childDimensions.height / 2 / 2,
        });
    }, [childDimensions]);

    useEffect(() => {
        if (view === 'horizontal') {
            setParentTranslate({
                x: parentDimensions.width / 2 + 100,
                y: parentDimensions.height / 2 / 2 + 50,
            });
            setChildTranslate({
                x: childDimensions.width / 2 - 200,
                y: childDimensions.height / 2 / 2,
            });
        } else {
            setParentTranslate({
                x: parentDimensions.width / 2 + 100,
                y: parentDimensions.height / 2 / 2 + 300,
            });
            setChildTranslate({
                x: childDimensions.width / 2 - 100,
                y: childDimensions.height / 2 / 2 - 200,
            });
        }
    }, [view]);

    const nodeSize = { x: 200, y: 200 };
    const foreignObjectProps = {
        width: nodeSize.x,
        height: nodeSize.y,
        x: -77,
        y: -20,
    };

    const helperChildrenFunc = (
        updatedArray: { DOCUMENT_NUMBER: string; PARENTS: string; CHILDREN: string }[],
        data: any,
    ): { DOCUMENT_NUMBER: string; PARENTS: string; CHILDREN: string }[] => {
        const obj = { DOCUMENT_NUMBER: '', PARENTS: '', CHILDREN: '' };
        if (data) {
            obj.DOCUMENT_NUMBER = data.name || '';
            // eslint-disable-next-line no-nested-ternary
            obj.PARENTS = data.parent === 'root' ? '-' : data.parent ? data.parent : '-';
            const childArray = data.children || [];
            const childrenArray =
                data.children &&
                data.children.length > 0 &&
                data.children.map((child: any) => child.name);
            obj.CHILDREN = childrenArray ? childrenArray.toString() : '-';
            updatedArray.push(obj);
            if (childArray.length > 0) {
                childArray.map((child: any) => helperChildrenFunc(updatedArray, child));
            }
        }
        return updatedArray;
    };

    const helperParentsFunc = (
        updatedArray: { DOCUMENT_NUMBER: string; PARENTS: string; CHILDREN: string }[],
        data: any,
    ): { DOCUMENT_NUMBER: string; PARENTS: string; CHILDREN: string }[] => {
        const obj = { DOCUMENT_NUMBER: '', PARENTS: '', CHILDREN: '' };
        if (data) {
            obj.DOCUMENT_NUMBER = data.name || '';
            obj.CHILDREN = data.parent ? data.parent : '-';
            const parentArray = data.children || [];
            const parentsArray =
                data.children &&
                data.children.length > 0 &&
                data.children.map((child: any) => child.name);
            obj.PARENTS = parentsArray ? parentsArray.toString() : '-';
            updatedArray.push(obj);
            if (parentArray.length > 0) {
                parentArray.map((parent: any) => helperParentsFunc(updatedArray, parent));
            }
        }
        return updatedArray;
    };

    const excelHandler = () => {
        const childrenData = helperChildrenFunc([], refChildRefDocsData);
        const parentsData = helperParentsFunc([], refParentRefDocsData);
        const docArray = childrenData.shift();
        const parentDataCopy = [...parentsData];
        const targetIndex = parentsData.findIndex(
            (f) => docArray && f.DOCUMENT_NUMBER === docArray.DOCUMENT_NUMBER,
        );
        parentDataCopy[targetIndex].CHILDREN = docArray ? docArray.CHILDREN : '-';
        const data = [...parentDataCopy, ...childrenData];
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'DataSheet.xlsx');
    };

    const changeOrientation = () => {
        if (view === 'horizontal') {
            setView('vertical');
        } else {
            setView('horizontal');
        }
    };

    const handleZoomIn = () => {
        setZoomValue(zoomValue + 0.3);
    };

    const handleZoomOut = () => {
        if (zoomValue - 0.3 <= 1) {
            setZoomValue(1);
        } else {
            setZoomValue(zoomValue - 0.3);
        }
    };

    return (
        <>
            <div
                id="parentTreeWrapper"
                ref={treeParentContainer}
                style={{ width: innerWidth - 400, height: innerHeight - 300 }}
                className="parentTree"
            >
                <div className="refer-title">
                    <h1>Parent Documents</h1>
                    <div className="tab ref-tab">
                        <button
                            type="button"
                            onClick={changeOrientation}
                            title="Change Orientation"
                        >
                            {view === 'horizontal' ? <VerticalView /> : <HorizontalView />}
                        </button>
                        <button type="button" onClick={excelHandler} title="Download Excel">
                            <ExcelIcon />
                        </button>
                        <button type="button" title="Zoom In" onClick={handleZoomIn}>
                            <PlusIcon title="Zoom In" />
                        </button>
                        <button type="button" title="Zoom Out" onClick={handleZoomOut}>
                            <MinusIcon />
                        </button>
                    </div>
                </div>
                {loader ? (
                    <div className="ref-loading-state">
                        <SkeletonFields />
                    </div>
                ) : (
                    <Tree
                        data={parentData}
                        ref={parentTree}
                        translate={parentTranslate}
                        enableLegacyTransitions
                        nodeSize={nodeSize}
                        orientation={view}
                        rootNodeClassName="node__root"
                        branchNodeClassName="node__branch"
                        leafNodeClassName="node__leaf"
                        pathClassFunc={() => 'node__link'}
                        renderCustomNodeElement={(rd3tProps) =>
                            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                        }
                        depthFactor={-200}
                        zoom={zoomValue}
                        scaleExtent={{ min: 1 }}
                        zoomable={false}
                    />
                )}
            </div>
            <div
                id="childTreeWrapper"
                ref={treeParentContainer}
                style={{ width: innerWidth - 400, height: innerHeight - 200 }}
                className="childTree"
            >
                <div className="refer-title">
                    <h1>Child Documents</h1>
                </div>
                {loader ? (
                    <div className="ref-loading-state">
                        <SkeletonFields />
                    </div>
                ) : (
                    <Tree
                        data={childData}
                        ref={childTree}
                        translate={childTranslate}
                        enableLegacyTransitions
                        nodeSize={nodeSize}
                        orientation={view}
                        rootNodeClassName="node__root"
                        branchNodeClassName="node__branch"
                        leafNodeClassName="node__leaf"
                        pathClassFunc={() => 'node__link'}
                        renderCustomNodeElement={(rd3tProps) =>
                            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                        }
                        zoom={zoomValue}
                        scaleExtent={{ min: 1 }}
                        zoomable={false}
                    />
                )}
            </div>
        </>
    );
};

export default RefDocsTreeNodes;
