import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { documentAction } from '../../API/document';
import { PlusIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import { IButtonStatus } from '../../interfaces';
import { IDocumentActionTypes, IVersionNumberInfo } from '../../interfaces/document';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    documentId: number;
}

const { DOC_TYPE_REVISE } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const DocusignModal: React.FC<Props> = ({ isModalOpen, documentId, onModalClose }) => {
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [versionType, setVersionType] = useState<'MAJOR' | 'MINOR' | 'CUSTOM' >('MINOR');
    const { docTypeInfo, versionNumberInfo } = useDocumentContext();
    const [minorV, setMinorV] = useState<number>(versionNumberInfo.minorVersionNumber+1);
    const [majorV, setMajorV] = useState<number>(versionNumberInfo.majorVersionNumber);
    const history = useHistory();
    
    const onClick = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const customVersionObj: IVersionNumberInfo = {
            minorVersionNumber: minorV,
            majorVersionNumber: majorV
        }
        const res = await documentAction(documentId, type, docTypeInfo, undefined, versionType, undefined, customVersionObj);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(DOC_TYPE_REVISE.TOAST.SUCCESS_MSG.REVISED_SUCCESSFULLY);
            history.push(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
        } else {
            toast.error(DOC_TYPE_REVISE.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
        onModalClose(false);
    };

    return (
        <div className="action-modal">
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="action__modal"
            >
                <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
                <div className="revise-modal">
                    <h4>There is a mail pending for docusign signature in your mailBox. Please sign it from there to approve this contract.</h4>
                </div>
            </Modal>
        </div>
    );
};

export default DocusignModal;
