/* eslint-disable max-lines-per-function */
import { getMilliseconds } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { createComment, deleteComment, getComments, updateComment } from '../../API/comment';
import { SkeletonText } from '../../components/Skeleton';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';
import { useEventsHeight } from '../../contexts/events-height-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IPageType, IUserType } from '../../interfaces';
import {
    IApprovalDocStage,
    IComment,
    IDocumentActionTypes,
    IDocumentInfo,
} from '../../interfaces/document';
import { IDocumentTypeInfo } from '../../interfaces/document-type';
import { convertEpochToDate, formatDate, formatTime } from '../../utils/date';
import './DocComments.scss';

const { DOC_COMMENTS } = STATIC_CONTENT;

const Comment: React.FC<{
    commentInfo: IComment;
    userId: number;
    fetchComments: () => Promise<void>;
    latestDocVersionId: number;
    currentlyOpenedDocVersionId: number;
    pageType: IPageType;
    docTypeInfo: IDocumentTypeInfo;
    docInfo: IDocumentInfo;
}> = ({
    commentInfo,
    userId,
    fetchComments,
    latestDocVersionId,
    currentlyOpenedDocVersionId,
    pageType,
    docTypeInfo,
    docInfo,
}) => {
    const [isEditable, setIsEditable] = useState(false);
    const [comment, setComment] = useState(commentInfo.comment);
    const [editedCommentType, setEditedCommentType] = useState<IDocumentActionTypes>('');

    const removeComment = async () => {
        const res = await deleteComment(commentInfo.id);
        if (res?.apiStatus === 'SUCCESS') {
            fetchComments();
        } else {
            toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
    };

    const saveEditedComment = async () => {
        const res = await updateComment(
            commentInfo.id,
            `${CONSTANTS.RANDOM_COMMENT_STRINGS[editedCommentType]}${comment}`,
        );
        if (res?.apiStatus === 'SUCCESS') {
            fetchComments();
            setIsEditable(false);
        } else {
            toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
    };

    return (
        <div className="comment">
            <span className="comment--title">
                {commentInfo.commenterId === userId ? (
                    'Me'
                ) : (
                    <SkeletonText text={commentInfo.commenterName} width={100} />
                )}
                ,{' '}
                {getMilliseconds(commentInfo.updatedDate) === 1 ? (
                    <Skeleton width={70} />
                ) : (
                    formatDate(commentInfo.updatedDate)
                )}{' '}
                at{' '}
                {getMilliseconds(commentInfo.updatedDate) === 1 ? (
                    <Skeleton width={50} />
                ) : (
                    formatTime(commentInfo.updatedDate)
                )}{' '}
                {commentInfo.commentEdited ? ' \u00b7 Edited' : ''}
            </span>
            {isEditable ? (
                <textarea
                    name="comment"
                    id="comment"
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            ) : (
                <p className={commentInfo.commentType.toLowerCase()}>
                    <pre>{commentInfo.comment}</pre>
                </p>
            )}
            {commentInfo.commenterId === userId &&
                !(docTypeInfo.restrictCommentForPublished && docInfo.docState === 'PUBLISHED') && (
                    <div>
                        {isEditable ? (
                            <>
                                <button
                                    type="button"
                                    className="link"
                                    onClick={() => saveEditedComment()}
                                >
                                    {DOC_COMMENTS.BUTTON.SAVE}
                                </button>
                                <button
                                    type="button"
                                    className="link"
                                    onClick={() => {
                                        setIsEditable(false);
                                        setComment(commentInfo.comment);
                                    }}
                                >
                                    {DOC_COMMENTS.BUTTON.CANCEL}
                                </button>
                            </>
                        ) : (
                            <>
                                {(currentlyOpenedDocVersionId === latestDocVersionId ||
                                    pageType === 'TASK_DETAILS') && (
                                    <>
                                        <button
                                            type="button"
                                            className="link"
                                            onClick={() => {
                                                setIsEditable(true);
                                                setEditedCommentType(commentInfo.commentType);
                                            }}
                                        >
                                            {DOC_COMMENTS.BUTTON.EDIT}
                                        </button>
                                        {/* <button
                                        type="button"
                                        className="link"
                                        onClick={() => removeComment()}
                                    >
                                        {DOC_COMMENTS.BUTTON.DELETE}
                                    </button> */}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
        </div>
    );
};

interface Props {
    activeDocVersion: number;
    setUnsavedCommentFunction: any;
    allowReviseByAnyone: boolean;
    userType: IUserType;
    pageType: IPageType;
    approvers: IApprovalDocStage[];
}

const defaultComments = () => {
    const comments: IComment[] = [];
    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < 3; i++) {
    //     comments.push({
    //         comment: CONSTANTS.LOADING.TEXT,
    //         commentEdited: false,
    //         commentType: '',
    //         commenterId: CONSTANTS.LOADING.NUMBER,
    //         commenterName: CONSTANTS.LOADING.TEXT,
    //         updatedDate: CONSTANTS.LOADING.DATE,
    //         id: CONSTANTS.LOADING.NUMBER,
    //     });
    // }

    return comments;
};

const DocComments: React.FC<Props> = ({
    activeDocVersion,
    setUnsavedCommentFunction,
    allowReviseByAnyone,
    userType,
    pageType,
    approvers,
}) => {
    const { user } = useAuthDataContext();
    const [comments, setComments] = useState<IComment[]>(defaultComments());
    const [newComment, setNewComment] = useState('');
    const [saving, setSaving] = useState(false);
    const { updateHeight } = useEventsHeight();
    const { activeDocVersionId, docInfo, docTypeInfo, submittedOn } = useDocumentContext();
    const fetchComments = async () => {
        if (activeDocVersion) {
            const commentsData = await getComments(activeDocVersion, allowReviseByAnyone);
            if (commentsData?.apiStatus === 'SUCCESS') {
                setComments(commentsData.comments);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    useEffect(() => {
        updateHeight();
    });

    const addNewComment = async () => {
        if (newComment === '') {
            toast.warn(DOC_COMMENTS.TOAST.WARNING_MSG.ADD_COMMENT_FIRST);
        } else {
            setSaving(true);
            const res = await createComment(activeDocVersion, newComment, allowReviseByAnyone);
            if (res?.apiStatus === 'SUCCESS') {
                fetchComments();
                setNewComment('');
                setUnsavedCommentFunction('');
            } else {
                toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocVersion, submittedOn, docTypeInfo.showCommentsAfterSubmitDate]);

    return docTypeInfo.restrictCommentForPublished &&
        docInfo.docState === 'PUBLISHED' &&
        comments.length <= 0 ? null : (
        <div className="doc-comments">
            {(userType === 'OTHER' && comments.length) || userType !== 'OTHER' ? (
                <h3>Comments {comments.length > 0 ? `(${comments.length})` : ''}</h3>
            ) : (
                ''
            )}
            <div className="comments-list">
                {comments.length > 0 &&
                    comments.map((comment) => (
                        <Comment
                            commentInfo={comment}
                            userId={user.id}
                            key={comment.id}
                            fetchComments={fetchComments}
                            latestDocVersionId={activeDocVersionId}
                            currentlyOpenedDocVersionId={activeDocVersion}
                            pageType={pageType}
                            docTypeInfo={docTypeInfo}
                            docInfo={docInfo}
                        />
                    ))}
            </div>
            {userType !== 'OTHER' &&
                !(docTypeInfo.restrictCommentForPublished && docInfo.docState === 'PUBLISHED') && (
                    <div className="add-comment">
                        <h5>{DOC_COMMENTS.LABEL.WRITE_A_COMMENT}</h5>
                        <textarea
                            name="comment"
                            id="comment"
                            rows={3}
                            value={newComment}
                            onChange={(e) => {
                                setNewComment(e.target.value);
                                setUnsavedCommentFunction(e.target.value);
                            }}
                        />
                        <button
                            type="button"
                            className="submit-comment"
                            onClick={() => addNewComment()}
                            disabled={saving}
                        >
                            {DOC_COMMENTS.BUTTON.SUBMIT_COMMENT}
                        </button>
                    </div>
                )}
        </div>
    );
};

export default DocComments;