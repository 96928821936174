/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import { DocumentIconSvg } from '../../assets/images';
import './SunsetNav.scss';
import SunsetProvider, { useSunsetContext } from '../../contexts/sunset-provider';
import SunsetTable from '../../widgets/sunset-table/SunsetTable';

const SunsetTabs: React.FC = () => {
    const {
        activeTab,
        setActiveTab,
        documentsCount: { mySunsetDocs, mySunsetTasks },
    } = useSunsetContext();
    return (
        <>
            {activeTab ? (
                <div className="tab">
                    <button
                        id="MY_SUNSET_DOCS"
                        type="button"
                        className={`${activeTab === 'MY_SUNSET_DOCS' ? 'active' : ''}`}
                        onClick={() => setActiveTab('MY_SUNSET_DOCS')}
                    >
                        <DocumentIconSvg className="icon" />
                        <span className="name">My Sunset Documents</span>
                        {mySunsetDocs > 0 && <span className="count">{`(${mySunsetDocs})`}</span>}
                    </button>
                    <button
                        id="MY_SUNSET_TASKS"
                        type="button"
                        className={`${activeTab === 'MY_SUNSET_TASKS' ? 'active' : ''}`}
                        onClick={() => setActiveTab('MY_SUNSET_TASKS')}
                    >
                        <DocumentIconSvg className="icon" />
                        <span className="name">My Sunset Tasks</span>
                        {mySunsetTasks > 0 && <span className="count">{`(${mySunsetTasks})`}</span>}
                    </button>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

const SunsetPage: React.FC = () => (
    <div className="sunset">
        <SunsetProvider>
            <SunsetTabs />
            <div className="section">
                <div className="doc-list">
                    <SunsetTable />
                </div>
            </div>
        </SunsetProvider>
    </div>
);

export default SunsetPage;
