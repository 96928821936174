import React, { useEffect, useMemo } from 'react';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import {
    IAdminDocuments,
    IDocStatus,
    IOwnerChange,
    IPaginationData,
    ISort,
    IStringObject,
} from '../../interfaces';
import CONSTANTS from '../../constants';
import { SkeletonText } from '../../components/Skeleton';
import { DateColumn, NoDocumentsFound } from '../../components/General';
import './AdminDocTable.scss';
import Pagination from '../../components/pagination/Pagination';
import { SortIcon } from '../../assets/images';
import { ownerChangeString } from '../../utils';

const DocNumberDocument = (row: Row<IAdminDocuments>) => {
    const {
        original: { docNumber, documentId },
    } = row;
    const clickHandler = async () => {
        document.body.classList.remove('admin-side-navigation');
    };
    return (
        <Link
            className="link-style"
            style={{ textDecoration: 'none' }}
            to={{
                pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                search: `?doc_id=${documentId}&prev_page_type=ADMIN_DOC`,
            }}
            onClick={clickHandler}
        >
            <SkeletonText text={docNumber} width={200} />
        </Link>
    );
};

const columnMapping: IStringObject = {
    docNumber: 'documentNumber',
    updatedAt: 'approvedOn',
};

const COLUMNS: Column<IAdminDocuments>[] = [
    {
        Header: ({ handleSelectAll, selectAll }) => (
            <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll(!selectAll)}
            />
        ),
        accessor: 'documentId',
        Cell: ({
            value,
            handleSingleCheckBox,
            SelectedDocuments,
            row: {
                original: { ownerId, documentId, status, docNumber },
            },
        }) => (
            <input
                type="checkbox"
                id={value.toString()}
                disabled={!documentId}
                checked={
                    SelectedDocuments.map((d: IOwnerChange) =>
                        ownerChangeString(d.documentId, d.oldOwnerId),
                    ).indexOf(ownerChangeString(value, ownerId)) !== -1
                }
                onChange={() => handleSingleCheckBox(value, ownerId, status, docNumber)}
            />
        ),
        disableSortBy: true,
    },
    {
        Header: 'Document Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberDocument {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Document Type',
        accessor: 'documentType',
        Cell: ({ value }) => <SkeletonText text={value} width={140} />,
        minWidth: 140,
        disableSortBy: true,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ value }) => <SkeletonText text={value} width={140} />,
        minWidth: 140,
        disableSortBy: true,
    },
    // {
    //     Header: 'Description',
    //     accessor: 'description',
    //     Cell: ({ value }) => <SkeletonText text={value} width={140} />,
    //     minWidth: 140,
    //     disableSortBy: true,
    // },
    {
        Header: 'Owner',
        accessor: 'owner',
        Cell: ({ value }: { value: { name: string; email: string } }) => (
            <SkeletonText text={typeof value === 'object' ? value.name : value} width={150} />
        ),
        minWidth: 200,
        disableSortBy: true,
    },
    {
        Header: 'PublishedOn',
        accessor: 'updatedAt',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: 200,
    },
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: ({ value }) => <StatusColumn value={value} />,
    //     minWidth: 200,
    //     disableSortBy: true,
    // },
];

const DEFAULT_SORT: ISort = {
    id: 'updatedAt',
    desc: true,
};

interface Props {
    data: IAdminDocuments[];
    selectAll: boolean;
    SelectedDocuments: IOwnerChange[];
    handleSelectAll: (selectAll: boolean) => void;
    handleSingleCheckBox: (
        documentId: number,
        oldOwnerId: number,
        state: IDocStatus,
        docNumber: string,
    ) => void;
    pagination: IPaginationData;
    fetchDocuments: (
        pageIndex: number,
        pageSize: number,
        order: string,
        orderType: string | undefined,
    ) => void;
}

// eslint-disable-next-line max-lines-per-function
const AdminDocTable: React.FC<Props> = ({
    data,
    pagination,
    fetchDocuments,
    selectAll,
    SelectedDocuments,
    handleSelectAll,
    handleSingleCheckBox,
}) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IAdminDocuments>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableSortBy: false,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
            selectAll,
            SelectedDocuments,
            handleSelectAll,
            handleSingleCheckBox,
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        fetchDocuments(
            pageIndex,
            pageSize,
            columnMapping[sortBy[0].id],
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);

    return (
        <div className="document-admin-table">
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth
                                                    ? column.minWidth
                                                    : 'auto',
                                            },
                                        })}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${column.isSortedDesc ? 'desc' : 'asc'
                                                        } `}
                                                />
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            className={cell.column.id}
                                            {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
                {page.length === 0 && <NoDocumentsFound heading="No Documents Found" />}
            </div>
        </div>
    );
};

export default AdminDocTable;
