import React, { useEffect, useState } from 'react';
import AdminDocTypeTable from '../../widgets/admin-doc-type-table/AdminDocTypeTable';
import { IDocTypeCard } from '../../interfaces/document-type';
import { getAllDocumentTypes } from '../../API/document-type';
import { IAdminDocumentTypeConfigTable } from '../../interfaces';

const AdminDocTypeTablePage: React.FC = () => {
    const [docTypeData, setDocTypeData] = useState<IDocTypeCard[]>([]);
    const fetchData = async () => {
        const res = await getAllDocumentTypes();
        if (res?.apiStatus === 'SUCCESS') {
            setDocTypeData(res.docTypeCard);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const configData: IAdminDocumentTypeConfigTable[] = [];
    const obj: any = {};
    if (docTypeData && docTypeData.length > 0) {
        docTypeData.forEach((docType) => {
            obj.config = docType.config;
            obj.config.documentType = docType.name;
            obj.config.id = docType.id;
            obj.config.activeVersion = docType.activeVersion;
            const prefix = docType.documentNumberStrategy.prefixes.find((o) => o.type === 'static');
            obj.config.prefixCode = prefix?.value ? prefix.value : '';
            obj.config.generationTrigger = docType.documentNumberStrategy.generationTrigger;
            if (docType.documentNumberStrategy.prefixes.length > 0) {
                obj.config.strategyUserInitials = Boolean(
                    docType.documentNumberStrategy.prefixes.find((p) => p.type === 'dynamic'),
                );
            }
            obj.config.createdDate = docType.createdDate;
            obj.config.lastUpdateDate = docType.lastUpdateDate;
            obj.config.status = docType.status;
            obj.config.totalDocuments = docType.totalDocuments;
            configData.push(obj.config);
        });
    }
    return <AdminDocTypeTable docTypeData={configData} />;
};

export default AdminDocTypeTablePage;
