import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import { CopyIconSvg, LinkIcon } from '../assets/images';
import { copyToClipBoard } from '../utils';
import { SkeletonText } from './Skeleton';

interface Props {
    text: string;
    // eslint-disable-next-line react/require-default-props
    docTypeBackgroundColor?: string;
    /* eslint-disable react/require-default-props */
    link?: string;
    differentIcon?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const CopyText = ({ text,docTypeBackgroundColor,link, differentIcon }: Props): JSX.Element => {
    const [copied, setCopied] = useState(false);
    const [props, api] = useSpring(() => ({
        backgroundColor: differentIcon ? '#fff' : '#f3f3f3',
        cursor: 'pointer'
    }));

    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
        usePopperTooltip({
            placement: 'bottom',
            trigger: 'click',
            closeOnOutsideClick: false,
            visible: copied,
            onVisibleChange: setCopied,
        });

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 1000);
        }
    }, [copied]);

    return (
        <>
            {differentIcon ? (
                <button type='button' style={{all: 'unset', cursor: 'pointer'}}
                    onClick={() => {
                        setCopied(true);
                        if (link !== undefined) {
                            copyToClipBoard(link);
                        } else {
                            copyToClipBoard(text);
                        }
                    }}
                >
                    <h4>
                        <SkeletonText text={text} width={50} />
                    </h4>
                    <span ref={setTriggerRef}>
                        <LinkIcon />
                    </span>
                    {visible && (
                        <div
                            ref={setTooltipRef}
                            {...getTooltipProps({ className: 'tooltip-container' })}
                        >
                            Link Copied
                            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                        </div>
                    )}
                </button>
            ) : (
                <animated.span
                    style={props}
                    className="copy-text"
                    onClick={() => {
                        setCopied(true);
                        if (link !== undefined) {
                            copyToClipBoard(link);
                        } else {
                            copyToClipBoard(text);
                        }
                    }}
                >
                    <h4>
                        <SkeletonText text={text} width={50} />
                    </h4>
                    <span ref={setTriggerRef}>
                        <CopyIconSvg />
                    </span>
                    {visible && (
                        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
                            Copied
                            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                        </div>
                    )}
                </animated.span>
            )}
        </>
    );
};

export default CopyText;
