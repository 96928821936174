/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { userSearch } from '../../../API/users';
import { IUserOptionValue } from '../../../interfaces';

interface Props {
    name: string;
    displayName: string;
    value: IUserOptionValue;
    updateFilters: (name: string, value: any, type: string) => void;
    placeholder: string | undefined;
}

// eslint-disable-next-line max-lines-per-function
const UserSearch: React.FC<Props> = ({ displayName, name, value, updateFilters, placeholder }) => {
    const [userListLoading, setUserListLoading] = useState(false);
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setUserListLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const users = await userSearch(inputValue);
            if (users?.apiStatus === 'SUCCESS') {
                const userList = users.users;
                userList.unshift({ jobTitle: '', label: 'All', role: '', roleId: 0, value: 0, activeUser: true });
                resolve(userList);
                setUserListLoading(false);
            }
        });
    };
    return (
        <div>
            <label htmlFor={name}>{displayName}</label>
            <AsyncSelect
                name={name}
                id={name}
                isMulti={false}
                className="react-select filter-user-search"
                classNamePrefix="select"
                loadOptions={promiseOptions}
                getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                isLoading={userListLoading}
                placeholder={placeholder || 'Start typing Author name'}
                noOptionsMessage={() => null}
                value={value}
                onChange={(option) => {
                    if (option) {
                        option.label === 'All'
                            ? updateFilters(name, null, 'USER_SEARCH')
                            : updateFilters(name, option, 'USER_SEARCH');
                    }
                }}
            />
        </div>
    );
};

export default UserSearch;
