import { useState, useLayoutEffect } from 'react';

interface IPosition {
    width: number | undefined;
    height: number | undefined;
    x: number | undefined;
    y: number | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useRefPosition = (ref: React.RefObject<HTMLDivElement>): IPosition => {
    const [position, setPosition] = useState<DOMRect>();
    useLayoutEffect(() => {
        const updatePosition = () => {
            if (ref.current) {
                setPosition(ref.current.getBoundingClientRect());
            }
        };
        window.addEventListener('resize', updatePosition);
        updatePosition();
        return () => window.removeEventListener('resize', updatePosition);
    }, [ref]);

    return { width: position?.width, height: position?.height, x: position?.x, y: position?.y };
};
export default useRefPosition;
