import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { PlusIconSvg, SortIcon } from '../../assets/images';
import { NoDocumentsFound } from '../../components/General';
import { SkeletonText } from '../../components/Skeleton';
import Pagination from '../../components/pagination/Pagination';
import CONSTANTS from '../../constants';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import './CustomReportModal.scss';

const columnMapping: IStringObject = {
    creator: 'creator',
    title: 'title',
};

const DEFAULT_SORT: ISort = {
    id: 'createdAt',
    desc: true,
};

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
};

const COLUMNS: Column[] = [
    {
        Header: '#',
        id: 'index',
        accessor: (_row: any, i: number) => i + 1,
    },
    {
        Header: ({ handleSelectAll, selectAll }) => (
            <input
                type="checkbox"
                checked={selectAll}
                onChange={() => handleSelectAll(!selectAll)}
            />
        ),
        accessor: 'documentId',
        Cell: ({ value, handleSingleCheckBox, SelectedDocuments }) => (
            <input
                type="checkbox"
                // id={value.toString()}
                // disabled={!documentId}
                // onChange={() => handleSingleCheckBox(value, inActiveApproverId)}
            />
        ),
    },
    {
        Header: 'Column',
        accessor: 'column',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Column Description',
        accessor: 'columnDescription',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 300,
        disableSortBy: true,
    },
];

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-lines-per-function
const CustomReportModal: React.FC<Props> = ({ isModalOpen, onModalClose }) => {
    const columns = useMemo(() => COLUMNS, []);
    const defaultData = [
        {
            column: 'DCO Number',
            columnDescription: 'It shows the unique number for DCO'
        },
        {
            column: 'Title',
            columnDescription: 'It shows the title of the DCO'
        },
        {
            column: 'Creator',
            columnDescription: 'It shows the creator of the DCO'
        },
        {
            column: 'Change Administrator',
            columnDescription: 'It shows the change administrator of the DCO'
        },
        {
            column: 'DCO Creation Date & Time',
            columnDescription: 'It shows the creation date of the DCO'
        },
        {
            column: 'Lifecycle Status',
            columnDescription: 'It shows the status of the DCO'
        },
        {
            column: 'Submit Count',
            columnDescription: 'It shows the submit count of the DCO'
        },
        {
            column: 'Latest Submit for Routing Date & Time',
            columnDescription: 'It shows the date DCO submitted to DCON'
        },
        {
            column: 'Latest Submit for Approval Date & Time',
            columnDescription: 'It shows the date DCON approved the DCO'
        },
        {
            column: 'Admin Response Time in Hours',
            columnDescription: 'It shows the time taken by DCON to approve the DCO'
        },
        {
            column: 'Approver',
            columnDescription: 'It shows the approver name of the DCO'
        },
        {
            column: 'Decision',
            columnDescription: 'It shows the approver decision of the DCO'
        },
        {
            column: 'Decision Date',
            columnDescription: 'It shows the date when approver taken a decision'
        }
    ]
    const [data, setData] = useState(defaultData);
    const history = useHistory();
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination,
    );

    const update = async (
        order?: string,
        orderType?: string | undefined,
    ) => {};

    useEffect(() => {
        update(
            columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'createdAt',
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose(false)}
            className="create-custom-report"
        >
            <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
            <div className="description-heading">
                <h1>Custom Report</h1>
            </div>
            <div className="report-fields">
                <div className="description-fields">
                    <div className="description-section">
                        <label className="mandatory-field">Title</label>
                        <input
                            type="text"
                            name="title"
                            className="description-value"
                            placeholder="Enter Title ..."
                            // value={customReportData.title}
                            // onChange={handleInputChange}
                        />
                    </div>
                    <div className="description-section">
                        <label>Description</label>
                        <input
                            type="text"
                            name="description"
                            className="description-value"
                            placeholder="Enter Description ..."
                            // value={customReportData.description}
                            // onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="description-fields">
                    <div className="description-section" style={{ marginTop: '20px' }}>
                        <label>Visibility</label>
                        <div className="radio-group" style={{ marginTop: '10px' }}>
                            <label style={{ marginRight: '40px' }}>
                                <input
                                    type="radio"
                                    name="visibility"
                                    value="Personal"
                                    style={{ marginRight: '5px' }}
                                />
                                Personal
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="visibility"
                                    value="Shared"
                                    style={{ marginRight: '5px' }}
                                />
                                Shared
                            </label>
                        </div>
                    </div>
                    <div className="description-section">
                        <label htmlFor="reportType">Report Type</label>
                        <Select
                            name="reportType"
                            id="reportType"
                            isMulti={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={[
                                { value: 'dco', label: 'DCO' },
                                { value: 'document', label: 'Document' },
                            ]}
                            isSearchable={false}
                            // value={{
                            //     value,
                            //     label: CONSTANTS.EFFECTIVITY[value],
                            // }}
                            // onChange={(option) => {
                            //     updateFilters(name, option?.value, 'EFFECTIVITY');
                            // }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="table-section">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted && (
                                                    <SortIcon
                                                        className={`sort ${
                                                            column.isSortedDesc ? 'desc' : 'asc'
                                                        } `}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                            ? cell.column.minWidth
                                                            : 'auto',
                                                    },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {page.length === 0 && <NoDocumentsFound heading="No Data Found" />}
                </div>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
            </div>
            <div className="action-buttons">
                <button type="submit" onClick={() => onModalClose(false)}>
                    Cancel
                </button>
                <button
                    type="submit"
                    className="primary-btn submit-button"
                    onClick={() => {
                        onModalClose(false);
                        history.push(`/reportList/report?prev_page_type=CUSTOM_REPORT`);
                    }
                }
                    // disabled={!customReportData.title}
                >
                    <span className="submit">Next</span>
                </button>
            </div>
        </Modal>
    );
};

export default CustomReportModal;