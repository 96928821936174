import { IAPIResponseStatus, IHRBPInformation } from '../interfaces';
import { getRequest, postRequest, putRequest } from '../utils/api-request';

// eslint-disable-next-line max-lines-per-function
const getHRBPInfo = async (
    id: number,
    limit = 10,
    offset = 0,
): Promise<
    undefined
    | {
        count : number;
        hrbpInfo: IHRBPInformation[];
        apiStatus: IAPIResponseStatus;
      }
> => {
    try {
        const query = `id=${id}&offset=${offset}&limit=${limit}`;
        const res = await getRequest(`hrbp-info?${query}`);
        if (res?.apiStatus === 'SUCCESS') {
            const result = [];
            const { response } = res;
            // eslint-disable-next-line no-restricted-syntax
            for (const data of response.data) {
                const info: IHRBPInformation = {
                    country: '',
                    hrbpName: '',
                    hrbpEmail: '',
                };
                info.country = data.entity;
                info.hrbpEmail = data.hrbp.email;
                info.hrbpName = data.hrbp.employeeName;
                result.push(info);
            }
            return {
                apiStatus: res.apiStatus,
                hrbpInfo: result,
                count : response.count,
            };
        }
        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

const createHRBPInfo = async (
    entity: string,
    employeeId: number,
): Promise<
    undefined
    | {
        message : string,
        apiStatus: IAPIResponseStatus;
      }
> => {
    try {
        const body = {
            entity,
            id : employeeId,
        }
        const res = await postRequest('hrbp-info',body);
        if(res?.apiStatus ==="SUCCESS"){
            return {
                apiStatus : res.apiStatus,
                message : res.response.message,
            }
        }
        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};


const updateHRBPInfo = async (
    entity: string,
    employeeId: number,
): Promise<
    undefined
    | {
        message : string,
        apiStatus: IAPIResponseStatus;
      }
> => {
    try {
        const body = {
            entity,
            id : employeeId,
        }
        const res = await putRequest('hrbp-info',body);
        if(res?.apiStatus ==="SUCCESS"){
            return {
                apiStatus : res.apiStatus,
                message : res.response.message,
            }
        }
        return undefined;
        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

export { getHRBPInfo, updateHRBPInfo, createHRBPInfo};
