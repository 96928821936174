import React from 'react';
import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';

import './ButtonSelector.scss';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const ButtonSelector: React.FC<Props> = ({ field, value, error, isDisabled, documentDispatch }) => (
    <div className={`button-selector ${error ? 'error' : ''}`}>
        {field.selectOption &&
            field.selectOption.map((option) => (
                <div className="button" key={option.value}>
                    <label
                        className={`${value === option.value ? 'checked' : ''} ${
                            isDisabled ? 'disabled' : ''
                        }`}
                        htmlFor={option.value.toString()}
                    >
                        {option.label}
                    </label>
                    <input
                        type="radio"
                        id={option.value.toString()}
                        name={field.name}
                        disabled={isDisabled}
                        checked={value === option.value}
                        onChange={() =>
                            documentDispatch &&
                            documentDispatch({
                                type: 'button-select',
                                inputName: field.name,
                                value: option.value.toString(),
                            })
                        }
                    />
                </div>
            ))}
        <div />
    </div>
);

export default ButtonSelector;
