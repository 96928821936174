import { useState, useEffect } from 'react';

interface Window {
    width: number;
    height: number;
    isMobile: boolean;
}
const useWindowSize: () => Window = () => {
    const [windowSize, setWindowSize] = useState<Window>({
        width: 0,
        height: 0,
        isMobile: false,
    });
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleResize = (): void => {
                const userAgent =
                    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
                const isMobile = Boolean(
                    userAgent.match(
                        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
                    ),
                );
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                    isMobile,
                });
            };
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);
    return windowSize;
};
export default useWindowSize;
