/* eslint-disable max-lines-per-function */
import { useState } from 'react';
import { IFileUpload } from '../interfaces/attachment';
import { getExtension } from '../utils';

const createInputComponent = (multiple: boolean, accept: string) => {
    const el = document.createElement('input');

    el.type = 'file';
    el.accept = accept;
    el.multiple = multiple;

    return el;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFileUpload = () => {
    const [files, setFiles] = useState<IFileUpload>();
    // eslint-disable-next-line @typescript-eslint/ban-types
    let userCallback: Function;

    const onChange = (ev: Event) => {
        const parsedFiles: IFileUpload[] = [];
        const target = ev.target as HTMLInputElement;

        // eslint-disable-next-line no-restricted-syntax
        for (const fileIndex in target.files) {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(parseInt(fileIndex, 10))) {
                // eslint-disable-next-line no-continue
                continue;
            }
            const file = target.files[fileIndex as unknown as number];
            const parsedFile: IFileUpload = {
                source: URL.createObjectURL(file) as unknown as URL,
                name: file.name,
                size: file.size,
                extension: getExtension(file.name),
                mimeType: file.type,
                file,
            };
            parsedFiles.push(parsedFile);
        }

        target.removeEventListener('change', onChange);
        target.remove();

        // if (target.multiple) {
        // setFiles(parsedFiles);
        //     return userCallback(parsedFiles);
        // }

        setFiles(parsedFiles[0]);
        return userCallback(parsedFiles[0]);
    };

    const onDrop = (ev: React.DragEvent<HTMLDivElement>, cb: unknown) => {
        if (typeof cb === 'function') {
            userCallback = cb;
        }
        ev.preventDefault();
        if (ev.dataTransfer && ev.dataTransfer.files) {
            const filesList = Array.from(ev.dataTransfer.files);
            const parsedFiles: IFileUpload[] = [];

            filesList.forEach((file) => {
                const parsedFile: IFileUpload = {
                    source: URL.createObjectURL(file) as unknown as URL,
                    name: file.name,
                    size: file.size,
                    extension: getExtension(file.name),
                    mimeType: file.type,
                    file,
                };
                parsedFiles.push(parsedFile);
            });

            setFiles(parsedFiles[0]);
            return userCallback(parsedFiles[0]);
        }
        return null;
    };

    const uploadFile = ({ accept } = { accept: '' }, cb: unknown) => {
        if (typeof cb === 'function') {
            userCallback = cb;
        }

        const inputEl = createInputComponent(false, accept);
        inputEl.addEventListener('change', onChange);
        inputEl.click();
    };

    return { files, uploadFile, onDrop };
};

export default useFileUpload;
