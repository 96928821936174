import React, { useState } from 'react';
import qs from 'qs';
import { toast } from 'react-toastify';
import {
    IAdminDocuments,
    IDocStatus,
    IOwnerChange,
    IPaginationData,
    IUserOptionValue,
} from '../../interfaces';
import AdminDocTable from '../../widgets/admin-doc-table/AdminDocTable';
import CONSTANTS from '../../constants';
import { getAdminDocumentList } from '../../API/adminSearch';
import { ownerChangeString } from '../../utils';
import { ownerChangeAction } from '../../API/ownerChange';
import { makeDocumentObsolete } from '../../API/document';
import ObsoleteModal from '../../components/document-admin-settings/ObsoleteModal';
import OwnerSelectModal from '../../components/document-admin-settings/OwnerSelectModal';
import './AdminDocTablePage.scss';
import AdminDocTableFilters from '../../widgets/admin-doc-table/AdminDocTableFilters';
import { fecthDirectfromDb } from '../../API/dashboard';
import WithdrawModal from '../../components/document-admin-settings/WithdrawModal';
import AdminSideNavigation from '../../components/admin-page/AdminSideNavigation';
import '../admin-dashboard/AdminDashboard.scss';

// eslint-disable-next-line max-lines-per-function
const AdminDocTablePage: React.FC = () => {
    const defaultDoc = () => {
        const Documents: IAdminDocuments[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 9; i++) {
            Documents.push({
                documentId: CONSTANTS.LOADING.NUMBER,
                docNumber: CONSTANTS.LOADING.TEXT,
                documentType: CONSTANTS.LOADING.TEXT,
                ownerId: CONSTANTS.LOADING.NUMBER,
                owner: CONSTANTS.LOADING.TEXT,
                status: 'NEW',
                updatedAt: CONSTANTS.LOADING.DATE,
                title: CONSTANTS.LOADING.TEXT,
                description: CONSTANTS.LOADING.TEXT,
            });
        }

        return Documents;
    };
    const [docList, setDocsList] = useState(defaultDoc);
    const [openModal, setOpenModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
        totalCount: 0,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [selectedDocuments, setSelectedDocuments] = useState<IOwnerChange[]>([]);
    const [selectedNewOwner, setSelectedNewOwner] = useState<IUserOptionValue | null>(null);
    const [attributeName, setAttributeName] = useState(null);
    const [operatorName, setOperatorName] = useState(null);
    const [value, setValue] = useState(null);
    const [filterDate, setDateValue] = useState<Date | null>(null);
    const [filterStartDate, setStartDateValue] = useState<Date | null>(null);
    const [filterEndDate, setEndDate] = useState<Date | null>(null);
    const [selectedAction, setSelectedAction] = useState<any>(null);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    // eslint-disable-next-line max-lines-per-function
    const update = async (
        pageIndex?: number,
        pageSize?: number,
        order?: string,
        orderType?: string | undefined,
    ) => {
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const orderBy = order || 'approvedOn';
        const sortOrder = orderType || 'DESC';
        const attribute = attributeName || null;
        const operator = operatorName || null;
        const attributeValue = attributeName !== 'publishedOn' ? value : null;
        const dateValue =
            filterDate && typeof filterDate.getMonth === 'function'
                ? filterDate.getTime() / 1000
                : null;
        const dateStartValue =
            filterStartDate && typeof filterStartDate.getMonth === 'function'
                ? filterStartDate.getTime() / 1000
                : null;
        const dateEndValue =
            filterEndDate && typeof filterEndDate.getMonth === 'function'
                ? filterEndDate.getTime() / 1000
                : null;
        if (attribute === 'documentNumber' && operator === 'eq' && attributeValue !== null) {
            const response = await fecthDirectfromDb(attribute, attributeValue);
            if (response?.apiStatus === 'SUCCESS') {
                const baseUrl = window.location.href.substring(0, window.location.href.length - 13);
                window.location.href = `${baseUrl}${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${response.docId}&prev_page_type=ADMIN_DOC`;
            }
        } else {
            const params = {
                limit: size,
                offset: index * size,
                orderBy,
                sortOrder,
                attribute,
                operator,
                attributeValue,
                dateValue,
                dateStartValue,
                dateEndValue,
            };
            setDocsList(defaultDoc);
            const documents = await getAdminDocumentList(qs.stringify(params));
            if (documents?.apiStatus === 'SUCCESS') {
                setDocsList(documents.DocumentList);
                setPagination({
                    pageSize: size,
                    pageIndex: index,
                    totalCount: documents.totalDocuments,
                });
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSingleCheckBox = (
        documentId: number,
        oldOwnerId: number,
        state: IDocStatus,
        docNumber: string,
    ) => {
        const selectedDocumentsCopy = [...selectedDocuments];
        const position = selectedDocumentsCopy
            .map((d) => ownerChangeString(d.documentId, d.oldOwnerId))
            .indexOf(ownerChangeString(documentId, oldOwnerId));
        if (position === -1) {
            selectedDocumentsCopy.push({ documentId, oldOwnerId, state, docNumber });
        } else {
            selectedDocumentsCopy.splice(position, 1);
            setSelectedAction(null);
        }
        setSelectedDocuments(selectedDocumentsCopy);
    };

    const handleSelectAll = (selectAll1: boolean) => {
        if (selectAll1) {
            setSelectedDocuments(
                docList.map((d) => ({
                    documentId: d.documentId,
                    oldOwnerId: d.ownerId,
                    state: d.status,
                    docNumber: d.docNumber,
                })),
            );
        } else {
            setSelectedDocuments([]);
            setSelectedAction(null);
        }
    };

    const handleReset = async () => {
        const params = {
            limit: 10,
            offset: 0,
            orderBy: 'approvedOn',
            sortOrder: 'DESC',
            attribute: null,
            operator: null,
            attributeValue: null,
            dateValue: null,
            dateStartValue: null,
            dateEndValue: null,
        };
        setDocsList(defaultDoc);
        setSelectedAction(null);
        const documents = await getAdminDocumentList(qs.stringify(params));
        if (documents?.apiStatus === 'SUCCESS') {
            setDocsList(documents.DocumentList);
            setPagination({
                pageSize: 10,
                pageIndex: 0,
                totalCount: documents.totalDocuments,
            });
        }
    };

    const handleAction = async () => {
        setIsSubmitLoading(true);
        const documents = selectedDocuments;
        if (!selectedNewOwner?.value) {
            toast.error('Please Select Owner');
        } else {
            const res = await ownerChangeAction(documents, selectedNewOwner?.value as number);
            if (res?.apiStatus === 'FAILURE') {
                toast.error('Something went wrong');
            } else {
                setSelectedDocuments([]);
                handleReset();
            }
        }
        setIsSubmitLoading(false);
        setOpenModal(false);
    };

    const handleObsolete = async (Docs: IOwnerChange[]) => {
        const documents = Docs;
        const res = await makeDocumentObsolete(documents);
        if (res?.apiStatus === 'FAILURE') {
            toast.error('Something went wrong');
        } else {
            setSelectedDocuments([]);
            handleReset();
        }
        setModal(false);
    };

    const handleSearch = async () => {
        update(0, 10, 'approvedOn', 'DESC');
    };

    return (
        <div className='admin-dashboard'>
            <AdminSideNavigation />
            <div className='admin-dashboard-main'>
                <AdminDocTableFilters
                    setAttributeName={setAttributeName}
                    setOperatorName={setOperatorName}
                    setFilterValue={setValue}
                    setDateValue={setDateValue}
                    setStartDateValue={setStartDateValue}
                    setEndDate={setEndDate}
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    setOpenModal={setOpenModal}
                    setModal={setModal}
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                    SelectedDocuments={selectedDocuments}
                    setOpenWithdrawModal={setOpenWithdrawModal}
                />
                <AdminDocTable
                    selectAll={selectedDocuments.length === docList.length}
                    SelectedDocuments={selectedDocuments}
                    handleSelectAll={(selectALl2) => {
                        handleSelectAll(selectALl2);
                    }}
                    handleSingleCheckBox={(documentId, oldOwnerId, state, DocumentNumber) =>
                        handleSingleCheckBox(documentId, oldOwnerId, state, DocumentNumber)
                    }
                    data={docList}
                    pagination={pagination}
                    fetchDocuments={(
                        pageIndex: number,
                        pageSize: number,
                        order: string,
                        orderType: string | undefined,
                    ) => update(pageIndex, pageSize, order, orderType)}
                />
                <WithdrawModal
                    show={openWithdrawModal}
                    documents={selectedDocuments}
                    handleShow={(show: boolean) => setOpenWithdrawModal(show)}
                />
                <ObsoleteModal
                    show={modal}
                    handleShow={(show: boolean) => setModal(show)}
                    documents={selectedDocuments}
                    handleObsolete={(docs: IOwnerChange[]) => handleObsolete(docs)}
                />
                <OwnerSelectModal
                    show={openModal}
                    handleShow={(show: boolean) => setOpenModal(show)}
                    updateChangedOwner={(selectedUser: IUserOptionValue) =>
                        setSelectedNewOwner(selectedUser)
                    }
                    IssubmitLoading={isSubmitLoading}
                    handleOwnerChange={() => handleAction()}
                />
            </div>
        </div>
    );
};

export default AdminDocTablePage;
