import { IAPIResponseStatus, IAdminDocuments } from '../interfaces';
import { getRequest } from '../utils/api-request';
import { convertEpochToDate } from '../utils/date';

// eslint-disable-next-line max-lines-per-function
const getAdminDocumentList = async (
    queryParams: string,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          DocumentList: IAdminDocuments[];
          totalDocuments: number;
      }
    | undefined
> => {
    try {
        const response = await getRequest(`search/adm-search?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const DocumentList: IAdminDocuments[] = [];

            const documents = response.response.source;
            documents.forEach((document: any) => {
                DocumentList.push({
                    documentId: document.id,
                    docNumber: document.documentNumber,
                    documentType: document.documentNumber.split('-')[0],
                    ownerId: document.ownerId,
                    owner: document.owner.name ? document.owner.name : '',
                    status: document.state,
                    updatedAt: convertEpochToDate(document.approvedOn),
                    title: document.title,
                    description: document.description,
                });
            });

            return {
                apiStatus: response.apiStatus,
                DocumentList,
                totalDocuments: response.response.hits,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, DocumentList: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line import/prefer-default-export
export { getAdminDocumentList };
