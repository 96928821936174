/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
    name: string;
    displayName: string;
    value: string;
    updateFilters: (name: string, value: any, type: string) => void;
}

const TextInput: React.FC<Props> = ({ displayName, name, value, updateFilters }) => (
    <div>
        <label htmlFor={name}>{displayName}</label>
        <input
            type="text"
            name={name}
            id={name}
            placeholder={`Enter ${displayName}`}
            value={value || ''}
            autoComplete="off"
            onChange={(e) => updateFilters(name, e.target.value, 'TEXT_INPUT')}
        />
    </div>
);

export default TextInput;
