/* eslint-disable max-lines-per-function */
import { IOptionValue } from '../interfaces';
import { IDataTypeMapping, IDocTypeFieldsOptionValue, IField, IUiControl } from '../interfaces/document';
import { toCamelCase } from '../utils';

export type IDocTypeAction =
    | { type: 'new'; value: number }
    | { type: 'update'; value: IField[] }
    | { type: 'delete'; key: number }
    | { type: 'field-display-name'; key: number; value: string; changeInternalName: boolean }
    | { type: 'field-name'; key: number; value: string }
    | { type: 'field-max-length'; key: number; value: number }
    | { type: 'helper-text'; key: number; value: string }
    | { type: 'step'; key: number; value: number }
    | { type: 'ui-type'; key: number; value: IUiControl }
    | { type: 'data-type'; key: number; value: IDataTypeMapping }
    | { type: 'mandatory'; key: number; value: boolean }
    | { type: 'static-option-value'; key: number; value: string[] }
    | {
        type: 'dynamic-option-value';
        key: number;
        tableName?: string;
        valueColumn?: string;
        displayColumn?: string;
    }
    | { type: 'select-type'; key: number; value: 'static' | 'dynamic' }
    | { type: 'markup-text'; key: number; value: string }
    | { type: 'doc-type-option-values'; key: number; value: IOptionValue[] }
    | { type: 'doc-type-fields-option-values'; key: number; value: IDocTypeFieldsOptionValue[] }
    | { type: 'readOnly'; key: number; value: boolean };

export const initialFieldData: IField = {
    id: 0,
    dataType: {
        label: 'string',
        uiControls: [
            {
                value: 1,
                label: 'Text',
            },
            {
                value: 2,
                label: 'Text Box',
            },
            {
                value: 5,
                label: 'Single Select Dropdown',
            },
            {
                value: 9,
                label: 'Button Select',
            },
            {
                value: 10,
                label: 'Markup',
            },
        ],
        value: 1,
    },
    name: '',
    helperText: '',
    maxLength: 0,
    displayName: '',
    step: 0.01,
    mandatory: false,
    uiControl: { label: 'Text', value: 1 },
    markupText: '',
    docTypeOptionValues: [] as IOptionValue[],
    readOnly: false,
    docTypeFieldsOptionValues: [] as IDocTypeFieldsOptionValue[]
};

export const docTypeReducer = (state: IField[], action: IDocTypeAction): IField[] => {
    switch (action.type) {
        case 'update': {
            const tempState: IField[] = [];
            return tempState.concat(action.value);
        }
        case 'new':
            return [...state, { ...initialFieldData, id: action.value }];
        case 'delete':
            return [...state.slice(0, action.key), ...state.slice(action.key + 1)];
        case 'field-display-name':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    displayName: action.value,
                    name: action.changeInternalName
                        ? toCamelCase(action.value)
                        : state[action.key].name,
                },
                ...state.slice(action.key + 1),
            ];
        case 'field-name':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    name: action.value,
                },
                ...state.slice(action.key + 1),
            ];
        case 'field-max-length':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    maxLength: action.value,
                },
                ...state.slice(action.key + 1),
            ];
        case 'step':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    step: action.value,
                },
                ...state.slice(action.key + 1),
            ];
        case 'helper-text':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    helperText: action.value,
                },
                ...state.slice(action.key + 1),
            ];
        case 'ui-type': {
            const selectType =
                action.value.label === 'Multi Select Dropdown' ||
                    action.value.label === 'Single Select Dropdown' ||
                    action.value.label === 'Button Select'
                    ? 'static'
                    : null;
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    uiControl: action.value,
                    selectType,
                },
                ...state.slice(action.key + 1),
            ];
        }
        case 'data-type':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    dataType: action.value,
                    uiControl: action.value.uiControls[0],
                },
                ...state.slice(action.key + 1),
            ];
        case 'mandatory':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], mandatory: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'readOnly':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], readOnly: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'static-option-value': {
            const field = state[action.key];
            delete field.dynamicOptionValues;
            return [
                ...state.slice(0, action.key),
                { ...field, staticOptionValues: { data: action.value } },
                ...state.slice(action.key + 1),
            ];
        }
        case 'select-type':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], selectType: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'dynamic-option-value': {
            const field = state[action.key];
            let { dynamicOptionValues } = state[action.key];
            delete field.staticOptionValues;
            if (!dynamicOptionValues) {
                dynamicOptionValues = {
                    displayColumn: '',
                    tableName: '',
                    type: 'db-lookup',
                    valueColumn: '',
                };
            }
            if (action.tableName) {
                dynamicOptionValues.tableName = action.tableName;
                dynamicOptionValues.displayColumn = '';
                dynamicOptionValues.valueColumn = '';
            }
            if (action.displayColumn) {
                dynamicOptionValues.displayColumn = action.displayColumn;
            }
            if (action.valueColumn) {
                dynamicOptionValues.valueColumn = action.valueColumn;
            }

            return [
                ...state.slice(0, action.key),
                { ...field, dynamicOptionValues },
                ...state.slice(action.key + 1),
            ];
        }
        case 'doc-type-option-values':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    docTypeOptionValues: action.value,
                },
                ...state.slice(action.key + 1),
            ]
        case 'doc-type-fields-option-values': {
            let updatedDocTypeFieldsOptionValues = state[action.key].docTypeFieldsOptionValues;
            const { docTypeName, fields } = action.value[0];

            let modifiedDocTypeFieldsIndex = -1;
            updatedDocTypeFieldsOptionValues.forEach((currObj, idx) => {
                if (currObj.docTypeName === docTypeName) {
                    modifiedDocTypeFieldsIndex = idx;
                    updatedDocTypeFieldsOptionValues[modifiedDocTypeFieldsIndex] = {
                        docTypeName,
                        fields,
                    };
                }
            });

            if (modifiedDocTypeFieldsIndex === -1) {
                updatedDocTypeFieldsOptionValues = [
                    ...updatedDocTypeFieldsOptionValues,
                    ...action.value,
                ];
            }
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    docTypeFieldsOptionValues: updatedDocTypeFieldsOptionValues,
                },
                ...state.slice(action.key + 1),
            ];
        }
        case 'markup-text':
            return [
                ...state.slice(0, action.key),
                {
                    ...state[action.key],
                    markupText: action.value,
                },
                ...state.slice(action.key + 1),
            ];
        default:
            return state;
    }
};
