import React from 'react'
import { IField } from '../../../interfaces/document'
import { IDocumentAction } from '../../../reducers/document-reducer'


interface Props {
    field: IField;
    step: number;
    isDisabled: boolean;
    value: number;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}


const Float: React.FC<Props> = ({ field, step, isDisabled, value, error, documentDispatch }: Props) =>
    // const formatNumber = (number: number): number => Math.round(number * 1000) / 1000;
    <input
        type="number"
        step={step}
        className={`input-text ${error ? 'error' : ''}`}
        name={field.name}
        disabled={isDisabled}
        value={value}
        onChange={(e) =>
            documentDispatch &&
            documentDispatch({
                type: 'float',
                inputName: field.name,
                value: parseFloat(e.target.value),
            })
        }
        autoComplete="off"
    />
    ;

export default Float;
