import React from 'react';
import Select from 'react-select';
import CONSTANTS from '../../../constants';
import { IEffectivity } from '../../../interfaces';
import { EFFECTIVITY_DATA } from '../filter-config';

interface Props {
    name: string;
    displayName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFilters: (name: string, value: any, type: string) => void;
    options: {
        label: string;
        value: IEffectivity;
    }[];
    value: IEffectivity;
}

const Effectivity: React.FC<Props> = ({ value, name, displayName, updateFilters }) => (
    <div>
        <label htmlFor={name}>{displayName}</label>
        <Select
            name={name}
            id={name}
            isMulti={false}
            className="react-select"
            classNamePrefix="select"
            options={EFFECTIVITY_DATA}
            isSearchable={false}
            value={{
                value,
                label: CONSTANTS.EFFECTIVITY[value],
            }}
            onChange={(option) => {
                updateFilters(name, option?.value, 'EFFECTIVITY');
            }}
        />
    </div>
);

export default Effectivity;
