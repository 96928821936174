/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';

import { IOptionValue, IUserOptionValue,InactiveOptionValue } from '../../../interfaces';
import './TaskApproversFilter.scss';
import CONSTANTS from '../../../constants';
import { searchInactiveApprovers, userSearch } from '../../../API/users';
import { getTasksApprovers } from '../../../API/inactive-approvers';
import STATIC_CONTENT from '../../../constants/StaticContent';

const { TASK_APPROVERS } = STATIC_CONTENT;
const animatedComponents = makeAnimated();
interface SelectUserProps {
    owner: IUserOptionValue | null;
    updateOwner: (approver: IUserOptionValue) => void;
    isDisabled: boolean;
    hideUsers: number[];
    documentId?: number,
}

// eslint-disable-next-line max-lines-per-function
export const SelectUser: React.FC<SelectUserProps> = ({
    owner,
    updateOwner,
    isDisabled,
    documentId
}) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const promiseOptions = async (inputValue: string): Promise<InactiveOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await searchInactiveApprovers(documentId,inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                   
                    resolve(users.users);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    return (
        <div className="select-user">
            <AsyncSelect
                name="user-search"
                id="user-search"
                components={animatedComponents}
                isLoading={isLoading}
                placeholder="Search user..."
                className="react-select select-approver__select"
                classNamePrefix="select"
                loadOptions={promiseOptions}
                value={owner}
                isDisabled={isDisabled}
                noOptionsMessage={() =>
                    searchText.length >= 3
                        ? 'No results found'
                        : 'Start typing minimum 3 characters'
                }
                onChange={(option) => updateOwner(option as IUserOptionValue)}
            />
        </div>
    );
};

const DEFAULT_OWNER: IUserOptionValue[] = [
    {
        jobTitle: '',
        label: 'All',
        role: '',
        roleId: 0,
        value: 0,
        activeUser: false,
    },
];

interface SelectOwnerProps {
    owner: IUserOptionValue | null;
    updateOwner: (approver: IUserOptionValue) => void;
}

// eslint-disable-next-line max-lines-per-function
const SelectOwner: React.FC<SelectOwnerProps> = ({ owner, updateOwner }) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    if (userList.length > 0) {
                        resolve([...DEFAULT_OWNER, ...userList]);
                    } else if (inputValue.toLowerCase() === 'all') {
                        resolve(DEFAULT_OWNER);
                    } else {
                        resolve([]);
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (!owner) {
            updateOwner({ jobTitle: '', label: 'All', value: '', activeUser: true, role: '', roleId: 0 });
        }
    }, [owner]);

    return (
        <div className="select-user">
            <label htmlFor="user-search" className="select-user__label">
                {TASK_APPROVERS.LABEL.SELECT_OWNER}
            </label>
            <AsyncSelect
                name="user-search"
                id="user-search"
                components={animatedComponents}
                isLoading={isLoading}
                placeholder="Search user..."
                className="react-select select-approver__select"
                classNamePrefix="select"
                loadOptions={promiseOptions}
                getOptionLabel={(e) => e.jobTitle ? `${e.label} | ${e.jobTitle}` : e.label }
                value={owner}
                noOptionsMessage={() =>
                    searchText.length >= 3
                        ? 'No results found'
                        : 'Start typing minimum 3 characters'
                }
                onChange={(option) => updateOwner(option as IUserOptionValue)}
                isClearable
            />
        </div>
    );
};

const DEFAULT_APPROVER: IOptionValue[] = [
    {
        label: 'All',
        value: 0,
    },
];

interface SelectTaskApproverProps {
    inactiveApprover: IOptionValue | null;
    updateInactiveApprover: (approver: IOptionValue) => void;
    ownerIds: number[];
}

// eslint-disable-next-line max-lines-per-function
const SelectTaskApprover: React.FC<SelectTaskApproverProps> = ({
    inactiveApprover,
    updateInactiveApprover,
    ownerIds,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await getTasksApprovers(ownerIds, inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    if (users.approvers.length > 0) {
                        resolve([...DEFAULT_APPROVER, ...users.approvers]);
                    } else if (inputValue.toLowerCase() === 'all') {
                        resolve(DEFAULT_APPROVER);
                    } else {
                        resolve([]);
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (!inactiveApprover) {
            updateInactiveApprover({ label: 'All', value: '' });
        }
    }, [inactiveApprover]);

    return (
        <div className="select-inactive-approver">
            <label htmlFor="user-search" className="select-user__label">
                {TASK_APPROVERS.LABEL.SELECT_APPROVER}
            </label>
            <AsyncSelect
                name="user-search"
                id="user-search"
                components={animatedComponents}
                isLoading={isLoading}
                placeholder="Search user..."
                className="react-select select-approver__select"
                classNamePrefix="select"
                value={inactiveApprover}
                loadOptions={promiseOptions}
                onChange={(option) => updateInactiveApprover(option as IOptionValue)}
                noOptionsMessage={() =>
                    searchText.length >= 3
                        ? 'No results found'
                        : 'Start typing minimum 3 characters'
                }
                isClearable
            />
        </div>
    );
};

interface Props {
    inactiveApprover: IOptionValue | null;
    updateInactiveApprover: (approver: IOptionValue) => void;
    owner: IUserOptionValue | null;
    updateOwner?: (approver: IUserOptionValue) => void;
    documentNumber?: string;
    updatedocumentNumber?: (documentNumber: string) => void;
    hideUsers: number[];
}

const TaskApproversFilter: React.FC<Props> = ({
    owner,
    inactiveApprover,
    updateInactiveApprover,
    updateOwner,
    documentNumber,
    updatedocumentNumber
}) => (
    <div className="inactive-approvers-filters">
        <div className="select-docNumber">
                            <label htmlFor="select-docNumber">{TASK_APPROVERS.LABEL.INPUT_DOCNUMBER}</label>
                            <input
                                className="select-docNumber"
                                type="text"
                                name="doc-code"
                                id="doc-code"
                                value={documentNumber}
                                autoComplete="off"
                                onChange={(e) => {
                                    if(updatedocumentNumber){
                                        updatedocumentNumber(e.target.value);
                                    }
                                    
                                }}
                            />
        </div>
        <SelectTaskApprover
            inactiveApprover={inactiveApprover}
            updateInactiveApprover={updateInactiveApprover}
            ownerIds={owner?.value ? [owner?.value as number] : []}
        />
        {updateOwner && <SelectOwner owner={owner || null} updateOwner={updateOwner} />}
    </div>
);

export default TaskApproversFilter;
