/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import React, {useEffect} from 'react';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import update from 'immutability-helper';

import './DocTypeApprovalFlow.scss';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import DocTypeApprovalStage from '../../widgets/doc-type-stage/DocTypeApprovalStage';
import { AddButton } from '../General';
// import ReactDND from '../ReactDND';
import STATIC_CONTENT from '../../constants/StaticContent';

const DocTypeApprovalFlow: React.FC = () => {
    const {
        approvalStages,
        approvalStageDispatch,
        docTypeInfo,
        docTypeVersionError: {
            APPROVAL_FLOW: { cards },
        },
        docTypeConfig
    } = useDocTypeContext();
    const onNewStage = async () => {
        approvalStageDispatch({ type: 'new' });
    };

    useEffect(() => {
        if (docTypeConfig.allowDCO && approvalStages.length) {
            approvalStages.forEach((stage, index) => {
                approvalStageDispatch({ type: 'delete', key: index });
            });
        }
    }, [docTypeConfig.allowDCO]);
    
    // const moveCard = (dragIndex: number, hoverIndex: number) => {
    //     const dragCard = approvalStages[dragIndex];
    //     if (dragCard) {
    //         approvalStageDispatch({
    //             type: 'update',
    //             value: update(approvalStages, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragCard],
    //                 ],
    //             }),
    //         });
    //     }
    // };

    return (
        <div className="doc-type-approval-flow">
            {/* <DndProvider backend={HTML5Backend}> */}
            {approvalStages.length > 0 &&
                approvalStages.map((stage, index) => (
                    // <ReactDND key={stage.id} index={index} id={stage.id} moveCard={moveCard}>
                    <DocTypeApprovalStage
                        errors={cards[index] ? cards[index] : { cardError: false, fieldList: [] }}
                        key={index}
                        stageData={stage}
                        index={index}
                        isDisabled={docTypeInfo.status !== 'DRAFT'}
                    />
                    // </ReactDND>
                ))}
            {/* </DndProvider> */}
            {docTypeInfo.status === 'DRAFT' && (
                <div className="new-button">
                    <button type="button" onClick={() => onNewStage()} disabled={docTypeConfig.allowDCO}>
                        <AddButton />
                        {STATIC_CONTENT.DOC_TYPE_APPROVAL.BUTTON.ADD_NEW_STAGE}
                    </button>
                </div>
            )}
        </div>
    );
};

export default DocTypeApprovalFlow;
