import React, { useState } from 'react';
import Modal from 'react-modal';
import { AlignMiddle, TextCenter } from 'react-bootstrap-icons';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import Button from '../button/Button';
import { PlusIconSvg } from '../../assets/images';
import STATIC_CONTENT from '../../constants/StaticContent';
import { IUserOptionValue } from '../../interfaces';
import CONSTANTS from '../../constants';
import { userSearch } from '../../API/users';
import './OwnerSelectModal.scss';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        // backgroundColor: '#000',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        right: 'auto',
        top: '20%',
        transform: 'translate(-50%, 0%)',
    },
};

const DEFAULT_OWNER: IUserOptionValue[] = [
    {
        jobTitle: '',
        label: 'All',
        role: '',
        roleId: 0,
        value: 0,
        activeUser: false,
    },
];

interface Props {
    updateChangedOwner: (selectedUser: IUserOptionValue) => void;
    handleOwnerChange: () => void;
    IssubmitLoading: boolean;
    show: boolean;
    handleShow: (check: boolean) => void;
}
// eslint-disable-next-line max-lines-per-function
const OwnerSelectModal: React.FC<Props> = ({
    updateChangedOwner,
    handleOwnerChange,
    IssubmitLoading,
    show,
    handleShow,
}) => {
    const [isLoading, setLoading] = useState(false);
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };
    return (
        <div>
            <Modal isOpen={show} style={customStyles} className="Owner-Change-Modal">
                <PlusIconSvg className="close-btn" onClick={() => handleShow(false)} />
                <label htmlFor="user-search">{STATIC_CONTENT.USER_SEARCH.LABEL.SELECT_USERS}</label>
                <span className="note">Length should be greater than 3 characters</span>
                <AsyncSelect
                    name="user-search"
                    id="user-search"
                    components={animatedComponents}
                    isLoading={isLoading}
                    isMulti={false}
                    placeholder="Search user..."
                    className="react-select user-search"
                    classNamePrefix="select"
                    loadOptions={promiseOptions}
                    getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                    noOptionsMessage={() => 'Start typing...'}
                    onChange={(option) => {
                        updateChangedOwner(option as IUserOptionValue);
                    }}
                />
                <Button
                    onClick={() => handleOwnerChange()}
                    disabled={IssubmitLoading}
                    isLoading={IssubmitLoading}
                    className="submit-button"
                    type={undefined}
                >
                    Submit
                </Button>
            </Modal>
        </div>
    );
};

export default OwnerSelectModal;
