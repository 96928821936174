/* eslint-disable max-lines-per-function */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ISunsetTabs } from '../interfaces/sunset';
import CONSTANTS from '../constants';
import { IPaginationData } from '../interfaces';
import { mySunsetDocsDefault, mySunsetTasksDefault } from '../components/filters/filter-config';
import { getMySunsetDocs, getMySunsetTasks } from '../API/sunset';

interface ISunsetContext {
    activeTab: ISunsetTabs;
    documentsCount: {
        mySunsetDocs: number;
        mySunsetTasks: number;
    };
    setActiveTab: React.Dispatch<React.SetStateAction<ISunsetTabs>>;
}

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

const sunsetContext = createContext<ISunsetContext>({
    activeTab: 'MY_SUNSET_DOCS',
    documentsCount: {
        mySunsetDocs: 0,
        mySunsetTasks: 0,
    },
    setActiveTab: () => {},
});

const SunsetProvider: React.FC = (props: unknown) => {
    const [activeTab, setActiveTab] = useState<ISunsetTabs>('MY_SUNSET_DOCS' as ISunsetTabs);
    const [mySunsetDocsCount, setMySunsetDocsCount] = useState(0);
    const [mySunsetTasksCount, setMySunsetTasksCount] = useState(0);

    const fetchCountOfDocs = async () => {
        const mySunsetDocsRes = getMySunsetDocs({
            ...mySunsetDocsDefault,
            limit: DEFAULT_PAGINATION_DATA.pageSize.toString(),
            offset: (
                DEFAULT_PAGINATION_DATA.pageIndex * DEFAULT_PAGINATION_DATA.pageSize
            ).toString(),
        });
        const mySunsetTasksRes = getMySunsetTasks(
            {
                ...mySunsetTasksDefault,
            },
            {
                sortBy: 'startTime',
                sortOrder: 'desc',
            },
            DEFAULT_PAGINATION_DATA.pageSize,
            DEFAULT_PAGINATION_DATA.pageIndex * DEFAULT_PAGINATION_DATA.pageSize,
        );
        Promise.all([mySunsetDocsRes, mySunsetTasksRes]).then((values) => {
            if (values[0]?.apiStatus === 'SUCCESS') {
                setMySunsetDocsCount(values[0].totalDocuments);
            }
            if (values[1]?.apiStatus === 'SUCCESS') {
                setMySunsetTasksCount(values[1].totalDocuments);
            }
        });
    };

    useEffect(() => {
        fetchCountOfDocs();
    }, [activeTab]);

    return (
        <sunsetContext.Provider
            value={{
                activeTab,
                documentsCount: {
                    mySunsetDocs: mySunsetDocsCount,
                    mySunsetTasks: mySunsetTasksCount,
                },
                setActiveTab,
            }}
            {...props}
        />
    );
};

export const useSunsetContext = (): ISunsetContext => useContext(sunsetContext);

export default SunsetProvider;
