import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { PlusIconSvg } from '../../../assets/images';
import { IUserOptionValue } from '../../../interfaces';
import HRBPInfoFilter from '../hrbp-filters/HRBPInfoFilters';
import CONSTANTS from '../../../constants';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { userSearch } from '../../../API/users';
import { createHRBPInfo, updateHRBPInfo } from '../../../API/hrbp';
import './HRBPModal.scss'

const { HRBP_INFO_SETTINGS } = STATIC_CONTENT;
const animatedComponents = makeAnimated();

const DEFAULT_HRBP: IUserOptionValue[] = [
    {
        jobTitle: '',
        label: '',
        role: '',
        roleId: 0,
        value: 0,
        activeUser: false,
    },
];

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    showModal: boolean;
    isCreate: boolean;
    entity: string;
    setEntity: React.Dispatch<React.SetStateAction<string>>;
    hrbp: IUserOptionValue;
    setHRBP: React.Dispatch<React.SetStateAction<IUserOptionValue>>;
}

// eslint-disable-next-line max-lines-per-function
const HRBPModal: React.FC<Props> = ({
    showModal,
    setShowModal,
    isCreate,
    entity,
    setEntity,
    hrbp,
    setHRBP,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [altering , setAltering] = useState<boolean>(false);
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    if (userList.length > 0) {
                        resolve([...userList]);
                    } else if (inputValue.toLowerCase() === 'all') {
                        resolve(DEFAULT_HRBP);
                    } else {
                        resolve([]);
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const closeModal = () => {  
        setEntity('');
        setHRBP(DEFAULT_HRBP[0]);
        setShowModal(false);
    }

    const alter = async () => {
        if(hrbp.value === 0){
            toast.error('Please select HRBP');
            return;
        }
        if(entity === ""){
            toast.error('Entity cannot be empty');
            return;
        }
        setAltering(true);
        if(isCreate){
            const createResponse = await createHRBPInfo(entity, hrbp.value as number);
            if(createResponse && createResponse?.apiStatus === "SUCCESS"){
                toast.success(createResponse.message);
            } else {
                toast.error('Something went wrong');
            }
        } else{
            const updateResponse = await updateHRBPInfo(entity,hrbp.value as number);
            if(updateResponse && updateResponse?.apiStatus === "SUCCESS"){
                toast.success(updateResponse.message);
            } else {
                toast.error('Something went wrong');
            }
        }
        setAltering(false);
        closeModal();
    };

    useEffect(() => {
        if (!hrbp) {
            setHRBP({ jobTitle: '', label: '', value: 0, activeUser: true, role: '', roleId: 0 });
        }
    }, [hrbp, setHRBP]);

    return (
        <>
            <Modal className="add_role__modal" isOpen={showModal}>
                <PlusIconSvg className="close-btn" onClick={() => setShowModal(false)} />
                <div className="modal-body">
                    <div className="heading">
                        <h1>{isCreate ? 'Create' : 'Update'}</h1>
                        <div className="hrbp-input">
                            <label htmlFor="role-name">Entity</label>
                            <input
                                className='entity-input'
                                type="text"
                                name="role-name"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Name"
                                value={entity}
                                disabled={!isCreate}
                                onChange={(e) => setEntity(e.target.value)}
                            />
                        </div>
                        <div className="hrbp-input">
                            <div className="select-user">
                                <label htmlFor="user-search" className="select-user__label">
                                    {HRBP_INFO_SETTINGS.LABEL.SELECT_HRBP}
                                </label>
                                <AsyncSelect
                                    name="user-search"
                                    id="user-search"
                                    components={animatedComponents}
                                    isLoading={isLoading}
                                    placeholder="Search user..."
                                    className="react-select select-approver__select"
                                    classNamePrefix="select"
                                    loadOptions={promiseOptions}
                                    getOptionLabel={(e) =>
                                        e.jobTitle ? `${e.label} | ${e.jobTitle}` : e.label
                                    }
                                    value={hrbp}
                                    noOptionsMessage={() =>
                                        searchText.length >= 3
                                            ? 'No results found'
                                            : 'Start typing minimum 3 characters'
                                    }
                                    onChange={(option) => setHRBP(option as IUserOptionValue)}
                                    isClearable
                                />
                            </div>
                        </div>
                        <div className="button-group">
                            <button disabled={altering} type="button" className="primary-btn" onClick={() => alter()}>
                                {isCreate ? 'Create' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default HRBPModal;
