import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import Calendar from 'react-calendar';
import { format, parse } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { IField } from '../../../interfaces/document';
import './DatePickerDateOnly.scss';
import { IDocumentAction } from '../../../reducers/document-reducer';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

// eslint-disable-next-line max-lines-per-function
const DatePickerDateOnly: React.FC<Props> = ({
    documentDispatch,
    field,
    isDisabled,
    value,
    error,
}) => (
    <div className="react-datepicker">
        <InputMask
            className={`input-text ${error ? 'error' : ''}`}
            name={field.name}
            disabled={isDisabled}
            value={value}
            onChange={(e) => {
                if (documentDispatch) {
                    documentDispatch({
                        type: 'date-picker-date-only',
                        inputName: field.name,
                        value: e.target.value,
                    });
                }
            }}
            placeholder={isDisabled ? '' : 'MM/DD/YYYY'}
            mask="99/99/9999"
        />
    </div>
);
export default DatePickerDateOnly;
