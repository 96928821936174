import React, { useEffect, useState } from 'react';

const ProgressBarComponent = () => {
    const [progress, setProgress] = useState(0);
    const getRandomDelay = () => Math.floor(Math.random() * 10) + 20;
    useEffect(() => {
        // Simulating API call progress
        const simulateAPICall = () => {
            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress === 100) {
                        clearInterval(interval);
                        return prevProgress;
                    }
                    const nextProgress = prevProgress + getRandomDelay();
                    return nextProgress > 100 ? 100 : nextProgress;
                });
            }, 150);

            // Simulating API call completion
            setTimeout(() => {
                clearInterval(interval);
            }, 1500);
        };

        simulateAPICall();
    }, []);

    return (
        <div>
            {progress !== 100 && (
                <>
                    <progress value={progress} max={100} /> <span>{`${progress}%`}</span>
                </>
            )}
        </div>
    );
};

export default ProgressBarComponent;
