import qs from 'qs';
import React, { useState, useEffect } from 'react';
import getAllContracts from '../../API/contracts';
import CONSTANTS from '../../constants';
import { IPaginationData } from '../../interfaces';
import { IContractList } from '../../interfaces/contracts';
import ContractsTableFilters from '../../widgets/contracts/ContractsTableFilters';
import ContractsPageTable from '../../components/contracts/ContractsPageTable';
import './ContractsPage.scss';

// eslint-disable-next-line max-lines-per-function
const ContractsPage: React.FC = () => {
    const defaultContracts = () => {
        const contractsList: IContractList[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 4; i++) {
            contractsList.push({
                contractId: CONSTANTS.LOADING.NUMBER,
                contractNumber: CONSTANTS.LOADING.TEXT,
                category: CONSTANTS.LOADING.TEXT,
                contractType: CONSTANTS.LOADING.TEXT,
                partyName: CONSTANTS.LOADING.TEXT,
                ownerId: CONSTANTS.LOADING.NUMBER,
                owner: CONSTANTS.LOADING.TEXT,
                status: 'NEW',
                updatedAt: CONSTANTS.LOADING.DATE,
            })
        }

        return contractsList;
    };
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 10,
        totalCount: CONSTANTS.LOADING.NUMBER,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [data, setData] = useState(defaultContracts);
    const [filterParams, setFilterParams] = useState([]);

    // eslint-disable-next-line max-lines-per-function
    const update = async (pageIndex?: number, pageSize?: number, order?: string, orderType?: string | undefined) => {
        setData(defaultContracts);
        const documentNumber: string | undefined = undefined;
        const OwnerIds: number | undefined = undefined;
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const sortBy = order || 'updatedAt';
        const sortOrder = orderType || 'DESC';
        const params = {
            ownerId: OwnerIds,
            documentNumber,
            limit: size,
            offset: index * size,
            sortBy,
            sortOrder,
            filters: JSON.stringify(filterParams)
        };

        const contracts = await getAllContracts(qs.stringify(params));
        if (contracts?.apiStatus === 'SUCCESS') {
            setData(contracts.contractList);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: contracts.totalContracts,
            });
        }
    };

    const handleReset = async () => {
        const params = {
            limit: 10,
            offset: 0,
            sortBy: 'updatedAt',
            sortOrder: 'DESC',
            filters: JSON.stringify([])
        };
        setData(defaultContracts);
        const contracts = await getAllContracts(qs.stringify(params));
        if (contracts?.apiStatus === 'SUCCESS') {
            setData(contracts.contractList);
            setPagination({
                pageSize: 10,
                pageIndex: 0,
                totalCount: contracts.totalContracts,
            });
        }
    };

    const handleSearch = async () => {
        update(0, 10, 'updatedAt', 'DESC');
    };

    useEffect(() =>{
        handleSearch();
    }, [filterParams])

    return (
        <div className="contracts-page-container">
            <ContractsTableFilters
                handleReset={handleReset}
                setFilterParams={setFilterParams}
                contractsCount={pagination.totalCount}
            />
            <ContractsPageTable
                data={data}
                pagination={pagination}
                fetchDocuments={(
                    pageIndex: number, pageSize: number, order: string, orderType: string | undefined
                ) => update(pageIndex, pageSize, order, orderType)}
            />
        </div>
    );
};
export default ContractsPage;
