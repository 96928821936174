/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { Isteps } from '../../interfaces/introjs-mastertable';
import { IDashboardTabs } from '../../interfaces/dashboard';
import {
    getUserTourDetails,
    createUserTourDetails,
    getInrojsMasterTableDetails,
} from '../../API/intojs';

interface Props {
    stepsEnabled: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    initialStep: number;
    setActiveTab?: React.Dispatch<React.SetStateAction<IDashboardTabs>>;
}

const InitialFeaturesTour: React.FC<Props> = ({
    initialStep,
    stepsEnabled,
    setStepsEnabled,
    setActiveTab,
}) => {
    const [steps, setSteps] = useState<Isteps[]>([]);

    const onExit = async (e: number) => {
        setStepsEnabled(false);
        const data = await getUserTourDetails(1); // tourId is 1
        if (data?.apiStatus === 'SUCCESS' && data.response === null) {
            await createUserTourDetails(1);
        }
    };

    const startIntro = () => {
        setStepsEnabled(true);
    };

    const checkStep = (e: number) => {
        if (e === 1 && setActiveTab) {
            setActiveTab('MY_APPROVALS');
        }
        if (e === 2 && setActiveTab) {
            setActiveTab('MY_DOCUMENTS');
        }
        if (e === 3 && setActiveTab) {
            setActiveTab('MY_FAVORITES');
        }
    };

    useEffect(() => {
        const fetchUserTourDetail = async () => {
            const dataMaster = await getInrojsMasterTableDetails(1); // tourId is 1
            const dataUser = await getUserTourDetails(1); // tourId is 1
            const currentTimeInSeconds = Math.floor(Date.now());
            if (dataMaster?.apiStatus === 'SUCCESS' && dataMaster.masterTableInfo !== null) {
                setSteps(dataMaster.masterTableInfo.steps);
            }

            if (
                dataUser?.apiStatus === 'SUCCESS' &&
                dataUser.response === null &&
                dataMaster &&
                currentTimeInSeconds >= dataMaster.masterTableInfo.starttime &&
                dataMaster.masterTableInfo.status &&
                (dataMaster.masterTableInfo.endtime === null ||
                    currentTimeInSeconds <= dataMaster.masterTableInfo.endtime)
            ) {
                startIntro();
            }
        };
        fetchUserTourDetail();
    }, []);

    return (
        <div>
            <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={initialStep}
                onExit={(e) => onExit(e)}
                onAfterChange={(e) => checkStep(e)}
                options={{
                    tooltipClass: 'customTooltip',
                    showProgress: true,
                    showStepNumbers: true,
                    showBullets: false,
                    exitOnOverlayClick: false,
                    hideNext: false,
                    doneLabel: 'Done',
                    nextLabel: 'Next',
                    skipLabel: 'Skip',
                }}
            />
        </div>
    );
};

export default InitialFeaturesTour;
