import React from 'react';
import { IField, IFieldsData } from '../../../interfaces/document';
import { useDocumentContext } from '../../../contexts/document-provider';
import { IDocumentAction } from '../../../reducers/document-reducer';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: number;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const list = ["airfare","rentalCar","lodging","meals","miscellaneous"];

// eslint-disable-next-line max-lines-per-function
const Number: React.FC<Props> = ({ field, isDisabled, value, error, documentDispatch }: Props) => {
    const { isTravelAuthorizationForm, fieldsData } = useDocumentContext();

    const CalculateAndAutoFill = (data: number, fieldName: string) => {
        if (list.includes(fieldName)) {
            const filteredFieldaData: IFieldsData[] = fieldsData.filter(
                (item) => item.name !== fieldName && list.includes(item.name),
            );
            const totalEstimatedCost = filteredFieldaData.reduce(
                (total, obj) => total + obj.value,
                data,
            );
            if (documentDispatch) {
                documentDispatch({
                    type: 'number',
                    inputName: 'totalCost',
                    value: totalEstimatedCost,
                });
            }
        }
    };

    const dispatchDate = (e : React.ChangeEvent<HTMLInputElement>) => {
        const data = parseInt(e.target.value, 10) ? parseInt(e.target.value, 10) : 0;
        if (documentDispatch) {
            documentDispatch({
                type: 'number',
                inputName: field.name,
                value: data,
            });
        }
        
        if (isTravelAuthorizationForm){
                CalculateAndAutoFill(data, field.name);
        }
    }

    return (
        <input
            type="number"
            className={`input-text ${error ? 'error' : ''}`}
            name={field.name}
            disabled={isDisabled}
            value={value}
            onChange={(e) => {
                dispatchDate(e);
            }}
            autoComplete="off"
        />
    );
};

export default Number;
