import React from 'react';

import './Pagination.scss';
import { ArrowIcon } from '../../assets/images';

interface Props {
    pageSize: number;
    pageIndex: number;
    pageOptions: number[];
    setPageSize: (pageSize: number) => void;
    previousPage: () => void;
    nextPage: () => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
    className?: string;
}

const Pagination: React.FC<Props> = ({
    pageSize,
    setPageSize,
    pageIndex,
    pageOptions,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    className
}) => (
    <div className= {className ? `pagination ${className}`: "pagination" }>
        <label htmlFor="page-size">Rows per page</label>
        <select
            id="page-size"
            name="page-size"
            className="page-size"
            value={pageSize}
            onChange={(e) => {
                setPageSize(parseInt(e.target.value, 10));
            }}
        >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
        </select>
        <span>
            {pageIndex + 1} of {pageOptions.length}
        </span>
        <ArrowIcon
            className={`prev ${canPreviousPage ? '' : 'disabled'} `}
            onClick={() => canPreviousPage && previousPage()}
        />
        <ArrowIcon
            className={`next ${canNextPage ? '' : 'disabled'} `}
            onClick={() => canNextPage && nextPage()}
        />
    </div>
);

export default Pagination;
