/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAPIResponseStatus, IMockResponse } from '../interfaces';
import { getFromInMemory } from './in-memory-storage';
import { removeFromLocalStorage, setLocalStorage } from './local-storage';
import CONSTANTS from '../constants';

const getAPIStatus = (responseCode: number): IAPIResponseStatus => {
    if (responseCode === 200 || responseCode === 201) {
        return 'SUCCESS';
    }

    if (responseCode === 403 || responseCode === 404) {
        return 'UNAUTHORIZED';
    }

    return 'FAILURE';
};

const storeUrlPathInLocalStorage = (): void => {
    let { pathname } = window.location;
    pathname = pathname.replace(`/app`, '');
    setLocalStorage('path', pathname + window.location.search);
};

const reloadPageForUnauthorized = (status: number): void => {
    if (status === 401) {
        removeFromLocalStorage('token');
        removeFromLocalStorage('access_token');
        storeUrlPathInLocalStorage();
        window.location.reload();
    }
};

const getRequest = async (
    url: string,
    goalHost = false,
): Promise<{ response: any; apiStatus: IAPIResponseStatus } | undefined> => {
    let res;
    try {
        res = await fetch(
            `${
                goalHost ? process.env.REACT_APP_GOAL_API_HOST : process.env.REACT_APP_API_HOST
            }/api/v1/${url}`,
            {
                headers: {
                    Authorization: ` Bearer ${getFromInMemory()}`,
                },
            },
        );
        reloadPageForUnauthorized(res.status);
        const response = await res.json();
        return { response, apiStatus: getAPIStatus(res.status) };
    } catch (err) {
        const response = <IMockResponse>{};
        response.message = CONSTANTS.ERROR_406;
        return {
            response,
            apiStatus: getAPIStatus(res ? res.status : 406), // default 406 failure
        };
    }
};

// eslint-disable-next-line max-lines-per-function
const getRequestWithResponse = async (
    url: string,
    options: {
        goalHost?: boolean;
        responseType?: 'json' | 'blob' | 'text';
    } = {},
): Promise<
    | { response: Response; apiStatus: IAPIResponseStatus }
    | { response: Blob; apiStatus: IAPIResponseStatus }
    | { response: string; apiStatus: IAPIResponseStatus }
    | undefined
> => {
    let response: Response | undefined;
    try {
        response = await fetch(
            `${
                options.goalHost
                    ? process.env.REACT_APP_GOAL_API_HOST
                    : process.env.REACT_APP_API_HOST
            }/api/v1/${url}`,
            {
                headers: {
                    Authorization: ` Bearer ${getFromInMemory()}`,
                },
            },
        );
        reloadPageForUnauthorized(response.status);

        const apiStatus = getAPIStatus(response.status);

        if (options.responseType === 'blob') {
            const blob = await response.blob();
            return { response: blob, apiStatus };
        }
        if (options.responseType === 'text') {
            const text = await response.text();
            return { response: text, apiStatus };
        }
        // Default to 'json'
        const json = await response.json();
        return { response: json, apiStatus };
    } catch (err) {
        const resMock = <Response>{}; // used in attachement, but not used
        return {
            response: resMock,
            apiStatus: getAPIStatus(response ? response.status : 406), // default 406 failure
        };
    }
};

const postRequest = async (
    url: string,
    body: unknown,
    goalHost = false,
): Promise<{ response: any; apiStatus: IAPIResponseStatus; responseCode: number } | undefined> => {
    const header = {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${getFromInMemory()}`,
    };
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
    };
    let res;
    try {
        res = await fetch(
            `${
                goalHost ? process.env.REACT_APP_GOAL_API_HOST : process.env.REACT_APP_API_HOST
            }/api/v1/${url}`,
            requestOptions,
        );
        reloadPageForUnauthorized(res.status);
        const response = await res.json();

        return { response, apiStatus: getAPIStatus(res.status), responseCode: res.status };
    } catch (error) {
        const response = <IMockResponse>{};
        response.message = CONSTANTS.ERROR_406;
        return {
            response,
            apiStatus: getAPIStatus(res ? res.status : 406), // default 406 failure
            responseCode: res ? res.status : 406,
        };
    }
};

const putRequest = async (
    url: string,
    body: unknown,
    goalHost = false,
): Promise<{ response: any; apiStatus: IAPIResponseStatus } | undefined> => {
    const header = {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${getFromInMemory()}`,
    };
    const requestOptions = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(body),
    };
    let res;
    try {
        res = await fetch(
            `${
                goalHost ? process.env.REACT_APP_GOAL_API_HOST : process.env.REACT_APP_API_HOST
            }/api/v1/${url}`,
            requestOptions,
        );
        reloadPageForUnauthorized(res.status);
        const response = await res.json();
        return { response, apiStatus: getAPIStatus(res.status) };
    } catch (err) {
        const response = <IMockResponse>{};
        response.message = CONSTANTS.ERROR_406;
        return {
            response,
            apiStatus: getAPIStatus(res ? res.status : 406), // default 406 failure
        };
    }
};

const deleteRequest = async (
    url: string,
    goalHost = false,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    let res;
    try {
        const header = {
            'Content-Type': 'application/json',
            Authorization: ` Bearer ${getFromInMemory()}`,
        };
        const requestOptions = {
            method: 'DELETE',
            headers: header,
        };
        res = await fetch(
            `${
                goalHost ? process.env.REACT_APP_GOAL_API_HOST : process.env.REACT_APP_API_HOST
            }/api/v1/${url}`,
            requestOptions,
        );
        reloadPageForUnauthorized(res.status);

        return { apiStatus: getAPIStatus(res.status) };
    } catch (err) {
        return {
            apiStatus: getAPIStatus(res ? res.status : 406), // default 406 failure
        };
    }
};

export {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    storeUrlPathInLocalStorage,
    getRequestWithResponse,
    getAPIStatus,
};
