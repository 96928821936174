/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './UserHeader.scss';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { ClearSvg, SettingIconSvg, UserIconSvg } from '../../assets/images';
import { getUserShortName } from '../../utils';
import Notifications from '../notifications/Notifications';
import { INotifications } from '../../interfaces';
import {
    clearAllNotifications,
    clearSingleNotification,
    getNotifications,
    updateReadNotification,
} from '../../API/notifications';
import useInterval from '../../hooks/use-interval';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';

const DELAY = 1000 * 60;
const startingNotificationId = 0;
const { HEADER } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const UserHeader: React.FC = () => {
    const history = useHistory();
    const { user, onLogout } = useAuthDataContext();
    const [show, setShow] = useState(false);
    const [notifications, setNotifications] = useState<INotifications[]>([]);
    const [newNotification, setNewNotification] = useState(false);
    const [notificationIds, setNotificationIds] = useState({ min: 0, max: 0 });
    const [count,setCount] = useState(0);

    useInterval(async () => {
        // const recentNotificationId = notifications[0]?.id ? notifications[0]?.id : 0;
        if (user.mail && user.id && user.id !== -1) {
            const res = await getNotifications(
                user.mail,
                startingNotificationId,
                notificationIds.max,
            );
            if (res?.apiStatus === 'SUCCESS') {
                if (res.notifications.length > 0) {
                    setNotifications([...res.notifications, ...notifications]);
                    setNotificationIds(res.notificationIds);
                }
            }
        }
    }, DELAY);

    const menuRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);
    const update = async () => {
        const res = await updateReadNotification(1, notificationIds.max);
        if (res === 'SUCCESS') {
            const res1 = await getNotifications(
                user.mail,
                startingNotificationId,
                startingNotificationId,
            );
            if (res1?.apiStatus === 'SUCCESS') {
                setNotifications(res1.notifications);
                setNotificationIds(res1.notificationIds);
            }
        }
    };

    // This method allows the notifications to be removed from list
    const handleClearAllNotifications = async () => {
        const res = await clearAllNotifications();
        if (res === 'SUCCESS') {
            const res1 = await getNotifications(
                user.mail,
                startingNotificationId,
                startingNotificationId,
            );
            if (res1?.apiStatus === 'SUCCESS') {
                setNotifications(res1.notifications);
                setNotificationIds(res1.notificationIds);
            }
        }
    };

    const handleClearFunction = async (id: number) => {
        const res = await clearSingleNotification(id);
        if (res === 'SUCCESS') {
            const res1 = await getNotifications(
                user.mail,
                startingNotificationId,
                startingNotificationId,
            );
            if (res1?.apiStatus === 'SUCCESS') {
                setNotifications(res1.notifications);
                setNotificationIds(res1.notificationIds);
            }
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handler = (event: { target: any }) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                iconRef.current &&
                !iconRef.current.contains(event.target)
            ) {
                setShow(false);

                update();
            }
        };
        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    useEffect(() => {
        const fetchNotifications = async () => {
            if (user.mail) {
                const res = await getNotifications(user.mail, 0, 0);
                if (res?.apiStatus === 'SUCCESS') {
                    setNotifications(res.notifications);
                    setNotificationIds(res.notificationIds);
                }
            }
        };
        fetchNotifications();
    }, [user]);

    useEffect(() => {
        const unreadNotifications = notifications.filter((n) => !n.isRead);
        setCount(unreadNotifications.length);
        if (unreadNotifications.length > 0) {
            setNewNotification(true);
        } else {
            setNewNotification(false);
        }
    }, [notifications]);

    return (
        <div className="user-header">
            {user.id ? (
                <div className="info">
                    <h5>{user.name}</h5>
                    <h6>{user.jobTitle}</h6>
                </div>
            ) : (
                <div className="info">
                    <h5>{HEADER.LABEL.ANONYMOUS}</h5>
                </div>
            )}
            <div
                className="user-icon"
                ref={iconRef}
                onClick={() => {
                    setShow(!show);
                    if (show) {
                        update();
                    }
                }}
                role="presentation"
            >
                <div className="icon">
                    <div>{getUserShortName(user.name)}</div>
                </div>
                { count>0 ? (
                    <div className='notification-count'>
                    {count<9 ? (
                        <div>{count}</div>
                    ):(
                        <div>9+</div>
                    )}
                </div>):<></>}
                {/* <ArrowIcon /> */}
            </div>
            {show && (
                <div className="user-header-pop-up" ref={menuRef}>
                    <Notifications
                        notifications={notifications}
                        handleClearFunction={handleClearFunction}
                    />
                    <div className="buttons">
                        <button
                            type="button"
                            onClick={() => {
                                history.push(`/${CONSTANTS.RELATIVE_PATHS.settings.Url}`);
                                setShow(false);
                            }}
                        >
                            <SettingIconSvg />
                            {HEADER.BUTTON.SETTINGS}
                        </button>
                        <span />
                        <button type="button" onClick={() => handleClearAllNotifications()}>
                            <ClearSvg />
                            {HEADER.BUTTON.CLEAR}
                        </button>
                        <span />
                        <button type="button" onClick={() => onLogout()}>
                            <UserIconSvg />
                            {HEADER.BUTTON.LOG_OUT}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserHeader;
