import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAllReports } from '../../API/reports';
import ReportsTablePage from '../../components/reports/ReportsTablePage';
import CONSTANTS from '../../constants';
import { IPaginationData } from '../../interfaces';
import { IReports } from '../../interfaces/reports';
import ReportsTableFilters from '../../widgets/reports/ReportsTableFilters';
import './ReportsPage.scss';

interface ParamsType {
    reportName?: string;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
    filters: string;
}

// eslint-disable-next-line max-lines-per-function
const ReportsPage: React.FC = () => {
    const defaultReportsData = () => {
        const reportsList: IReports[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 4; i++) {
            reportsList.push({
                documentId: CONSTANTS.LOADING.NUMBER,
                documentNumber: CONSTANTS.LOADING.TEXT,
                ownerId: CONSTANTS.LOADING.NUMBER,
                creator: CONSTANTS.LOADING.TEXT,
                documentState: 'NEW',
                publishedOn: CONSTANTS.LOADING.DATE,
                createdAt: CONSTANTS.LOADING.DATE,
                title: CONSTANTS.LOADING.TEXT,
                submittedOn: CONSTANTS.LOADING.DATE,
                approvalDurationDays: CONSTANTS.LOADING.NUMBER_WITH_0,
                ChangeAdministrator: CONSTANTS.LOADING.TEXT,
                submitCount: CONSTANTS.LOADING.NUMBER_WITH_0,
                AdminResponseTimeHours: CONSTANTS.LOADING.NUMBER_WITH_0,
                ChangeAdministratorDecisionTime: CONSTANTS.LOADING.DATE,
                approverName: CONSTANTS.LOADING.TEXT,
                approverState: CONSTANTS.LOADING.TEXT,
                approverDecisionTime: CONSTANTS.LOADING.DATE,
                approverResponseTimeDays: CONSTANTS.LOADING.NUMBER_WITH_0,
            });
        }
        return reportsList;
    };
    const attributeOption = [
        { value: 'documentNumber', label: 'DCO Number' },
        { value: 'title', label: 'Title' },
        { value: 'creator', label: 'Creator' },
        { value: 'ChangeAdministrator', label: 'Change Administrator' },
        { value: 'createdAt', label: 'DCO Creation Date & Time' },
        { value: 'documentState', label: 'Lifecycle Status' },
        { value: 'submitCount', label: 'Submit Count' },
        { value: 'submittedOn', label: 'Latest Submit for Routing Date & Time' },
        {
            value: 'ChangeAdministratorDecisionTime',
            label: 'Latest Submit for Approval Date & Time',
        },
        { value: 'AdminResponseTimeHours', label: 'Admin Response Time in Hours' },
        { value: 'approverName', label: 'Approver' },
        { value: 'approverState', label: 'Decision' },
        { value: 'approverDecisionTime', label: 'Decision Date' },
        { value: 'approverResponseTimeDays', label: 'Total Days for Each approver' },
        { value: 'publishedOn', label: 'Change Approval Date' },
        { value: 'approvalDurationDays', label: 'Total Days for Approval' },
    ];
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [data, setData] = useState(defaultReportsData);
    const [filterParams, setFilterParams] = useState([]);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const reportName = queryParams.get('report_name');
    const reportType = queryParams.get('prev_page_type');

    // eslint-disable-next-line max-lines-per-function
    const update = async (
        pageIndex?: number,
        pageSize?: number,
        order?: string,
        orderType?: string | undefined,
    ) => {
        setData(defaultReportsData);
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const sortBy = order || 'createdAt';
        const sortOrder = orderType || 'DESC';
        const params: ParamsType = {
            limit: size,
            offset: index * size,
            sortBy,
            sortOrder,
            filters: JSON.stringify(filterParams),
        };
        if (reportName) {
            params.reportName = reportName;
        }
        if (!(reportName && filterParams.length === 0 && reportType !== 'STANDARD_REPORT')) {
            const reports = await getAllReports(qs.stringify(params));
            if (reports?.apiStatus === 'SUCCESS') {
                setData(reports.reportsList);
                setPagination({
                    pageSize: size,
                    pageIndex: index,
                    totalCount: reports.total,
                });
            }
        }
    };

    const handleReset = async () => {
        const params: ParamsType = {
            limit: 10,
            offset: 0,
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            filters: JSON.stringify([]),
        };
        setData(defaultReportsData);
        if (reportName) {
            params.reportName = reportName;
        }
        const reports = await getAllReports(qs.stringify(params));
        if (reports?.apiStatus === 'SUCCESS') {
            setData(reports.reportsList);
            setPagination({
                pageSize: 10,
                pageIndex: 0,
                totalCount: reports.total,
            });
        }
    };

    const handleSearch = async () => {
        update(0, 10, 'createdAt', 'DESC');
    };

    useEffect(() => {
        handleSearch();
    }, [filterParams]);

    return (
        <div className="reports-page-container">
            {reportName && (
                <div className="report-title">
                    <h1>{reportName}</h1>
                </div>
            )}
            <ReportsTableFilters
                handleReset={handleReset}
                setFilterParams={setFilterParams}
                attributeOption={attributeOption}
            />
            <ReportsTablePage
                data={data}
                pagination={pagination}
                fetchDocuments={(
                    pageIndex: number,
                    pageSize: number,
                    order: string,
                    orderType: string | undefined,
                ) => update(pageIndex, pageSize, order, orderType)}
            />
        </div>
    );
};
export default ReportsPage;
