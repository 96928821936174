import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getDynamicValues, getDynamicValuesPRB, getUserInfoByemail } from '../../../API/fields';
import CONSTANTS from '../../../constants';
import { useDocumentContext } from '../../../contexts/document-provider';
import { IOptionValue } from '../../../interfaces';

import { IField } from '../../../interfaces/document';
import STATIC_CONTENT from '../../../constants/StaticContent'
import { IDocumentAction } from '../../../reducers/document-reducer';
import './SingleSelect.scss';
import { useAuthDataContext } from '../../../contexts/user-auth-provider';
import { calculateYearGap } from '../../../utils/date';
import { isValuePresentIntheOptions } from '../../../utils';
import { fetchManagerReportees } from '../../../API/users';


interface Props {
    field: IField;
    isDisabled: boolean;
    value: string[];
    error: boolean;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
}

// eslint-disable-next-line max-lines-per-function
function fillOutTheFieldsForICMP(data: any, documentDispatch: React.Dispatch<IDocumentAction> | null, fields: IField[]) {
    if (documentDispatch) {
        const currentGradeOptions = fields.filter((item) => item.name === 'currentGrade');
        documentDispatch({
            type: 'number',
            inputName: 'employeeId',
            value: data.id,
        });
        documentDispatch({
            type: 'single-select-dropdown',
            inputName: 'managerName',
            value: [
                {
                    value: data.managerEmail,
                    label: data.managerEmployeeName,
                },
            ] as IOptionValue[],
        });
        documentDispatch({
            type: 'single-select-dropdown',
            inputName: 'eStaff',
            value: [
                {
                    value: data.estaffEmail,
                    label: data.estaffEmployeeName,
                },
            ] as IOptionValue[],
        });
        documentDispatch({
            type: 'text',
            inputName: 'currentPosition',
            value: data.jobTitle,
        });
        documentDispatch({
            type: 'text',
            inputName: 'functionalArea',
            value: data.department,
        });
        if (isValuePresentIntheOptions(data.currentGrade, currentGradeOptions[0].selectOption)) {
            documentDispatch({
                type: 'single-select-dropdown',
                inputName: 'currentGrade',
                value: [
                    {
                        value: data.currentGrade,
                        label: data.currentGrade,
                    },
                ] as IOptionValue[],
            });
        } else {
            documentDispatch({
                type: 'reset-single-field',
                inputName: 'currentGrade',
                value: '',
            });
        }
        if (data.dateHired) {
            documentDispatch({
                type: 'text',
                inputName: 'experienceAtEnphaseYears',
                value: calculateYearGap(data.dateHired),
            });
        } else {
            documentDispatch({
                type: 'reset-single-field',
                inputName: 'experienceAtEnphaseYears',
                value: '',
            });
        }
        documentDispatch({
            type: 'text',
            inputName: 'jobCode',
            value: data.jobCode,
        });
    }
}

// eslint-disable-next-line max-lines-per-function
function fillOutTheFieldsForLPNF(data: any, documentDispatch: React.Dispatch<IDocumentAction> | null, fields: IField[]) {
    if (documentDispatch) {
        const currentGradeOptions = fields.filter((item) => item.name === 'currentGrade');
        documentDispatch({
            type: 'number',
            inputName: 'employeeId',
            value: data.id,
        });
        documentDispatch({
            type: 'single-select-dropdown',
            inputName: 'managerName',
            value: [
                {
                    value: data.managerEmail,
                    label: data.managerEmployeeName,
                },
            ] as IOptionValue[],
        });
        documentDispatch({
            type: 'single-select-dropdown',
            inputName: 'eStaff',
            value: [
                {
                    value: data.estaffEmail,
                    label: data.estaffEmployeeName,
                },
            ] as IOptionValue[],
        });
        documentDispatch({
            type: 'text',
            inputName: 'functionalArea',
            value: data.department,
        });
        if (isValuePresentIntheOptions(data.currentGrade, currentGradeOptions[0].selectOption)) {
            documentDispatch({
                type: 'single-select-dropdown',
                inputName: 'currentGrade',
                value: [
                    {
                        value: data.currentGrade,
                        label: data.currentGrade,
                    },
                ] as IOptionValue[],
            });
        } else {
            documentDispatch({
                type: 'reset-single-field',
                inputName: 'currentGrade',
                value: '',
            });
        }
        if (data.dateHired) {
            documentDispatch({
                type: 'text',
                inputName: 'experienceAtEnphase',
                value: calculateYearGap(data.dateHired),
            });
        } else {
            documentDispatch({
                type: 'reset-single-field',
                inputName: 'experienceAtEnphase',
                value: '',
            });
        }
    }
}


// eslint-disable-next-line max-lines-per-function
const SingleSelect: React.FC<Props> = ({
    field,
    documentDispatch,
    isDisabled,
    value,
    error,
}: Props) => {
    const {
        docInfo,
        setDocInfo,
        isICMP,
        fieldsData,
        fields,
        accessibleByCurrentUser,
        travelRequestFor,
        isLeadershipForm,
        setTravelRequestFor,
        isTravelAuthorizationForm,
        changeApprovlStatusForTravelForm,
        changeApprovalStatusForLPNF
    } = useDocumentContext();
    const { user } = useAuthDataContext();
    const [loading, setLoading] = useState(false);
    const {TRAVEL_FORM} = STATIC_CONTENT;
    const [intialOptions, setIntialOptions] = useState<IOptionValue[]>([]);

    let staticSingleSelectFlag = false;
    if (field.selectType === 'static' && field.uiControl.label === 'Single Select Dropdown') {
        staticSingleSelectFlag = true;
    }

    
    // eslint-disable-next-line max-lines-per-function
    const fillOtherFields = async (email: string): Promise<any> => {
        const dummy = false;
        if (email) {
            const userData = await getUserInfoByemail(email);
            if (userData && userData.apiStatus === 'SUCCESS') {
                const data = userData.data[0];
                if(isICMP){
                    fillOutTheFieldsForICMP(data, documentDispatch ,fields);
                } else {
                    fillOutTheFieldsForLPNF(data, documentDispatch ,fields);
                    await changeApprovalStatusForLPNF(email);
                }
            }
        }
    };

    const updateTravelRequestFor = async (option : IOptionValue) => {
        const previousTravelRequester = travelRequestFor;
        setTravelRequestFor(option);
        const body : any = {};
        if((option.value === TRAVEL_FORM.TRAVEL_REQUEST_FOR.SELF || option.value === TRAVEL_FORM.TRAVEL_REQUEST_FOR.GUEST) && previousTravelRequester.value === TRAVEL_FORM.TRAVEL_REQUEST_FOR.OTHER_EMPLOYEE){
            body.travelRequestFor = [option.value];
            await changeApprovlStatusForTravelForm(user.mail, body);
        }
        else if(option.value === TRAVEL_FORM.TRAVEL_REQUEST_FOR.OTHER_EMPLOYEE){
            const travellerDetails = fieldsData.filter((item) => item.name === 'traveller');
            body.travelRequestFor = [option.value];
            if(travellerDetails[0].value !== ''){
                body.traveller = [travellerDetails[0].value[0].value];
                await changeApprovlStatusForTravelForm(travellerDetails[0].value[0].value, body);
            }
        }
    };

    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (field.selectType === 'dynamic') {
                if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                    const dynamicOptions = await getDynamicValues(field.id, inputValue);
                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                        resolve(dynamicOptions.dynamicData);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } else if (field.selectType === 'static') {
                if (field.selectOption) {
                    const filteredOptions = field.selectOption.filter((option) =>
                        option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
                    );
                    resolve(filteredOptions);
                }
                resolve(field.selectOption ? field.selectOption : []);
                setLoading(false);
            }
        });
    };

    const promiseOptionsForPRB = async (inputValue: string): Promise<IOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (field.selectType === 'dynamic') {
                if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                    const dynamicOptions = await getDynamicValuesPRB(user.id, field.id, inputValue);
                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                        resolve(dynamicOptions.dynamicData);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } else if (field.selectType === 'static') {
                if (field.selectOption) {
                    const filteredOptions = field.selectOption.filter((option) =>
                        option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
                    );
                    resolve(filteredOptions);
                }
                resolve(field.selectOption ? field.selectOption : []);
                setLoading(false);
            }
        });
    };

    const updateApprovalStatusForTravelAuthorizationForm = async (email : string) => {
        const body : any = {};
        body.travelRequestFor = [TRAVEL_FORM.TRAVEL_REQUEST_FOR.OTHER_EMPLOYEE];
        body.traveller = [email];
        await changeApprovlStatusForTravelForm(email,body);
    }

    useEffect(() => {
        const loadIntialOptions = async () => {

            const list = await fetchManagerReportees(user.mail);
            if (list?.apiStatus === 'SUCCESS') {
                setIntialOptions(list.users);
            }
        }
        if(field.name === 'employeeName' && (isICMP || isLeadershipForm)){
            loadIntialOptions();
        }
    },[]);

    return (
        <>
            {field.name === 'employeeName' && (isICMP || isLeadershipForm) ? (
                <AsyncSelect
                    name="single-select"
                    id="single-select"
                    isMulti={false}
                    className={`react-select ${error ? 'error' : ''}`}
                    classNamePrefix="select"
                    isLoading={loading}
                    loadOptions={promiseOptionsForPRB}
                    value={value}
                    getOptionLabel={(e) =>
                        !staticSingleSelectFlag
                        ? `${e.label} <${e.value}>`
                        : e?.label}
                    defaultOptions={staticSingleSelectFlag ? field.selectOption : intialOptions}
                    isDisabled={isDisabled || accessibleByCurrentUser}
                    placeholder={isDisabled ? '' : 'Start Typing...'}
                    onChange={(option) => {
                        if (option && documentDispatch) {
                            documentDispatch({
                                type: 'single-select-dropdown',
                                inputName: field.name,
                                value: [option] as IOptionValue[],
                            });
                            if (field.name === 'employeeName') {
                                fillOtherFields(option.value);
                                setDocInfo({
                                    ...docInfo,
                                    title: `PRB Nomination - ${option.label}`,
                                    description: `PRB Nomination - ${option.label}`,
                                });
                            }
                        }
                    }}
                />
            ) : (
                <AsyncSelect
                    name="single-select"
                    id="single-select"
                    isMulti={false}
                    className={`react-select ${error ? 'error' : ''}`}
                    classNamePrefix="select"
                    isLoading={loading}
                    loadOptions={promiseOptions}
                    value={value}
                    getOptionLabel={(e) =>
                        !staticSingleSelectFlag
                            ? `${e.label} <${e.value}>`
                            : e?.label
                    }
                    defaultOptions={staticSingleSelectFlag && field.selectOption}
                    isDisabled={isDisabled}
                    placeholder={isDisabled ? '' : 'Start Typing...'}
                    onChange={(option) => {
                        if (option && documentDispatch) {
                            documentDispatch({
                                type: 'single-select-dropdown',
                                inputName: field.name,
                                value: [option] as IOptionValue[],
                            });
                            if (field.name === 'employeeName') {
                                setDocInfo({
                                    ...docInfo,
                                    title: `PRB Nomination - ${option.label}`,
                                    description: `PRB Nomination - ${option.label}`,
                                });
                            }
                            if(isTravelAuthorizationForm){
                                if(field.name === 'travelRequestFor'){
                                    updateTravelRequestFor(option as IOptionValue)
                                }
                                if(field.name === 'traveller'){
                                    updateApprovalStatusForTravelAuthorizationForm(option.value)
                                }
                            }
                        }
                    }}
                />
            )}
        </>
    );
};

export default SingleSelect;
