/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import {
    IInactiveAProverLoading,
    IInactiveApproverAdminDocuments,
    IPaginationData,
} from '../../interfaces';
import CONSTANTS from '../../constants';
import { getApprovalTasksForAdmin } from '../../API/inactive-approvers';
import TaskApproversAdminTable from './TaskApproversAdminTable';

const defaultDocWithInactiveApprovers = () => {
    const myApprovals: IInactiveApproverAdminDocuments[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 4; i++) {
        myApprovals.push({
            documentId: CONSTANTS.LOADING.NUMBER,
            docNumber: CONSTANTS.LOADING.TEXT,
            documentType: CONSTANTS.LOADING.TEXT,
            inActiveApprover: CONSTANTS.LOADING.TEXT,
            inActiveApproverId: CONSTANTS.LOADING.NUMBER,
            owner: CONSTANTS.LOADING.TEXT,
            ownerId: CONSTANTS.LOADING.NUMBER,
            active: true,
        });
    }

    return myApprovals;
};

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: 5,
    totalCount: 0,
};

// eslint-disable-next-line max-lines-per-function
const ListAllTasksAdminPage: React.FC = () => {
    const [docWithInactiveApprovers, setDocWithInactiveApprovers] = useState(
        defaultDocWithInactiveApprovers,
    );
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [loadingState, setLoadingState] = useState<IInactiveAProverLoading | null>(null);

    const update = async (pageIndex?: number, pageSize?: number) => {
        const inactiveApproversIds: number[] = [];
        const OwnerIds: number[] = [];
        const documentNumber = '';
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;

        const documents = await getApprovalTasksForAdmin(
            OwnerIds,
            inactiveApproversIds,
            documentNumber,
            size,
            index * size,
        );
        if (documents?.apiStatus === 'SUCCESS') {
            setDocWithInactiveApprovers(documents.documentWithInactiveApprovers);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: documents.totalCount,
            });
        }
    };

    useEffect(() => {
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="task-approver-admin-table">
            <TaskApproversAdminTable
                data={docWithInactiveApprovers}
                pagination={pagination}
                loadingState={loadingState}
                fetchDocuments={(pageIndex: number, pageSize: number) =>
                    update(pageIndex, pageSize)
                }
            />
        </div>
    );
};

export default ListAllTasksAdminPage;
