/* eslint-disable max-lines-per-function */
import React, { createContext, useContext, useState } from 'react';

interface IAlias {
    label: string;
    value: string;
}
interface IInitialRoleInfo {
    name: string;
    alias: IAlias[];
}
const initialRoleInfo: IInitialRoleInfo = {
    name: '',
    alias: [],
};

interface IRoleContext {
    roleInfo: IInitialRoleInfo;
    changeRoleInfo: (name: string, value: string | IInitialRoleInfo[]) => void;
    active: () => boolean;
    resetInput: () => void;
    setEditInfo: (name: string, alias: IInitialRoleInfo[], id: number) => void;
    EditId: number;
}

const GroupContext = createContext<IRoleContext>({
    roleInfo: initialRoleInfo,
    changeRoleInfo: () => null,
    active: () => false,
    resetInput: () => null,
    setEditInfo: () => null,
    EditId: 0,
});

const GroupProvider: React.FC = (props: unknown) => {
    const [roleInfo, setRoleInfo] = useState(initialRoleInfo);
    const [isActive, setIsActive] = useState(false);
    const [EditId, setId] = useState(0);
    const changeRoleInfo = (name: string, value: string | IInitialRoleInfo[]) => {
        setRoleInfo({ ...roleInfo, [name]: value });
    };
    const active = () => {
        if (roleInfo.name !== '' && roleInfo.alias.length !== 0) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
        return isActive;
    };
    const resetInput = () => {
        setRoleInfo(initialRoleInfo);
    };
    const setEditInfo = (name: string, alias: any, id: number) => {
        setRoleInfo({ ...roleInfo, name, alias });
        setId(id);
    };

    return (
        <GroupContext.Provider
            value={{
                roleInfo,
                changeRoleInfo,
                active,
                resetInput,
                setEditInfo,
                EditId,
            }}
            {...props}
        />
    );
};

export const useRoleContext = (): IRoleContext => useContext(GroupContext);

export default GroupProvider;
