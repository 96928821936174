/* eslint-disable max-lines-per-function */
import {
    IOptionValue,
    IScopeTypes,
    IExecutionTypes,
    IRoleDepthTypes,
    IApprovalTypes,
} from '../interfaces';
import { IApprovalStage } from '../interfaces/document-type';

export type IApprovalStageAction =
    | { type: 'new' }
    | { type: 'update'; value: IApprovalStage[] }
    | { type: 'delete'; key: number }
    | { type: 'scope-type'; key: number; value: IScopeTypes }
    | { type: 'execution-type'; key: number; value: IExecutionTypes }
    | { type: 'approval-type'; key: number; value: IApprovalTypes }
    | { type: 'is-customApproval'; key: number; value: boolean }
    | { type: 'approval-groups'; key: number; value: IOptionValue }
    | { type: 'is-multiRole'; key: number; value: boolean }
    | { type: 'role-list'; key: number; value: IOptionValue[] }
    | { type: 'role-depth'; key: number; value: IRoleDepthTypes }
    | { type: 'remove-date'; key: number; index: number }
    | { type: 'is-editDocument'; key: number; value: boolean }
    | { type: 'no-of-days'; key: number; value: number }
    | { type: 'group-based-approval'; key: number; value: IOptionValue }
    | { type: 'approval-stage-title'; key: number; value: string }
    | { type: 'mandatory'; key: number; value: boolean };

export const initialFieldData: IApprovalStage = {
    id: 0,
    scopeType: 'roleBased',
    dueDate: [],
    executionType: 'SEQUENTIAL',
    approvalType: 'INDIVIDUAL',
    roleList: [],
    customApproval: false,
    multiApproval: false,
    approvalGroups: [],
    groupCanEdit: false,
    approvalStageTitle: '',
    mandatory: false,
};

export const approvalStageReducer = (
    state: IApprovalStage[],
    action: IApprovalStageAction,
): IApprovalStage[] => {
    switch (action.type) {
        case 'update': {            
            const tempState: IApprovalStage[] = [];
            return tempState.concat(action.value);
        }
        case 'new': {
            return [...state, { ...initialFieldData, id: state.length + 1, dueDate: [] }];
        }
        case 'delete':
            return [...state.slice(0, action.key), ...state.slice(action.key + 1)];
        case 'scope-type':
            if (action.value === 'roleBased' || action.value === 'groupBased') {
                return [
                    ...state.slice(0, action.key),
                    { ...state[action.key], scopeType: action.value, approvalType: 'INDIVIDUAL' },
                    ...state.slice(action.key + 1),
                ];
            }
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], scopeType: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'execution-type':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], executionType: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'is-customApproval':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], customApproval: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'is-editDocument':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], groupCanEdit: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'is-multiRole':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], multiApproval: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'approval-type':            
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], approvalType: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'role-list':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], roleList: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'approval-groups':            
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], approvalGroups: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'remove-date': {
            const selected = state[action.key];
            selected.dueDate.splice(action.index, 1);
            return [...state.slice(0, action.key), selected, ...state.slice(action.key + 1)];
        }
        case 'no-of-days': {
            const selected = state[action.key];
            selected.dueDate.push(action.value);
            return [...state.slice(0, action.key), selected, ...state.slice(action.key + 1)];
        }
        case 'role-depth':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], depth: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'group-based-approval':            
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], approvalGroups: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'approval-stage-title':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], approvalStageTitle: action.value },
                ...state.slice(action.key + 1),
            ];
        case 'mandatory':
            return [
                ...state.slice(0, action.key),
                { ...state[action.key], mandatory: action.value },
                ...state.slice(action.key + 1),
            ]
        default:
            return state;
    }
};
