/* eslint-disable no-restricted-syntax */
import { IFieldsData } from '../interfaces/document';

const convertToEpoch = (date: Date): number => Math.floor(date.getTime());

const formatNumber = (number: number): number => Math.round(number * 100) / 100;

const getUserShortName = (firstName: string, lastName?: string): string => {
    const firstLetter = firstName.charAt(0) ? firstName.charAt(0) : '';
    const secondLetter = lastName?.charAt(0) ? lastName.charAt(0) : '';
    if (firstLetter && secondLetter) {
        return `${firstLetter}${secondLetter}`;
    }
    if (firstLetter) {
        return `${firstLetter}`;
    }
    if (secondLetter) {
        return `${secondLetter}`;
    }

    return ''; // for anonymous
};

const getUserFullName = (firstName: string, lastName: string): string => `${firstName} ${lastName}`;

const getDocVersion = (majorVersion: number, minorVersion: string, versionState ?: string, state ?: string, allowDCO?:boolean): string => {
    if(versionState === "RERELEASE"){
        return (state === "DRAFT")? `${majorVersion}.${minorVersion} (RE-RELEASE) (${state})` : `${majorVersion}.${minorVersion} (RE-RELEASE)`;
    }
    if(versionState === "OBSOLETE"){
        return (state === "DRAFT")? `${majorVersion}.${minorVersion} (${versionState}) (${state})` : `${majorVersion}.${minorVersion} (${versionState})`;
    }
    return ((state === "DRAFT") && allowDCO)? `${majorVersion}.${minorVersion} (${state})` :`${majorVersion}.${minorVersion}`;
}
    // versionState === "RERELEASE" || versionState === "OBSOLETE" ? `${majorVersion}.${minorVersion} (${versionState})` : `${majorVersion}.${minorVersion}` ;

const copyToClipBoard = (text: string): void => {
    navigator.clipboard.writeText(text);
};

const stringToSlug = (str1: string): string => {
    let str = str1.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // // remove accents, swap ñ for n, etc
    // const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    // const to = 'aaaaaeeeeiiiioooouuuunc------';

    // for (let i = 0, l = from.length; i < l; i++) {
    //     str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    // }

    str = str
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
};

const toCamelCase = (str: string): string =>
    str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });

const getExtension = (filename: string): string => filename.replace(/^.*\./, '');

const bytesToSize = (bytes: number): string => {
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    if (Number(bytes) === 0) return '0 Byte';
    const i = Math.floor(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
};

const isValuePresentIntheOptions = (value : string, options : any) => {
    const isValuePresent = options.some((item: { value: any; }) => item.value === value);
    return isValuePresent;
}

const capitalizeFirstLetter = (string: string): string =>
    string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();

const getNextDate = (date: Date): Date => {
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow;
};

const getToCcUserFromFields = (fields: IFieldsData[]): string[] => {
    let users: string[] = [];
    for (const field of fields) {
        if (field.name === 'to' || field.name === 'cc') {
            users = [...users, ...(field.value as string[])];
        }
    }

    return users;
};

const ownerChangeString = (documentId: number, ownerId: number): string =>
    `${documentId}-${ownerId}`;

const inactiveString = (documentId: number, inactiveApproverId: number): string =>
    `${documentId}-${inactiveApproverId}`;

export {
    convertToEpoch,
    formatNumber,
    getUserShortName,
    getUserFullName,
    getDocVersion,
    copyToClipBoard,
    stringToSlug,
    getExtension,
    bytesToSize,
    capitalizeFirstLetter,
    getNextDate,
    toCamelCase,
    getToCcUserFromFields,
    inactiveString,
    ownerChangeString,
    isValuePresentIntheOptions,
};
