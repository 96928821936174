/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';

import { getAttachments, downloadAttachment } from '../../../API/attachments';
import { AttachmentIcon, DocumentIconSvg, DownloadIcon } from '../../../assets/images';
import { IAttachment, IAttachmentConfig } from '../../../interfaces/attachment';
import { bytesToSize } from '../../../utils';
import './ViewAttachments.scss';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { convertEpochToFormatDate } from '../../../utils/date';

const { DOCUMENT_PREVIEW } = STATIC_CONTENT;
const q1 = { question: 'Redline Document attached?', value: null };

interface IDownloadProps {
    attachmentId: number;
    fileName: string;
}

export const DownloadAttachment: React.FC<IDownloadProps> = ({ attachmentId, fileName }) => {
    const [downloading, setDownloading] = useState(false);
    const downloadFile = async () => {
        setDownloading(true);
        await downloadAttachment(attachmentId, fileName);
        setDownloading(false);
    };

    return (
        <div className="download-icon">
            {downloading ? (
                <Loader type="Oval" color="#000" height={14} width={14} />
            ) : (
                <DownloadIcon data-tip='Download' data-for='tooltip' style={{cursor: 'pointer'}} onClick={() => downloadFile()} />
            )}
            <ReactTooltip className='tooltip-docinfo' id="tooltip" effect="solid" place="top" />
        </div>
    );
};

interface IAttachmentInfo {
    attachments: IAttachment[];
    attachmentConfig: IAttachmentConfig;
}

const AttachmentInfo: React.FC<IAttachmentInfo> = ({ attachments, attachmentConfig }) =>
    attachmentConfig.allowDragDrop ? (
        <div className="attachment-list">
            {attachments.length > 0 && (
                <div className="attachment-item files-header">
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Name</h5>
                    </div>

                    <div className="attachment-name doc-description">
                        <h5 style={{ color: 'black' }}>Description</h5>
                    </div>

                    <div className="attachment-name uploaded">
                        <h5 style={{ color: 'black' }}>Uploaded on</h5>
                    </div>
                </div>
            )}
            {attachments.map((a) => (
                <div className="attachment-item" key={a.id}>
                    <div className="attachment-name">
                        <div
                            onClick={() => downloadAttachment(a.id, a.fileMeta.name)}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter' || event.key === ' ') {
                                    downloadAttachment(a.id, a.fileMeta.name);
                                }
                            }}
                            title="Download Attachment"
                        >
                            <AttachmentIcon style={{ transform: 'rotate(0deg)' }} width="17" />
                            <h5>{a.fileMeta.name}</h5>
                            {'\u00A0'}
                        </div>
                        <span className="file-size">{`(${(a.fileMeta.size / 1024 ** 2).toFixed(
                            2,
                        )}MB)`}</span>
                    </div>
                    <span className="doc-description">{a.description ? a.description : ''}</span>
                    <span className="uploaded">
                        {a.createdAt ? `Uploaded on ${convertEpochToFormatDate(a.createdAt)}` : ''}
                    </span>
                </div>
            ))}
        </div>
    ) : (
        <div className="attachment-info-1">
            {attachments.map((attachment) => (
                <div className="attachment__info" key={attachment.id}>
                    <DocumentIconSvg className="document-icon" />
                    <div className="attachment__detail">
                        <h4
                            onClick={() =>
                                downloadAttachment(attachment.id, attachment.fileMeta.name)
                            }
                            role="presentation"
                        >
                            {attachment.fileMeta.name}
                        </h4>
                        <span>{bytesToSize(attachment.fileMeta.size)}</span>
                    </div>
                    <DownloadAttachment
                        attachmentId={attachment.id}
                        fileName={attachment.fileMeta.name}
                    />
                </div>
            ))}
        </div>
    );

interface Props {
    activeDocVersion: number;
    attachmentConfig: IAttachmentConfig[];
    showTitle?: boolean;
    isDocFetched: boolean;
    allowReviseByAnyone: boolean;
    checklist: { question: string; value: null | string }[] | null;
}

const ViewAttachments: React.FC<Props> = ({
    activeDocVersion,
    attachmentConfig,
    showTitle = false,
    isDocFetched,
    allowReviseByAnyone,
    checklist
}) => {
    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const [attachmentMandatory, setattachmentMandatory] = useState(false);

    useEffect(() => {
        const fetchAttachments = async () => {
            const res = await getAttachments(activeDocVersion, allowReviseByAnyone);
            if (res?.apiStatus === 'SUCCESS') {
                setAttachments(res.attachments);
            }
        };
        if (isDocFetched) fetchAttachments();
    }, [activeDocVersion, isDocFetched]);

    useEffect(() => {
        if (Array.isArray(checklist)) {
            const item1 = checklist.find((item) => item.question === q1.question);
            if (item1) {
                const { value } = item1;
                if (value === null || value === 'no') {
                    setattachmentMandatory(false);
                } else setattachmentMandatory(true);
            }
        }
    }, [checklist]);

    return (
        <>
            {(attachments.length > 0 || showTitle) &&
                (attachmentConfig.length ? (
                    <div className="attachments">
                        <div className="view-attachments">
                            <h3>
                                Attachments{' '}
                                {attachments.length > 0 ? `(${attachments.length})` : ''}
                            </h3>
                            {attachments.length > 0 && (
                                <div className="attachment">
                                    {attachmentConfig.map((config) => (
                                        <div
                                            key={config.attachmentType}
                                            className="attachment__list"
                                        >
                                            {attachments.filter(
                                                (a) =>
                                                    a.fileMeta.attachmentType ===
                                                    config.attachmentType,
                                            ).length > 0 && (
                                                <>
                                                    <div
                                                        className={`attachment__type ${
                                                            config.isMandatory || attachmentMandatory
                                                                ? 'mandatory-field'
                                                                : ''
                                                        }`}
                                                    >
                                                        {config.displayName}
                                                    </div>
                                                    <AttachmentInfo
                                                        attachments={attachments.filter(
                                                            (a) =>
                                                                a.fileMeta.attachmentType ===
                                                                config.attachmentType,
                                                        )}
                                                        attachmentConfig={config}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {attachments.length === 0 && showTitle && (
                                <p className="no-attachment">{DOCUMENT_PREVIEW.NO_ATTACHMENTS}</p>
                            )}
                        </div>
                    </div>
                ) : null)}
        </>
    );
};

export default ViewAttachments;
