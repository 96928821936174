import React from 'react';
import './AdminDelegations.scss';
import Delegations from '../../components/delegations/Delegations';
import AdminSideNavigation from '../../components/admin-page/AdminSideNavigation';
import "../admin-dashboard/AdminDashboard.scss";

const AdminDelegations: React.FC = () => (
    <div className='admin-dashboard'>
        <AdminSideNavigation />
        <div className="admin-delegations admin-dashboard-main">
            <Delegations />
        </div>
    </div>
);

export default AdminDelegations;