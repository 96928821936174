import React from 'react';

const ColumnFilter = ({column}: {column: any}) => {
    const { filterValue , setFilter } = column;
    return (
        <div>
            <input 
                type="text"
                className="column-filter"
                value = {filterValue || ''}
                onChange = { e => setFilter(e.target.value)}
                placeholder='Search here ...'
            />
        </div>
    )
};

export default ColumnFilter;