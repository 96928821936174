import CONSTANTS from '../../constants';
import { IDateRangeFilter, IDocStatusFilter, IEffectivity } from '../../interfaces';
import { IFilterConfig } from '../../interfaces/filters';

// NOTE : changing name of filter can break the filters. need to test it after changing value

export const myApprovalsDefault = { approverState: 'UNDER_REVIEW' };
export const myGroupApprovalsDefault = { approverState: 'UNDER_REVIEW' };
export const myReviewsDefault = { approverState: 'UNDER_REVIEW' };
export const mySunsetDocsDefault = { approverState: 'ALL', effectivity: 'ALL' };
export const mySunsetTasksDefault = { action: 'PENDING' };
export const myDocumentsDefault = {};
export const myFavoritesDefault = {};
export const myLatestDocumentsDefault = {
    limit: '10',
    offset: '0',
    orderBy: 'updatedAt',
    sortOrder: 'DESC',
};
export const myApprovalsConfig: IFilterConfig[] = [
    {
        name: 'approverState',
        displayName: 'Status',
        componentType: 'STATUS',
        defaultValue: 'UNDER_REVIEW',
        type: 'APPROVAL',
    },
    {
        name: 'documentTypeCode',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
    // {
    //     name: 'documentTitle',
    //     displayName: 'Doc Title',
    //     componentType: 'TEXT_INPUT',
    // },
    {
        name: 'ownerId',
        displayName: 'Authors',
        componentType: 'USER_SEARCH',
    },
    {
        name: 'started',
        displayName: 'Notification Date',
        componentType: 'DOUBLE_DATE',
    },
    {
        name: 'finished',
        displayName: 'Completion Date',
        componentType: 'DOUBLE_DATE',
    },
];

export const myGroupApprovalsConfig: IFilterConfig[] = [
    {
        name: 'approverState',
        displayName: 'Status',
        componentType: 'STATUS',
        defaultValue: 'UNDER_REVIEW',
        type: 'APPROVAL',
    },
    {
        name: 'documentTypeCode',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'groupName',
        displayName: 'Group Name',
        componentType: 'GROUP_NAME',
    },
    {
        name: 'ownerId',
        displayName: 'Authors',
        componentType: 'USER_SEARCH',
    },
    {
        name: 'started',
        displayName: 'Notification Date',
        componentType: 'DOUBLE_DATE',
    },
    {
        name: 'finished',
        displayName: 'Completion Date',
        componentType: 'DOUBLE_DATE',
    },
];

export const myReviewsConfig: IFilterConfig[] = [
    {
        name: 'approverState',
        displayName: 'Status',
        componentType: 'STATUS',
        defaultValue: 'UNDER_REVIEW',
        type: 'REVIEW',
    },
    {
        name: 'documentTypeCode',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
    // {
    //     name: 'documentTitle',
    //     displayName: 'Doc Title',
    //     componentType: 'TEXT_INPUT',
    // },
    {
        name: 'documentAuthor',
        displayName: 'Authors',
        componentType: 'USER_SEARCH',
    },

    {
        name: 'started',
        displayName: 'Notification Date',
        componentType: 'DOUBLE_DATE',
    },
    {
        name: 'finished',
        displayName: 'Completion Date',
        componentType: 'DOUBLE_DATE',
    },
];

export const myFavoriteConfig: IFilterConfig[] = [
    {
        name: 'documentType',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'title',
        displayName: 'Doc Title',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'ownerId',
        displayName: 'Authors',
        componentType: 'USER_SEARCH',
    },
];

export const myLatestConfig: IFilterConfig[] = [
    {
        name: 'documentType',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'title',
        displayName: 'Doc Title',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'ownerId',
        displayName: 'Authors',
        componentType: 'USER_SEARCH',
    },
];

export const myDocumentsConfig: IFilterConfig[] = [
    {
        name: 'state',
        displayName: 'Status',
        componentType: 'STATUS',
        defaultValue: 'ALL',
    },
    {
        name: 'documentType',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'title',
        displayName: 'Doc Title',
        componentType: 'TEXT_INPUT',
    },
    {
        name: 'createdAt',
        displayName: 'Created Date',
        componentType: 'DOUBLE_DATE',
    },
    {
        name: 'approvedOn',
        displayName: 'Approved Date',
        componentType: 'DOUBLE_DATE',
    },
    {
        name: 'submittedOn',
        displayName: 'Submitted Date',
        componentType: 'DOUBLE_DATE',
    },
];

export const MySunsetDocsConfig: IFilterConfig[] = [
    {
        name: 'state',
        displayName: 'New Version Status',
        componentType: 'STATUS',
        defaultValue: 'ALL',
        type: 'SUNSET_DOCS_DATA',
    },
    {
        name: 'effectivity',
        displayName: 'Effectivity',
        componentType: 'EFFECTIVITY',
        defaultValue: 'ALL',
    },
    {
        name: 'documentType',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
];

export const MySunsetTasksConfig: IFilterConfig[] = [
    {
        name: 'action',
        displayName: 'Action',
        componentType: 'STATUS',
        defaultValue: 'PENDING',
        type: 'SUNSET_TASK',
    },
    {
        name: 'documentTypeCode',
        displayName: 'Doc Type',
        componentType: 'DOC_TYPE',
    },
    {
        name: 'documentNumber',
        displayName: 'Doc Number',
        componentType: 'TEXT_INPUT',
    },
];

export const STATUS_DATA: { label: string; value: IDocStatusFilter }[] = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'DRAFT',
        label: CONSTANTS.DOCUMENT_STATUS.DRAFT,
    },
    {
        value: 'UNDER_REVIEW',
        label: CONSTANTS.DOCUMENT_STATUS.UNDER_REVIEW,
    },
    {
        value: 'APPROVED',
        label: CONSTANTS.DOCUMENT_STATUS.APPROVED,
    },
    {
        value: 'REJECTED',
        label: CONSTANTS.DOCUMENT_STATUS.REJECTED,
    },
    {
        value: 'SUNSET',
        label: CONSTANTS.DOCUMENT_STATUS.SUNSET,
    },
    {
        value: 'PUBLISHED',
        label: CONSTANTS.DOCUMENT_STATUS.PUBLISHED,
    },
    {
        value: 'OBSOLETE',
        label: CONSTANTS.DOCUMENT_STATUS.OBSOLETE,
    },
];

export const STATUS_DATA_SUNSET_DOCS: { label: string; value: IDocStatusFilter }[] = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'DRAFT',
        label: CONSTANTS.DOCUMENT_STATUS.DRAFT,
    },
    {
        value: 'UNDER_REVIEW',
        label: CONSTANTS.DOCUMENT_STATUS.UNDER_REVIEW,
    },
    {
        value: 'APPROVED',
        label: CONSTANTS.DOCUMENT_STATUS.APPROVED,
    },
    {
        value: 'REJECTED',
        label: CONSTANTS.DOCUMENT_STATUS.REJECTED,
    },
    {
        value: 'SUNSET',
        label: CONSTANTS.DOCUMENT_STATUS.SUNSET,
    },
];

export const SEARCH_STATE_DATA: { label: string; value: IDocStatusFilter }[] = [
    {
        value: 'PUBLISHED',
        label: CONSTANTS.DOCUMENT_STATUS.PUBLISHED,
    },
    {
        value: 'SUNSET',
        label: CONSTANTS.DOCUMENT_STATUS.SUNSET,
    },
    {
        value: 'INACTIVE',
        label: CONSTANTS.DOCUMENT_STATUS.INACTIVE,
    },
    {
        value: 'OBSOLETE',
        label: CONSTANTS.DOCUMENT_STATUS.OBSOLETE,
    },
];

export const DATE_RANGE_DATA: { label: string; value: IDateRangeFilter }[] = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'PAST_24',
        label: CONSTANTS.DATE_RANGE_DATA.PAST_24,
    },
    {
        value: 'PAST_WEEK',
        label: CONSTANTS.DATE_RANGE_DATA.PAST_WEEK,
    },
    {
        value: 'PAST_MONTH',
        label: CONSTANTS.DATE_RANGE_DATA.PAST_MONTH,
    },
    {
        value: 'PAST_YEAR',
        label: CONSTANTS.DATE_RANGE_DATA.PAST_YEAR,
    },
    {
        value: 'CUSTOM',
        label: CONSTANTS.DATE_RANGE_DATA.CUSTOM,
    },
];

export const STATUS_DATA_APPROVAL: { label: string; value: IDocStatusFilter }[] = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'UNDER_REVIEW',
        label: CONSTANTS.DOCUMENT_STATUS.UNDER_REVIEW,
    },
    {
        value: 'APPROVED',
        label: CONSTANTS.DOCUMENT_STATUS.APPROVED,
    },
    {
        value: 'REJECTED',
        label: CONSTANTS.DOCUMENT_STATUS.REJECTED,
    },
];

export const STATUS_DATA_REVIEW: { label: string; value: IDocStatusFilter }[] = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'UNDER_REVIEW',
        label: CONSTANTS.DOCUMENT_STATUS.UNDER_REVIEW,
    },
    {
        value: 'COMPLETED',
        label: CONSTANTS.DOCUMENT_STATUS.COMPLETED,
    },
];
export const STATUS_DATA_SUNSET_TASK_ACTION: {
    label: string;
    value: IDocStatusFilter;
}[] = [
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'PENDING',
        label: CONSTANTS.DOCUMENT_STATUS.PENDING,
    },
    {
        value: 'REVISED',
        label: CONSTANTS.DOCUMENT_STATUS.REVISED,
    },
    {
        value: 'REPUBLISHED',
        label: CONSTANTS.DOCUMENT_STATUS.REPUBLISHED,
    },
    {
        value: 'OBSOLETED',
        label: CONSTANTS.DOCUMENT_STATUS.OBSOLETED,
    },
];

export const EFFECTIVITY_DATA: { label: string; value: IEffectivity }[] = [
    {
        value: 'IN_SUNSET',
        label: 'In Sunset',
    },
    {
        value: 'SUNSET30',
        label: '30 Days to sunset',
    },
    {
        value: 'SUNSET60',
        label: '60 Days to sunset',
    },
];
