/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { IOptionValue } from '../../../interfaces';
import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';
import CONSTANTS from '../../../constants';
import { getDynamicValues } from '../../../API/fields';
import { getAllEmailData } from '../../../API/document';
import Button from '../../button/Button';

const animatedComponents = makeAnimated();
interface Props {
    field: IField;
    documentId?: number;
    documentVersionId?: number;
    isDisabled: boolean;
    value: any[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const MultiSelectDropdown: React.FC<Props> = ({
    field,
    documentId,
    documentVersionId,
    isDisabled,
    value,
    error,
    documentDispatch,
}) => {
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [customValue, setCustomValue] = useState(value);
    const [allEmails, setAllEmails] = useState<IOptionValue[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const showSeeMore = documentId && field.isLargeData;
    const blurSelectInput = () => document.activeElement && (document.activeElement as HTMLElement).blur();

    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (field.selectType === 'dynamic') {

                if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                    let multiSearch = false;
                    if ((inputValue.indexOf('<') > -1)||inputValue.indexOf(',') > -1) {
                        multiSearch = true;
                    }
                    const dynamicOptions = await getDynamicValues(field.id, inputValue);

                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                        if ((multiSearch) && dynamicOptions.dynamicData && documentDispatch) {
                            const newElements = [ ...value, ...dynamicOptions.dynamicData];
                            const values = newElements.filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v2) === JSON.stringify(v)))===i)

                            documentDispatch({
                                type: 'multi-select-dropdown',
                                inputName: field.name,
                                value: values as IOptionValue[],
                            });
                            blurSelectInput();
                        }
                        resolve(dynamicOptions.dynamicData);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } else if (field.selectType === 'static') {
                resolve(field.selectOption ? field.selectOption : []);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (isExpanded) {
            setCustomValue(allEmails);
        } else {
            setCustomValue(value);
        }
    }, [isExpanded, value, allEmails]);

    // Reset the email values on change of documentVersionId
    useEffect(() => {
        setIsExpanded(false);
        setAllEmails([]);
    }, [documentVersionId]);

    // show SeeMore button when there are several emails to show
    const handleSeeMore = async () => {
        if (!allEmails.length && documentId && documentVersionId) {
            setIsLoading(true);
            const response = await getAllEmailData(documentId, documentVersionId, field.name);
            if (response) {
                const allEmailData = response.emails;
                const dynamicOptions = await getDynamicValues(field.id, '', allEmailData);
                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                    setAllEmails(dynamicOptions.dynamicData);
                }
                setIsLoading(false);
            }
        }
        setIsExpanded(!isExpanded);
    };

    const DropdownIndicator = (
        props: any
      ) => (
            <components.DropdownIndicator {...props}>
                {showSeeMore &&
                    <div className="seemore-section">
                        <Button
                            type="button"
                            onClick={handleSeeMore}
                            className="primary-btn"
                            isLoading={isLoading}
                            loaderColor="white"
                            disabled={false}
                        >
                            {isExpanded ? 'See Less -' : 'See More +'}
                        </Button>
                    </div>
                }
            </components.DropdownIndicator>
    );

    return (
        <AsyncSelect
            name="single-select"
            components={showSeeMore ? {DropdownIndicator} : animatedComponents}
            id="single-select"
            className={`react-select ${error ? 'error' : ''} ${showSeeMore ? 'indicator-enabled' : ''}`}
            classNamePrefix="select"
            value={documentId ? customValue : value}
            getOptionLabel={(e) => `${e.label} <${e.value}>`}
            isMulti
            isDisabled={isDisabled}
            isLoading={loading}
            placeholder={isDisabled ? '' : 'Start Typing...'}
            loadOptions={promiseOptions}
            noOptionsMessage={() =>
                searchText.length >= 3 ? 'No results found' : 'Start typing minimum 3 characters'
            }
            onChange={(option) => {
                if (option && documentDispatch) {
                    documentDispatch({
                        type: 'multi-select-dropdown',
                        inputName: field.name,
                        value: option as IOptionValue[],
                    });
                }
            }}
        />
    );
};

export default MultiSelectDropdown;
