import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { documentAction, getAllDocVersionIds, getDocumentRelationsInfo, getDocumentsDetails, updateDCOfields } from '../../API/document';
import { PlusIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import { IButtonStatus } from '../../interfaces';
import { IDocumentActionTypes, IVersionNumberInfo } from '../../interfaces/document';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    documentId: number;
    activeDocumentVersion: number;
}

const { DOC_TYPE_REVISE } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const ReviseModal: React.FC<Props> = ({ isModalOpen, documentId, onModalClose, activeDocumentVersion }) => {
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [versionType, setVersionType] = useState<'MAJOR' | 'MINOR' | 'CUSTOM' | 'RERELEASE'>('MINOR');
    const { docTypeInfo, versionNumberInfo } = useDocumentContext();
    const [minorV, setMinorV] = useState<number>(versionNumberInfo.minorVersionNumber+1);
    const [majorV, setMajorV] = useState<number>(versionNumberInfo.majorVersionNumber);
    const history = useHistory();
    
    const onClick = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const customVersionObj: IVersionNumberInfo = {
            minorVersionNumber: minorV,
            majorVersionNumber: majorV
        }
        const res = await documentAction(documentId, type, docTypeInfo, undefined, versionType, undefined, customVersionObj);
        if (res?.apiStatus === 'SUCCESS') {
            if(documentId && activeDocumentVersion){
                const responseData = await getDocumentRelationsInfo(Number(activeDocumentVersion));          
                if(responseData?.apiStatus === 'SUCCESS') {
                    const relationArr : any[] = responseData.data;
                    const draftRelationArr = relationArr.filter((obj) => obj?.state === "DRAFT");
                    if(draftRelationArr.length>0){
                        const versionIdList = await getAllDocVersionIds([documentId]);
                        const docVersionIds = versionIdList ? versionIdList?.documentVersionIds : [];
                        const NewVersionData = await getDocumentsDetails(docVersionIds);
                        const DCOList = draftRelationArr.map(obj => obj.activeVersionId);
                        const res1 = await updateDCOfields(DCOList,NewVersionData?.data,documentId);
                        if(res1?.apiStatus === 'SUCCESS'){
                            toast.success("Reference has been updated to this new version in all the draft DCOs this document is part of");
                        }
                    }
                }
            }
            toast.success(DOC_TYPE_REVISE.TOAST.SUCCESS_MSG.REVISED_SUCCESSFULLY);
            history.push(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
        } else {
            toast.error(DOC_TYPE_REVISE.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
        onModalClose(false);
    };

    const versionFieldsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentMinorVersion = versionNumberInfo.minorVersionNumber;
        const currentMajorVersion = versionNumberInfo.majorVersionNumber;
        const newVersion = Number(e.target.value);
        
        if(e.target.id === 'minor') {
            if((newVersion >= 0 && newVersion > currentMinorVersion) || (newVersion >= 0 && newVersion <= currentMinorVersion && majorV > currentMajorVersion)) {
                setMinorV(newVersion);                
            }
        } else if(e.target.id === 'major') {
            if(newVersion >= 1 && newVersion >= currentMajorVersion) {
                if(newVersion === currentMajorVersion && minorV <= currentMinorVersion) {
                    setMinorV(currentMinorVersion+1);
                }
                setMajorV(newVersion);
            }
        }
    }

    return (
        <div className="action-modal">
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="action__modal"
            >
                <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
                <div className="revise-modal">
                    <h4>{DOC_TYPE_REVISE.LABEL.SELECT_VERSION}</h4>
                    <div className="tab">
                        <button
                            type="button"
                            className={versionType === 'MINOR' ? 'active' : ''}
                            onClick={() => setVersionType('MINOR')}
                        >
                            {DOC_TYPE_REVISE.BUTTON.MINOR}
                        </button>
                        <button
                            type="button"
                            className={versionType === 'MAJOR' ? 'active' : ''}
                            onClick={() => setVersionType('MAJOR')}
                        >
                            {DOC_TYPE_REVISE.BUTTON.MAJOR}
                        </button>
                        {docTypeInfo.allowCustomVersioning ? (
                            <button
                                type="button"
                                className={versionType === 'CUSTOM' ? 'active' : ''}
                                onClick={() => setVersionType('CUSTOM')}
                            >
                                {DOC_TYPE_REVISE.BUTTON.CUSTOM}
                            </button>
                        ) : null}
                        {(docTypeInfo.allowDCO || docTypeInfo.allowRerelease) ? (
                            <button
                                type="button"
                                className={versionType === 'RERELEASE' ? 'active' : ''}
                                onClick={() => setVersionType('RERELEASE')}
                            >
                                {DOC_TYPE_REVISE.BUTTON.RERELEASE}
                            </button>
                        ) : null}
                    </div>
                    {versionType === 'CUSTOM' ? (
                        <div className='custom-version-inputs'>
                            <input
                                type="number"
                                id="major"
                                value={majorV}
                                onChange={(e) => versionFieldsHandler(e)}
                            />
                            <div><p>.</p></div>
                            <input
                                type="number"
                                id="minor"
                                value={minorV}
                                onChange={(e) => versionFieldsHandler(e)}
                            />
                        </div>
                    ) : null}
                    <button
                        type="button"
                        disabled={buttonStatus === 'LOADING'}
                        onClick={() => onClick('REVISE')}
                    >
                        {DOC_TYPE_REVISE.BUTTON.REVISE}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ReviseModal;
