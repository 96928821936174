/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import './DocTypeApprovalStage.scss';
import { getRolesForDropDown } from '../../API/roles';
import { DeleteSvg, DragIcon1Svg } from '../../assets/images';
import CONSTANTS from '../../constants';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import { IOptionValue, IOptionValueGrouped, IRoleDepthTypes, IScopeTypes } from '../../interfaces';
import { IApprovalStage, ICardError } from '../../interfaces/document-type';
import ReactDND from '../../components/ReactDND';
import { AddButton } from '../../components/General';
import Toggle from '../../components/toggle/Toggle';
import { getGroups } from '../../API/groups';
import { IGroupInfo } from '../../interfaces/groups';

const animatedComponents = makeAnimated();

interface Props {
    stageData: IApprovalStage;
    isDisabled: boolean;
    index: number;
    errors: ICardError;
}

const scopeType: { value: IScopeTypes; label: string }[] = [
    {
        value: 'roleBased',
        label: 'Role Based',
    },
    {
        value: 'managementChain',
        label: 'Management Chain',
    },
    {
        value: 'custom',
        label: 'Custom',
    },
    {
        value: 'groupBased',
        label: 'Group Based',
    },
    {
        value: 'travelForm',
        label: 'Travel Form Approvers',
    },
    {
        value : 'lpnfForm',
        label : 'LPNF Approvers',
    }
];

const rolesOption: IRoleDepthTypes[] = ['L1', 'L2', 'VP'];

const DocTypeApprovalStage: React.FC<Props> = ({ stageData, index, isDisabled, errors }) => {
    const { approvalStageDispatch } = useDocTypeContext();
    const [roles, setRoles] = useState<IOptionValueGrouped[]>([]);
    const [noOfDays, setNumberOfDays] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const mapResponseToValuesAndLabels = (data: { name: string; id: number; alias: string }) => ({
        value: data.id,
        label: data.name,
    });

    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setSearchText(inputValue);
        setIsLoading(true);
        const queryParams = {
            limit: '10',
            offset: '0',
            qp: inputValue,
        };
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const res = await getGroups(queryParams);
                if (res?.apiStatus === 'SUCCESS') {
                    setIsLoading(false);
                    const response = res.myGroups.map((i: IGroupInfo) =>
                        mapResponseToValuesAndLabels(i),
                    );
                    const data = response.filter((i: { value: number; label: string }) =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase()),
                    );
                    resolve(data);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        });
    };

    const getRolesList = async () => {
        const rolesRes = await getRolesForDropDown();
        if (rolesRes?.apiStatus === 'SUCCESS') {
            setRoles(rolesRes.roles);
        }
    };

    const moveCard = (dragIndex: number, hoverIndex: number) => {
        if (stageData.roleList) {
            const dragCard = stageData.roleList[dragIndex];
            approvalStageDispatch({
                type: 'role-list',
                key: index,
                value: update(stageData.roleList, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            });
        }
    };

    const removeRole = (id: number) => {
        if (id) {
            const { roleList } = stageData;
            const roleIndex = stageData.roleList?.findIndex((s) => s.value === id);
            if (roleList?.length > 0 && roleIndex !== -1) {
                approvalStageDispatch({
                    type: 'role-list',
                    key: index,
                    value: [...roleList.slice(0, roleIndex), ...roleList.slice(roleIndex + 1)],
                });
            }
        }
    };

    useEffect(() => {
        getRolesList();
    }, []);
    return (
        <div className="approval-stage">
            <div className="form-section">
                <div className="approval-stage-title">
                    <label htmlFor="approval-stage-title">Title</label>
                    <input
                        type="text"
                        id="approval-stage-title"
                        name="approval-stage-title"
                        value={stageData.approvalStageTitle}
                        onChange={(e) => {
                            approvalStageDispatch({
                                type: 'approval-stage-title',
                                key: index,
                                value: e.target.value as string,
                            });
                        }}
                        disabled={isDisabled}
                    />
                </div>

                <div>
                    <label htmlFor="scope-type">Approval Type</label>
                    <Select
                        name="scope-type"
                        id="scope-type"
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={scopeType}
                        value={{
                            value: stageData.scopeType,
                            label: scopeType.filter((a) => stageData.scopeType === a.value)[0]
                                .label,
                        }}
                        onChange={(option) => {
                            approvalStageDispatch({
                                type: 'scope-type',
                                key: index,
                                value: option?.value as IScopeTypes,
                            });
                        }}
                        isDisabled={isDisabled}
                    />
                    <div className="select-handler">
                        <input
                            type="radio"
                            id={`sequential-${index.toString()}`}
                            name={`sequential-${index.toString()}`}
                            value="sequential"
                            checked={stageData.executionType.toUpperCase() === 'SEQUENTIAL'}
                            onChange={() =>
                                approvalStageDispatch({
                                    type: 'execution-type',
                                    key: index,
                                    value: 'SEQUENTIAL',
                                })
                            }
                            disabled={isDisabled}
                        />
                        <label htmlFor={`sequential-${index.toString()}`}>Sequential</label>
                        <input
                            type="radio"
                            id={`parallel-${index.toString()}`}
                            name={`parallel-${index.toString()}`}
                            value="parallel"
                            checked={stageData.executionType.toUpperCase() === 'PARALLEL'}
                            onChange={() =>
                                approvalStageDispatch({
                                    type: 'execution-type',
                                    key: index,
                                    value: 'PARALLEL',
                                })
                            }
                            disabled={isDisabled}
                        />
                        <label htmlFor={`parallel-${index.toString()}`}>Parallel</label>
                    </div>
                </div>

                {stageData.scopeType === 'roleBased' && (
                    <div>
                        <label htmlFor="scope-type">Assignment Type</label>
                        <div className="select-handler">
                            <input
                                type="radio"
                                id={`individual-${index.toString()}`}
                                name={`individual-${index.toString()}`}
                                value="individual"
                                checked={stageData?.approvalType?.toUpperCase() === 'INDIVIDUAL'}
                                onChange={() =>
                                    approvalStageDispatch({
                                        type: 'approval-type',
                                        key: index,
                                        value: 'INDIVIDUAL',
                                    })
                                }
                                disabled={isDisabled}
                            />
                            <label htmlFor={`individual-${index.toString()}`}>Individual</label>
                            <input
                                type="radio"
                                id={`anyone-${index.toString()}`}
                                name={`anyone-${index.toString()}`}
                                value="anyone"
                                checked={stageData?.approvalType?.toUpperCase() === 'ANYONE'}
                                onChange={() =>
                                    approvalStageDispatch({
                                        type: 'approval-type',
                                        key: index,
                                        value: 'ANYONE',
                                    })
                                }
                                disabled={isDisabled}
                            />
                            <label htmlFor={`anyone-${index.toString()}`}>Anyone</label>
                        </div>
                    </div>
                )}
                {stageData.scopeType === 'roleBased' && stageData.approvalType === 'INDIVIDUAL' && (
                    <div>
                        <label htmlFor="role-list">Approval Roles</label>
                        <Select
                            name="role-list"
                            id="role-list"
                            components={animatedComponents}
                            options={roles}
                            defaultOptions
                            className={`react-select ${
                                errors.fieldList && errors.fieldList.includes('role-list')
                                    ? 'error'
                                    : ''
                            }`}
                            classNamePrefix="select"
                            isMulti
                            value={stageData.roleList}
                            onChange={(option) => {
                                approvalStageDispatch({
                                    type: 'role-list',
                                    key: index,
                                    value: option as IOptionValue[],
                                });
                            }}
                            isDisabled={isDisabled}
                        />
                    </div>
                )}

                {stageData.scopeType === 'roleBased' &&
                    stageData.roleList?.length > 0 &&
                    stageData.approvalType === 'INDIVIDUAL' && (
                        <div>
                            <label htmlFor="role-list" />
                            <div className="role-dnd">
                                {/* <DndProvider backend={HTML5Backend}> */}
                                <span className="role-dnd-hint">
                                    Drag and drop to rearrange sequence
                                </span>
                                {stageData.roleList?.map((role, i) => (
                                    <ReactDND
                                        key={role.value}
                                        index={i}
                                        id={role.value}
                                        moveCard={moveCard}
                                    >
                                        <div className="role-item">
                                            <h5>
                                                {role.label} <DragIcon1Svg />
                                            </h5>
                                            <DeleteSvg
                                                className="remove-icon"
                                                onClick={() =>
                                                    !isDisabled && removeRole(role.value as number)
                                                }
                                            />
                                        </div>
                                    </ReactDND>
                                ))}
                                {/* </DndProvider> */}
                            </div>
                        </div>
                    )}

                {stageData.scopeType === 'roleBased' && stageData.approvalType === 'ANYONE' && (
                    <div>
                        <label htmlFor="approval-groups">Approval Groups</label>
                        <AsyncSelect
                            name="approval-groups"
                            id="single-select"
                            components={animatedComponents}
                            isMulti={false}
                            value={stageData.approvalGroups}
                            className={`react-select ${
                                errors.fieldList && errors.fieldList.includes('approval-group')
                                    ? 'error'
                                    : ''
                            }`}
                            classNamePrefix="select"
                            isLoading={isLoading}
                            loadOptions={promiseOptions}
                            isDisabled={isDisabled}
                            placeholder={isDisabled ? '' : 'Select...'}
                            isClearable
                            noOptionsMessage={() =>
                                searchText.length >= 3
                                    ? 'No results found'
                                    : 'Start typing minimum 3 characters'
                            }
                            onChange={(option) => {
                                approvalStageDispatch({
                                    type: 'approval-groups',
                                    key: index,
                                    value: option as IOptionValue,
                                });
                            }}
                        />
                    </div>
                )}

                {stageData.scopeType === 'roleBased' && stageData.approvalType === 'INDIVIDUAL' && (
                    <div className="config-item first">
                        <label htmlFor="is-customApproval">Allow Multiple Roles</label>
                        <div className="config-item--section">
                            <Toggle
                                isChecked={stageData.multiApproval}
                                isDisabled={isDisabled}
                                onclick={() =>
                                    approvalStageDispatch({
                                        type: 'is-multiRole',
                                        key: index,
                                        value: !stageData.multiApproval,
                                    })
                                }
                            />
                        </div>
                    </div>
                )}

                {stageData.scopeType === 'groupBased' && (
                    <div>
                        <label htmlFor="scope-type">Assignment Type</label>
                        <div className="select-handler">
                            <input
                                type="radio"
                                id={`individual-${index.toString()}`}
                                name={`individual-${index.toString()}`}
                                value="individual"
                                checked={stageData?.approvalType?.toUpperCase() === 'INDIVIDUAL'}
                                onChange={() =>
                                    approvalStageDispatch({
                                        type: 'approval-type',
                                        key: index,
                                        value: 'INDIVIDUAL',
                                    })
                                }
                                disabled={isDisabled}
                            />
                            <label htmlFor={`individual-${index.toString()}`}>Individual</label>
                            <input
                                type="radio"
                                id={`anyone-${index.toString()}`}
                                name={`anyone-${index.toString()}`}
                                value="anyone"
                                checked={stageData?.approvalType?.toUpperCase() === 'ANYONE'}
                                onChange={() =>
                                    approvalStageDispatch({
                                        type: 'approval-type',
                                        key: index,
                                        value: 'ANYONE',
                                    })
                                }
                                disabled={isDisabled}
                            />
                            <label htmlFor={`anyone-${index.toString()}`}>Anyone</label>
                        </div>
                    </div>
                )}

                {stageData.scopeType === 'groupBased' && (
                    <div>
                        <label htmlFor="approval-groups">Approval Groups</label>
                        <AsyncSelect
                            name="approval-groups"
                            id="single-select"
                            components={animatedComponents}
                            isMulti={false}
                            value={stageData.approvalGroups}
                            className={`react-select ${
                                errors.fieldList && errors.fieldList.includes('approval-group')
                                    ? 'error'
                                    : ''
                            }`}
                            classNamePrefix="select"
                            isLoading={isLoading}
                            loadOptions={promiseOptions}
                            isDisabled={isDisabled}
                            placeholder={isDisabled ? '' : 'Select...'}
                            isClearable
                            noOptionsMessage={() =>
                                searchText.length >= 3
                                    ? 'No results found'
                                    : 'Start typing minimum 3 characters'
                            }
                            onChange={(option) => {
                                approvalStageDispatch({
                                    type: 'group-based-approval',
                                    key: index,
                                    value: option as IOptionValue,
                                });
                            }}
                        />
                    </div>
                )}

                {stageData.scopeType === 'managementChain' && (
                    <div>
                        <label htmlFor="role-depth">Depth</label>
                        <Select
                            name="role-depth"
                            id="role-depth"
                            isMulti={false}
                            className={`react-select ${
                                errors.fieldList && errors.fieldList.includes('role-depth')
                                    ? 'error'
                                    : ''
                            }`}
                            classNamePrefix="select"
                            options={rolesOption.map((t) => ({ value: t, label: t }))}
                            value={{ value: stageData.depth, label: stageData.depth }}
                            onChange={(option) => {
                                approvalStageDispatch({
                                    type: 'role-depth',
                                    key: index,
                                    value: option?.value as IRoleDepthTypes,
                                });
                            }}
                            isDisabled={isDisabled}
                        />
                    </div>
                )}
                <div>
                    <label htmlFor="due-date-value">Remind After</label>
                    <input
                        id={`${index.toString()}`}
                        name={`${index.toString()}`}
                        type="number"
                        min={1}
                        max={365}
                        value={noOfDays}
                        onChange={(e) => setNumberOfDays(parseInt(e.target.value, 10))}
                        disabled={isDisabled}
                    />
                    <span className="suffix">Days</span>
                    {!isDisabled && (
                        <div
                            className="add-btn"
                            onClick={() => {
                                if (noOfDays) {
                                    approvalStageDispatch({
                                        type: 'no-of-days',
                                        key: index,
                                        value: noOfDays,
                                    });
                                } else {
                                    // TODO : error message
                                }
                                setNumberOfDays(0);
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                                if (noOfDays) {
                                    approvalStageDispatch({
                                        type: 'no-of-days',
                                        key: index,
                                        value: noOfDays,
                                    });
                                } else {
                                    // TODO : error message
                                }
                                setNumberOfDays(0);
                            }}
                        >
                            <AddButton />
                        </div>
                    )}
                </div>

                {stageData.dueDate.length > 0 && (
                    <div>
                        <label htmlFor="role-list" />
                        <div className="role-dnd">
                            {stageData.dueDate?.map((date, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className="role-item" key={i}>
                                    <h5>{date} Days</h5>
                                    <DeleteSvg
                                        className="remove-icon"
                                        onClick={() =>
                                            approvalStageDispatch({
                                                type: 'remove-date',
                                                key: index,
                                                index: i,
                                            })
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="config-item first">
                    <label htmlFor="edit-document">Edit Document</label>
                    <div className="config-item--section" id="edit-document">
                        <Toggle
                            isChecked={stageData.groupCanEdit}
                            isDisabled={isDisabled}
                            onclick={() =>
                                approvalStageDispatch({
                                    type: 'is-editDocument',
                                    key: index,
                                    value: !stageData.groupCanEdit,
                                })
                            }
                        />
                    </div>
                </div>

                {(stageData.scopeType === 'roleBased' || stageData.scopeType === 'groupBased') &&
                    stageData.approvalType === 'INDIVIDUAL' && (
                        <div className="config-item first">
                            <label htmlFor="is-customApproval">Allow Custom Approvals</label>
                            <div className="config-item--section">
                                <Toggle
                                    isChecked={stageData.customApproval}
                                    isDisabled={isDisabled}
                                    onclick={() =>
                                        approvalStageDispatch({
                                            type: 'is-customApproval',
                                            key: index,
                                            value: !stageData.customApproval,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}

                {(stageData.scopeType === 'roleBased' || stageData.scopeType === 'groupBased') &&
                    stageData.approvalType === 'ANYONE' &&
                    !stageData.groupCanEdit && (
                        <div className="config-item first">
                            <label htmlFor="is-customApproval">Allow Custom Approvals</label>
                            <div className="config-item--section">
                                <Toggle
                                    isChecked={stageData.customApproval}
                                    isDisabled={isDisabled}
                                    onclick={() =>
                                        approvalStageDispatch({
                                            type: 'is-customApproval',
                                            key: index,
                                            value: !stageData.customApproval,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}
            </div>
            <div className="action-buttons">
                <span className="line" />
                <div>
                    <h5>Mandatory</h5>
                    <Toggle
                        isChecked={stageData.mandatory}
                        isDisabled={isDisabled}
                        onclick={() =>
                            approvalStageDispatch({
                                type: 'mandatory',
                                key: index,
                                value: !stageData.mandatory,
                            })
                        }
                    />
                </div>
                <div
                    className="delete"
                    onClick={() =>
                        !isDisabled && approvalStageDispatch({ type: 'delete', key: index })
                    }
                    role="presentation"
                >
                    <h5>Delete</h5>
                    <DeleteSvg />
                </div>
                {/* <DragIconSvg /> */}
            </div>
        </div>
    );
};

export default DocTypeApprovalStage;
