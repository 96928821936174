export const getFromLocalStorage = (key: string): string | null => localStorage.getItem(key);
 
export const setLocalStorage = (key: string, value: string): void => {
    localStorage.setItem(key, value);
    const event = new CustomEvent('localStorageUpdate', { detail: { key, value } });
    window.dispatchEvent(event);
};
 
export const removeFromLocalStorage = (key: string): void => {
    localStorage.removeItem(key);
    const event = new CustomEvent('localStorageUpdate', { detail: { key, value: null } });
    window.dispatchEvent(event);
};
 
export const clearAllLocalStorage = (): void => {
    localStorage.clear();
    const event = new CustomEvent('localStorageUpdate', { detail: { key: 'all', value: null } });
    window.dispatchEvent(event);
}
 
export const keyExistInLocalStorage = (key: string): boolean =>
    !(localStorage.getItem(key) === null);