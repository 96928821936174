import React from 'react';
import './AdminDashboard.scss';
import DocumentStatusCount from '../../components/admin-page/DocumentStatusCount';
import Button from '../../components/button/Button';
import ListAllTasksAdminPage from '../../components/admin-page/ListAllTasksAdminPage';
import ListAllDocuments from '../../components/admin-page/ListAllDocuments';
import AdminSideNavigation from '../../components/admin-page/AdminSideNavigation';

// eslint-disable-next-line max-lines-per-function
const AdminDashboard: React.FC = () => (
    <>
        <div className="admin-dashboard">
            <div className="admin-dashboard-main">
                <AdminSideNavigation />
                <div className="status-panel">
                    <DocumentStatusCount />
                </div>

                <div className="admin-panel">
                    <div className="admin-panel-header">
                        <h2 className="admin-panel-heading">Documents</h2>
                        <Button
                            disabled={false}
                            isLoading={false}
                            className="viewMore"
                            onClick={() => {
                                window.location.href = `${process.env.PUBLIC_URL}/adm/documents`;
                            }}
                            type="button"
                        >
                            View All Documents
                        </Button>
                    </div>
                    <div className="admin-panel-content">
                        <ListAllDocuments />
                    </div>
                </div>
                <div className="admin-panel">
                    <div className="admin-panel-header">
                        <h2 className="admin-panel-heading">Tasks</h2>
                        <Button
                            disabled={false}
                            isLoading={false}
                            className="viewMore"
                            onClick={() => {
                                window.location.href = `${process.env.PUBLIC_URL}/adm/tasks`;
                            }}
                            type="button"
                        >
                            View All Tasks
                        </Button>
                    </div>
                    <div className="admin-panel-content">
                        <ListAllTasksAdminPage />
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default AdminDashboard;
