import { deleteRequest, getRequest, postRequest, putRequest } from '../utils/api-request';
import { IAPIResponseStatus, IOptionValue, IStringObject } from '../interfaces';
import { IGroupInfo } from '../interfaces/groups';

// eslint-disable-next-line consistent-return
const getGroups = async (queryParams: IStringObject): Promise<any | undefined> => {
    try {
        let query = '';
        // eslint-disable-next-line no-prototype-builtins
        if (queryParams.hasOwnProperty('qp')) {
            query = `qp=${queryParams.qp}&limit=${queryParams.limit}&offset=${queryParams.offset}`;
        } else {
            query = `limit=${queryParams.limit}&offset=${queryParams.offset}`;
        }
        const response = await getRequest(`users/dms-groups/?${query}`);
        if (response?.apiStatus === 'SUCCESS') {
            const myGroups: IGroupInfo[] = [];
            const groups = response.response.rows;
            // eslint-disable-next-line no-restricted-syntax
            for (const group of groups) {
                myGroups.push({ name: group.name, alias: group.aliasEmail, id: group.id });
            }
            return {
                apiStatus: response.apiStatus,
                myGroups,
                totalGroups: response.response.count,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, myGroups: [], totalGroups: 0 };
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

const addGroup = async (payload: {
    name: string;
    aliasEmail: string;
}): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await postRequest('users/dms-groups', payload);
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }

        if (response?.apiStatus === 'FAILURE') {
            const { message } = response.response;
            return { apiStatus: response.apiStatus, errorMessage: message };
        }

        return undefined;
    } catch (e) {
        return undefined;
    }
};

const editGroup = async (payload: {
    name: string;
    aliasEmail: string;
    id: number;
}): Promise<{ apiStatus: IAPIResponseStatus; errorMessage: string } | undefined> => {
    try {
        const response = await putRequest('users/dms-groups', payload);
        if (response?.apiStatus === 'SUCCESS') {
            return { apiStatus: response.apiStatus, errorMessage: '' };
        }

        if (response?.apiStatus === 'FAILURE') {
            const { message } = response.response;
            return { apiStatus: response.apiStatus, errorMessage: message };
        }

        return undefined;
    } catch (e) {
        return undefined;
    }
};

const deleteGroup = async (id: number): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await deleteRequest(`users/dms-groups?id=${id}`);
        if (res) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line consistent-return
const groupSearch = async (
    query: string,
): Promise<{ apiStatus: IAPIResponseStatus; emailGroup: any[] } | undefined> => {
    try {
        const response = await getRequest(`users/dms-groups/search-by-email?email=${query}`);
        if (response?.apiStatus === 'SUCCESS') {
            const emailGroup: any[] = [];
            if (response.response.length > 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const item of response.response) {
                    emailGroup.push({
                        primary_email: item.primary_email,
                    });
                }
            }
            return { apiStatus: response.apiStatus, emailGroup };
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

const getGroupById = async (
    id: number,
    // eslint-disable-next-line consistent-return
): Promise<{ apiStatus: IAPIResponseStatus; myGroups: IOptionValue[] } | undefined> => {
    try {
        const response = await getRequest(`users/dms-groups?id=${id}`);
        if (response?.apiStatus === 'SUCCESS') {
            const myGroups: IOptionValue[] = [];
            const groups = response.response;
            // eslint-disable-next-line no-restricted-syntax
            myGroups.push({ label: groups.name, value: groups.id });
            return {
                apiStatus: response.apiStatus,
                myGroups,
            };
        }
    } catch (e) {
        return undefined;
    }
};

const getUserGroups = async (): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          myGroups: { id: number; name: string; aliasEmail: string }[];
      }
    | undefined
> => {
    try {
        const response = await getRequest('users/dms-groups/me');
        if (response?.apiStatus === 'SUCCESS') {
            return {
                apiStatus: response.apiStatus,
                myGroups: response.response,
            };
        }
        if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, myGroups: [] };
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
};

export { getGroups, groupSearch, deleteGroup, getGroupById, getUserGroups, addGroup, editGroup };
