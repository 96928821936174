import React, { useState } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { useHistory } from 'react-router-dom';
import './DocumentAction.scss';

import { toast } from 'react-toastify';
import { IButtonStatus, IUserOptionValue } from '../../interfaces';
import { userSearch } from '../../API/users';
import { documentActionForward } from '../../API/document';
import { createComment } from '../../API/comment';
import CONSTANTS from '../../constants';
import { PlusIconSvg } from '../../assets/images';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';

const animatedComponents = makeAnimated();
const { FORWARD_DOC, DOC_COMMENTS } = STATIC_CONTENT;

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    documentId: number;
    hideUsers: number[];
    taskId: string;
    activeDocumentVersion: number;
    unsavedComment: string;
    setUnsavedCommentFunction: any;
}

// eslint-disable-next-line max-lines-per-function
const ForwardDocModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    documentId,
    taskId,
    hideUsers,
    activeDocumentVersion,
    unsavedComment,
    setUnsavedCommentFunction
}) => {
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [isLoading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUserOptionValue>();
    const [error, setError] = useState(false);
    const { docTypeInfo } = useDocumentContext();
    const history = useHistory();

    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users.filter(
                        (u) => !hideUsers.includes(u.value as number),
                    );
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const onSave = async () => {
        if (selectedUser) {
            setButtonStatus('LOADING');
            // first save the comments if any
            if(unsavedComment) {
                const commentRes = await createComment(activeDocumentVersion, unsavedComment, docTypeInfo.allowReviseByAnyone);
                if (commentRes?.apiStatus === 'SUCCESS') {
                    setUnsavedCommentFunction('');
                } else {
                    toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
                }
            }
            const res = await documentActionForward(
                documentId,
                selectedUser.value as number,
                taskId,
            );
            if (res?.apiStatus === 'SUCCESS') {
                toast.success(`Forwarded to ${selectedUser.label}`);
                history.push(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}`);
            } else {
                toast.error(FORWARD_DOC.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
            setButtonStatus('DEFAULT');
        } else {
            setError(true);
        }
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose(false)}
            contentLabel="Example Modal"
            className="forward_doc_modal"
        >
            <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
            <div>
                <div className="search-wrapper">
                    <label htmlFor="user-search">{FORWARD_DOC.LABEL.SELECT_USER}</label>
                    <span className="note">{FORWARD_DOC.LABEL.LENGTH_CONSTRAINT}</span>
                    <AsyncSelect
                        name="user-search"
                        id="user-search"
                        components={animatedComponents}
                        isLoading={isLoading}
                        isMulti={false}
                        placeholder="Search user..."
                        className={`react-select user-search ${error ? 'error' : ''}`}
                        classNamePrefix="select"
                        loadOptions={promiseOptions}
                        getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                        onChange={(option) => {
                            setSelectedUser(option as IUserOptionValue);
                            setError(false);
                        }}
                    />
                </div>
                <div className="button-grp">
                    <button type="submit" className="primary-btn" onClick={() => onSave()} disabled={buttonStatus === 'LOADING'}>
                        {FORWARD_DOC.BUTTON.SAVE}
                    </button>
                    <button type="submit" onClick={() => onModalClose(false)}>
                        {FORWARD_DOC.BUTTON.CANCEL}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ForwardDocModal;
