import React , {useEffect, useState} from "react";
import AsyncSelect from 'react-select/async';
import makeAnimated from "react-select/animated";
import { IUserOptionValue } from "../../../interfaces";
import STATIC_CONTENT from "../../../constants/StaticContent";
import CONSTANTS from "../../../constants";
import { userSearch } from "../../../API/users";
import './HRBPInfoFilters.scss'

const { HRBP_INFO_SETTINGS } = STATIC_CONTENT;
const animatedComponents = makeAnimated();

const DEFAULT_HRBP: IUserOptionValue[] = [
    {
        jobTitle: '',
        label: 'All',
        role: '',
        roleId: 0,
        value: 0,
        activeUser: false,
    },
];

interface SelectOwnerProps {
    hrbp: IUserOptionValue | null;
    updateHRBP: (approver: IUserOptionValue) => void;
}

// eslint-disable-next-line max-lines-per-function
const HRBPInfoFilter: React.FC<SelectOwnerProps> = ({ hrbp, updateHRBP }) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setSearchText(inputValue);
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    if (userList.length > 0) {
                        resolve([...DEFAULT_HRBP, ...userList]);
                    } else if (inputValue.toLowerCase() === 'all') {
                        resolve(DEFAULT_HRBP);
                    } else {
                        resolve([]);
                    }
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (!hrbp) {
            updateHRBP({ jobTitle: '', label: 'All', value: 0, activeUser: true, role: '', roleId: 0 });
        }
    }, [hrbp, updateHRBP]);

    return (
        <div className="hrbp-select-user">
            <label htmlFor="user-search" className="select-user__label">
                {HRBP_INFO_SETTINGS.LABEL.SELECT_HRBP}
            </label>
            <AsyncSelect
                name="user-search"
                id="user-search"
                components={animatedComponents}
                isLoading={isLoading}
                placeholder="Search user..."
                className="react-select select-approver__select"
                classNamePrefix="select"
                loadOptions={promiseOptions}
                getOptionLabel={(e) => e.jobTitle ? `${e.label} | ${e.jobTitle}` : e.label }
                value={hrbp}
                noOptionsMessage={() =>
                    searchText.length >= 3
                        ? 'No results found'
                        : 'Start typing minimum 3 characters'
                }
                onChange={(option) => updateHRBP(option as IUserOptionValue)}
                isClearable
            />
        </div>
    );
};

export default HRBPInfoFilter;
