import React, { useEffect, useState } from 'react';
import './ReleaseNotes.scss';
import Markdown from 'markdown-to-jsx';
import  getMarkdown  from '../../API/Documentation';
import ScrollButton from '../../components/ScrollButton';

  
const ReleaseNotes: React.FC = () => {
    const [md, setMd] = useState('');
    const getFileContent = async () => {
        const data = await getMarkdown('releasenotes');
        if (data) {
            setMd(data);
        }
    };

    useEffect(() => {
        getFileContent();
    }, []);

    return (
        <div className="release-notes">
            <Markdown>{md}</Markdown>
            <ScrollButton/>
        </div>
    );
};

export default ReleaseNotes;
