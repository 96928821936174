import React from 'react';
import Select from 'react-select';
import CONSTANTS from '../../../constants';
import { IDateRangeFilter } from '../../../interfaces';

interface Props {
    name: string;
    displayName: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFilters: (name: string, value: any, type: string) => void;
    options: {
        label: string;
        value: IDateRangeFilter;
    }[];
    value: IDateRangeFilter;
    placeholder: string | undefined;
}

const DateRange: React.FC<Props> = ({
    value,
    name,
    displayName,
    updateFilters,
    options,
    placeholder,
}) => (
    <div>
        <label htmlFor={name}>{displayName}</label>
        <Select
            name={name}
            id={name}
            isMulti={false}
            className="react-select"
            classNamePrefix="select"
            options={options}
            isSearchable={false}
            placeholder={placeholder}
            value={
                value === null
                    ? null
                    : {
                          value,
                          label: CONSTANTS.DATE_RANGE_DATA[value],
                      }
            }
            onChange={(option) => {
                updateFilters(name, option?.value, 'DATE_RANGE');
            }}
        />
    </div>
);

export default DateRange;
