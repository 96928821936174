/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { add } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import './Filters.scss';
// import { toast } from 'react-toastify';
import { IFilterConfig } from '../../interfaces/filters';
import { STATUS_DATA, DATE_RANGE_DATA, EFFECTIVITY_DATA } from './filter-config';
import DocStatus from './filter-fields/DocStatus';
import DateRangefrom from './filter-fields/DateRange';
import TextInput from './filter-fields/TextInput';
import UserSearch from './filter-fields/UserSearch';
import DoubleDateFilter from './filter-fields/DoubleDateFilter';
import DocTypeFilter from './filter-fields/DocTypeFilter';
import GroupNameFilter from './filter-fields/GroupNameFilter';
import SelectFromOptions from './filter-fields/SelectFromOptions';
import { IAdditionalSearch, IStringObject } from '../../interfaces';
import STATIC_CONTENT from '../../constants/StaticContent';
import Button from '../button/Button';
import Effectivity from './filter-fields/Effectivity';

const NotApplicable: React.FC = () => <div>N/A</div>;

const { FILTERS } = STATIC_CONTENT;

const SkeletonFilter = (): JSX.Element => (
    <div>
        <div>
            <label htmlFor="">
                <Skeleton width={100} />
            </label>
            <div>
                <Skeleton width="100%" />
            </div>
        </div>
    </div>
);

interface Props {
    filtersConfig: IFilterConfig[];
    filterSearch: (queryParams: IStringObject) => void;
    documentCount: number;
    resetFilters: () => void;
    loading: boolean;
    usedBy?: 'SEARCH' | 'OTHER';
    searchfilters?: IAdditionalSearch[];
    deletedFilter?: string;
}

const Filters: React.FC<Props> = ({
    filtersConfig,
    filterSearch,
    documentCount,
    resetFilters,
    loading,
    usedBy = 'OTHER',
    searchfilters,
    deletedFilter
}) => {
    const [filters, setFilters] = useState<{ [key: string]: any }>({});
    const [isCustomRangeSet, setisCustomRangeSet] = useState(true);
    const [textFilters, setTextFilters] = useState<{ [key: string]: any }>({});

    const updateFilters = (name: string, value: any, type: string) => {
        // console.log(`filter name: ${JSON.stringify(name)}`);
        if (type === 'TEXT_INPUT') {
            setTextFilters({ ...textFilters, [name]: value });
        } else {
            setFilters({ ...filters, [name]: value });
        }
    };

    const resetTextFilters = () => {
        setTextFilters([]);
    };

    const convertFiltersToQs = (): IStringObject => {
        const queryParams: IStringObject = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const config of filtersConfig) {
            if (config.componentType === 'DOC_TYPE' || config.componentType === 'USER_SEARCH') {
                //  condition to send username for filter value accepting names
                if (filters[config.name]) {
                    if (config.name === 'documentAuthor') {
                        queryParams[config.name] =
                            filters[config.name].label !== 'All'
                                ? filters[config.name].label
                                : null;
                    } else {
                        queryParams[config.name] =
                            filters[config.name].value && filters[config.name].value !== 'all'
                                ? filters[config.name].value
                                : null;
                    }
                }
            } else if (config.componentType === 'DOUBLE_DATE') {
                queryParams[`${config.name}Start`] = filters[`${config.name}Start`];
                if (filters[`${config.name}End`]) {
                    const fromDate = add(filters[`${config.name}End`], {
                        hours: 23,
                        minutes: 59,
                        seconds: 59,
                    });
                    queryParams[`${config.name}End`] = `${fromDate}`;
                } else {
                    queryParams[`${config.name}End`] = filters[`${config.name}End`];
                }
            } else if (config.componentType === 'STATUS') {
                queryParams[config.name] =
                    filters[config.name] !== 'ALL' ? filters[config.name] : null;
            } else if (config.componentType === 'SELECT_FROM_OPTION') {
                queryParams[config.name] = filters[config.name] ? filters[config.name].value : null;
            } else {
                queryParams[config.name] = filters[config.name];
            }
        }
        let wasCustomRangeSet = false;
        if (searchfilters !== undefined && searchfilters.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const filterelement of searchfilters) {
                // updateFilters(filterelement.displayName,filterelement.value);
                if (
                    (!wasCustomRangeSet &&
                        (filterelement.displayName === 'Custom Date RangeStart' ||
                            filterelement.displayName === 'Custom Date RangeEnd')) ||
                    filterelement.displayName === 'Effective Date RangeStart' ||
                    filterelement.displayName === 'Effective Date RangeEnd'
                ) {
                    // skip, assuming custom date is initiall followed by ranges
                } else if (
                    (queryParams[filterelement.displayName] == null ||
                        queryParams[filterelement.displayName] === undefined) &&
                    (filterelement.value != null || filterelement.value !== undefined)
                ) {
                    if (
                        filterelement.displayName === 'Date Range' &&
                        filterelement.value === 'CUSTOM'
                    ) {
                        wasCustomRangeSet = true;
                    }
                    queryParams[filterelement.displayName] = filterelement.value;
                }
            }
        }
        return queryParams;
    };

    const setFilterToDefault = () => {
        const config: { [key: string]: unknown } = {};
        config['Date Range'] = null; /* filtersConfig initialized only in the beginning */
        // eslint-disable-next-line no-restricted-syntax
        for (const filter of filtersConfig) {
            if (filter.componentType === 'STATUS' || filter.name === 'Date Range') {
                config[filter.name] = filter.defaultValue ? filter.defaultValue : null;
            } else if (filter.componentType === 'DOUBLE_DATE') {
                config[`${filter.name}Start`] = null;
                config[`${filter.name}End`] = null;
            } else {
                config[filter.name] = null;
            }
        }
        setFilters(config);
        resetTextFilters();
    };

    const showResults = () => {
        setFilters({
            ...filters,
            documentNumber: textFilters.documentNumber,
            title: textFilters.title,
        });

        // if (filters['Date Range'] === 'CUSTOM'
        //  && ((filters['Custom Date RangeStart'] !== null && filters['Custom Date RangeEnd'] === null) || (filters['Custom Date RangeStart'] === null && filters['Custom Date RangeEnd'] !== null))) {
        //     toast.error('Please Fill in Custom  Date Range !!');
        // } else {
        //     filterSearch(convertFiltersToQs());
        // }
    };

    useEffect(() => {
        if (deletedFilter && deletedFilter !== 'All') {
            setFilters({ ...filters, [deletedFilter]: null });
        } else if (deletedFilter && deletedFilter === 'All') {
            setFilters({});
        }
    }, [deletedFilter]);

    useEffect(() => {
        setFilterToDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (filters['Effective Date RangeStart'] || filters['Effective Date RangeEnd']) {
            filterSearch(convertFiltersToQs());
        } else {
            // If user has prviosly selected
            filters['Effective Date RangeStart'] = null;
            filters['Effective Date RangeEnd'] = null;
            filterSearch(convertFiltersToQs());
        }
    }, [filters]);

    useEffect(() => {
        if (filters['Date Range'] === 'CUSTOM') {
            setisCustomRangeSet(false);
            if (filters['Custom Date RangeStart'] || filters['Custom Date RangeEnd']) {
                filterSearch(convertFiltersToQs());
            }
        } else {
            // If user has prviosly selected
            filters['Custom Date RangeStart'] = null;
            filters['Custom Date RangeEnd'] = null;
            setisCustomRangeSet(true);
            filterSearch(convertFiltersToQs());
        }
    }, [filters]);

    const getComponent = (filter: IFilterConfig) => {
        switch (filter.componentType) {
            case 'STATUS':
                return (
                    <DocStatus
                        displayName={filter.displayName}
                        name={filter.name}
                        value={filters[filter.name] ?? ''}
                        options={STATUS_DATA}
                        updateFilters={updateFilters}
                        type={filter.type ? filter.type : ''}
                    />
                );
            case 'EFFECTIVITY':
                return (
                    <Effectivity
                        displayName={filter.displayName}
                        name={filter.name}
                        value={filters[filter.name] ? filters[filter.name] : 'IN_SUNSET'}
                        options={EFFECTIVITY_DATA}
                        updateFilters={updateFilters}
                    />
                );
            case 'DATE_RANGE':
                return (
                    <DateRangefrom
                        displayName={filter.displayName}
                        name={filter.name}
                        value={filters[filter.name]}
                        options={DATE_RANGE_DATA}
                        updateFilters={updateFilters}
                        placeholder={filter.placeholder}
                    />
                );

            case 'TEXT_INPUT':
                return (
                    <TextInput
                        displayName={filter.displayName}
                        value={textFilters[filter.name]}
                        name={filter.name}
                        updateFilters={updateFilters}
                    />
                );
            case 'USER_SEARCH':
                return (
                    <UserSearch
                        displayName={filter.displayName}
                        name={filter.name}
                        value={filters[filter.name] ? filters[filter.name] : null}
                        updateFilters={updateFilters}
                        placeholder={filter.placeholder}
                    />
                );
            case 'DOUBLE_DATE':
                if (filter.name === 'Custom Date Range') {
                    return (
                        <DoubleDateFilter
                            displayName={filter.displayName}
                            name={filter.name}
                            fromDate={filters[`${filter.name}Start`]}
                            toDate={filters[`${filter.name}End`]}
                            updateFilters={updateFilters}
                            customselected={isCustomRangeSet}
                        />
                    );
                }
                return (
                    <DoubleDateFilter
                        displayName={filter.displayName}
                        name={filter.name}
                        fromDate={filters[`${filter.name}Start`]}
                        toDate={filters[`${filter.name}End`]}
                        updateFilters={updateFilters}
                    />
                );
            case 'DOC_TYPE':
                return (
                    <DocTypeFilter
                        displayName={filter.displayName}
                        name={filter.name}
                        value={
                            filters[filter.name]
                                ? filters[filter.name]
                                : { value: '', label: 'All' }
                        }
                        updateFilters={updateFilters}
                    />
                );
            case 'GROUP_NAME':
                return (
                    <GroupNameFilter
                        displayName={filter.displayName}
                        name={filter.name}
                        updateFilters={updateFilters}
                        placeholder="Select Group"
                    />
                );
            case 'SELECT_FROM_OPTION':
                return (
                    <SelectFromOptions
                        displayName={filter.displayName}
                        name={filter.name}
                        value={filters[filter.name]}
                        updateFilters={updateFilters}
                        options={filter.options ? filter.options : []}
                        placeholder={filter.placeholder}
                    />
                );
            default:
                return <NotApplicable />;
        }
    };
    return (
        <div className="filters">
            <div className="heading">
                <div>
                    <h4>{FILTERS.LABEL.FILTERS}</h4>
                    {documentCount ? (
                        <span>{`${documentCount} Result(s)`} </span>
                    ) : (
                        <span>{FILTERS.LABEL.NO_RESULT}</span>
                    )}
                </div>
                <button
                    type="button"
                    disabled={filtersConfig.length === 0}
                    onClick={() => {
                        setFilterToDefault();
                        resetFilters();
                        resetTextFilters();
                    }}
                >
                    {usedBy === 'SEARCH' ? 'Clear Filters' : FILTERS.BUTTON.RESET}
                </button>
            </div>
            <div className="filter-list">
                {loading ? (
                    <>
                        <SkeletonFilter />
                        <SkeletonFilter />
                        <SkeletonFilter />
                        <SkeletonFilter />
                    </>
                ) : filtersConfig.length > 0 ? (
                    filtersConfig.map((filter) => (
                        <div key={filter.name}>{getComponent(filter)}</div>
                    ))
                ) : (
                    <div />
                )}
                {usedBy !== 'SEARCH' && (
                    <Button
                        className="primary-btn"
                        type="button"
                        disabled={filtersConfig.length === 0}
                        onClick={() => showResults()}
                        isLoading={loading}
                    >
                        {FILTERS.BUTTON.SHOW_RESULTS}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Filters;
