/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { useDocumentContext } from '../../contexts/document-provider';
import './checklistComponent.scss';
import { useTaskDetailContext } from '../../contexts/task-details-provider';

interface Props {
    isDisabled: boolean;
}

const ChecklistComponent: React.FC<Props> = ({ isDisabled }) => {
    const { docVersionCheckedInFlag, checklistErrorInfo } = useDocumentContext();

    let {
        docTypeInfo: docTypeInfo1,
        checklist: checklist1,
        setchecklist: setchecklist1,
    } = useDocumentContext();

    if (window.location.pathname.includes('/task-details')) {
        docTypeInfo1 = useTaskDetailContext().docTypeInfo;
        checklist1 = useTaskDetailContext().checklist;
        setchecklist1 = useTaskDetailContext().setchecklist;
    }

    const handleRadioChange = (
        changedItem: { question: any; value: null | string },
        newValue: null | string,
    ) => {
        setchecklist1(
            (prevState) =>
                prevState &&
                prevState.map((item) => {
                    if (item.question === changedItem.question) {
                        return { ...item, value: newValue };
                    }
                    return item;
                }),
        );
    };

    return (
        <div className="checklist">
            {(docTypeInfo1.allowDCO || docTypeInfo1.isDCO) && Array.isArray(checklist1) &&
                checklist1?.map((item) => (
                    <div
                        key={item.question}
                        className={
                            // eslint-disable-next-line no-nested-ternary
                            docTypeInfo1.allowDCO
                                ? (!checklistErrorInfo.isValid && checklistErrorInfo.errorFields.includes(item.question)) ? 'question-container error' : 'question-container'
                                : (!checklistErrorInfo.isValid && checklistErrorInfo.errorFields.includes(item.question)) ? 'question-container isDCO error' : 'question-container isDCO'
                        }
                    >
                        <div className="question">
                            <label className="mandatory-field">{item.question}</label>
                        </div>
                        <div className="label">
                            <label className="yes">
                                <input
                                    className="input"
                                    type="radio"
                                    name={item.question}
                                    value="yes"
                                    checked={item.value === 'yes'}
                                    onChange={() => handleRadioChange(item, 'yes')}
                                    disabled={isDisabled || docVersionCheckedInFlag}
                                />
                                Yes
                            </label>
                            <label className="no">
                                <input
                                    className="input"
                                    type="radio"
                                    name={item.question}
                                    value="no"
                                    checked={item.value === 'no'}
                                    onChange={() => handleRadioChange(item, 'no')}
                                    disabled={isDisabled || docVersionCheckedInFlag}
                                />
                                N/A
                            </label>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ChecklistComponent;
