/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { toast } from 'react-toastify';
import { Column, useTable, usePagination, useExpanded } from 'react-table';
import ReactTooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';

import { IOptionValue } from '../../../interfaces';
import { IField, IDocumentData, IFieldsData } from '../../../interfaces/document';
import CONSTANTS from '../../../constants';
import { getDocumentsReference } from '../../../API/fields';
import { getDocumentsDetails, getDocumentsReferenceDetails } from '../../../API/document';
import './LinkedDocuments.scss';

import { CaretDownSvg, CaretRightSvg, RemoveIconSvg } from '../../../assets/images';
import { NoDocumentsFound, NoDocumentsReferenceFound, StatusColumn } from '../../General';
import { SkeletonText } from '../../Skeleton';
import { IDocumentAction } from '../../../reducers/document-reducer';
import Button from '../../button/Button';
import { useGAEvents } from '../../../hooks/useGoogleAnalytics';
import { useDocumentContext } from '../../../contexts/document-provider';
import STATIC_CONTENT from '../../../constants/StaticContent';
import { useEventsHeight } from '../../../contexts/events-height-provider';
// eslint-disable-next-line import/no-cycle
import ImportantFieldsDupl from '../../important-fields/ImportantFieldsDupl';

const animatedComponents = makeAnimated();
interface Props {
    field: IField;
    isDisabled: boolean;
    value: string[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
    isEditable: boolean;
    isChild: boolean;
}

const LinkedDocuments: React.FC<Props> = ({
    field,
    isDisabled,
    value,
    error,
    documentDispatch,
    isEditable,
    isChild,
}) => {
    const { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedDocument, setSelectedDocument] = useState<IDocumentData>({
        label: '',
        value: '',
        documentId: 0,
        documentVersionId: 0,
    });
    const [documentList, setDocumentList] = useState<(string | number)[]>(value);
    const [documentDetails, setDocumentDetails] = useState([]);
    const { saveFieldsData, updateFieldsData, documentId, isFieldsDataSaved, fieldsData } =
        useDocumentContext();
    const [completed, setCompleted] = useState(0);
    const { triggerEvent } = useGAEvents();
    const history = useHistory();
    const { updateHeight } = useEventsHeight();

    const queryObj = qs.parse(search, { ignoreQueryPrefix: true });

    const getDocumentsDetail = async (documents: (string | number)[]) => {
        setDocumentList(documents);
        let docList = documents;
        if (documents !== undefined && documents.length > 0) {
            if (typeof documents[0] === 'object') {
                docList = documents.map((doc: any) => doc.value);
            }

            const documentsDetailsRes = await getDocumentsReferenceDetails(docList);
            if (documentsDetailsRes?.apiStatus === 'SUCCESS') {
                setDocumentDetails(documentsDetailsRes.data ? documentsDetailsRes.data : []);
            }
        } else {
            setDocumentDetails([]);
        }
    };

    const getDocumentsDetailByState = async () => {
        let list: (number | string)[] = [];
        list = value;
        if (queryObj.newDocversionId) {
            if (!list.includes(Number(queryObj.newDocversionId))) {
                list.push(Number(queryObj.newDocversionId));
                saveFieldsData(false);
            }
        }
        setDocumentList(list);
    };

    const dispatchData = async (documents: (number | string)[]) => {
        const tempstateDocList = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const docItem of documents) {
            tempstateDocList.push(docItem);
        }
        if (documentDispatch) {
            documentDispatch({
                type: 'reference',
                inputName: field.name,
                value: tempstateDocList,
            });
            setCompleted(completed + 1);
        }
    };

    const promiseOptions = async (inputValue: string): Promise<IDocumentData[]> => {
        setSearchText(inputValue);
        const party = fieldsData.filter((field) => field.name === 'partyName');
        if (party.length) {
            const partyName = party[0].value;
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) => {
                if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                    const dynamicOptions = await getDocumentsReference(
                        field.id,
                        inputValue,
                        documentList,
                        partyName,
                    );
                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                        resolve(dynamicOptions.dynamicData);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            });
        }
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const dynamicOptions = await getDocumentsReference(
                    field.id,
                    inputValue,
                    documentList,
                );
                if (dynamicOptions?.apiStatus === 'SUCCESS') {
                    resolve(dynamicOptions.dynamicData);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        if (completed) {
            updateFieldsData();
        }
    }, [completed]);

    const handleAddDocument = async () => {
        if (selectedDocument.documentId !== 0) {
            const list: (string | number)[] = documentList;
            list.push(selectedDocument?.documentVersionId);
            setDocumentList(list);
            dispatchData(list);
            setSelectedDocument({ label: '', value: '', documentId: 0, documentVersionId: 0 });
        }
    };

    const handleRemoveDocument = async (
        activeVersionId: number,
        currDocId: number,
        documentList: (number | string)[],
    ) => {
        const updatedDocumentList = documentList.filter((document) => document !== activeVersionId);
        // eslint-disable-next-line no-param-reassign
        // getDocumentsDetail(updatedDocumentList);
        dispatchData(updatedDocumentList);
    };

    const handleChange = async (option: any) => {
        setSelectedDocument(option);
    };

    const DocNumberForLink = (row: any) => {
        const {
            original: { documentNumber, id },
        } = row;

        return (
            <Link
                className="link-style"
                target="_blank"
                style={{ textDecoration: 'none' }}
                to={{
                    pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${id}`,
                }}
            >
                <SkeletonText text={documentNumber} width={200} />
            </Link>
        );
    };

    const ActionColumn = (element: any) => {
        const {
            element: { id: currDocId, activeVersionId, documentNumber },
        } = element;

        return (
            <div className="action">
                <span
                    aria-hidden="true"
                    style={{ cursor: 'pointer' }}
                    data-tip
                    data-for="remove-action"
                    className="action-icon"
                    onClick={() => handleRemoveDocument(activeVersionId, currDocId, documentList)}
                >
                    <RemoveIconSvg />
                </span>
                <ReactTooltip place="top" type="dark" effect="solid" id="remove-action">
                    {' '}
                    <span>Remove</span>
                </ReactTooltip>
            </div>
        );
    };

    const FIELDS_COLUMN: Column[] = [
        {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => {
                const { activeDocumentVersion }: { activeDocumentVersion?: any } = row.original;
                const data = activeDocumentVersion.data.LinkedDocuments;
                return data && data.length ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <CaretDownSvg /> : <CaretRightSvg />}
                    </span>
                ) : (
                    <></>
                );
            },
        },
        {
            Header: 'Document Number',
            accessor: 'documentNumber',
            Cell: ({ row }) => <DocNumberForLink {...row} />,
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'State',
            accessor: 'state',
            Cell: ({ value }) => <StatusColumn value={value} />,
        },
        {
            Header: 'Action',
            accessor: (row) => row,
            Cell: ({ value }) => <ActionColumn element={value} />,
        },
    ];

    const READMODE_FIELDS_COLUMN: Column[] = [
        {
            // Make an expander cell
            Header: () => null, // No header
            id: 'expander', // It needs an ID
            Cell: ({ row }) => {
                const { activeDocumentVersion }: { activeDocumentVersion?: any } = row.original;
                const data = activeDocumentVersion.data.LinkedDocuments;
                return data && data.length ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <CaretDownSvg /> : <CaretRightSvg />}
                    </span>
                ) : (
                    <></>
                );
            },
        },
        {
            Header: 'Document Number',
            accessor: 'documentNumber',
            Cell: ({ row }) => <DocNumberForLink {...row} />,
        },
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'State',
            accessor: 'state',
            Cell: ({ value }) => <StatusColumn value={value} />,
        },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const columns = useMemo(() => FIELDS_COLUMN, []);
    let columns = null;
    if (isEditable) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        columns = useMemo(() => FIELDS_COLUMN, [documentList, isEditable]);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        columns = useMemo(() => READMODE_FIELDS_COLUMN, [documentList, isEditable]);
    }

    const renderRowSubComponent = React.useCallback(({ row }) => {
        // console.log(row);
        const {
            original: { activeDocumentVersion },
        } = row;
        const { documentTypeVersion } = activeDocumentVersion;
        const linkedDocuments = activeDocumentVersion.data.LinkedDocuments;
        const fieldsData: IFieldsData = {
            name: 'LinkedDocuments',
            value: linkedDocuments,
        };
        const {
            fieldSet: { fields },
        } = documentTypeVersion;
        return (
            <ImportantFieldsDupl
                isDisabled={isDisabled}
                fields={[field]}
                fieldsData={[fieldsData]}
                documentDispatch={null}
            />
        );
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { pageIndex, pageSize, expanded },
    } = useTable(
        {
            columns,
            data: documentDetails || [],
            manualPagination: true,
            autoResetPage: false,
        },
        useExpanded,
        usePagination,
    );

    useEffect(() => {
        getDocumentsDetailByState();
    }, [queryObj.newDocversionId]);

    // useEffect(() => {
    //     // dispatchData(documentList);
    //     getDocumentsDetail(documentList);
    // }, [documentList]);

    useEffect(() => {
        setDocumentList(value);
        getDocumentsDetail(value);
    }, [value]);

    useEffect(() => {
        updateHeight();
    });

    return (
        <>
            <div
                className={`document-reference ${isChild ? 'child' : ''} ${
                    field && field.name === 'LinkedDocuments' ? 'border' : ''
                }`}
            >
                <div className="ref-doc-title">
                    <label>Parent Documents</label>
                </div>
                <div
                    className={`${
                        documentDispatch !== null && isEditable ? 'search-document' : ''
                    } ${field && field.name === 'LinkedDocuments' ? 'refPadding' : ''}`}
                >
                    {documentDispatch !== null && isEditable ? (
                        <>
                            <AsyncSelect
                                name="single-select"
                                components={animatedComponents}
                                id="single-select"
                                className={`react-select ${error ? 'error' : ''}`}
                                classNamePrefix="select"
                                value={selectedDocument}
                                getOptionLabel={(e) => `${e.value} | ${e.label} `}
                                isMulti={false}
                                isDisabled={isDisabled}
                                isLoading={loading}
                                placeholder={isDisabled ? '' : 'Start Typing...'}
                                loadOptions={promiseOptions}
                                noOptionsMessage={() =>
                                    searchText.length >= 3
                                        ? 'No results found'
                                        : 'Start typing minimum 3 characters'
                                }
                                onChange={(option) => handleChange(option)}
                            />
                            {/* <button type="button" onClick={() => handleAddDocument()}>
                                Add
                            </button> */}
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => handleAddDocument()}
                                disabled={isFieldsDataSaved}
                                isLoading={isFieldsDataSaved}
                            >
                                Add
                            </Button>
                        </>
                    ) : null}
                </div>
                <div>
                    <div
                        className={`roles-table ${
                            field && field.name === 'LinkedDocuments' ? 'refPadding' : ''
                        }`}
                    >
                        <div className="table-section">
                            <table {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps({
                                                        style: {
                                                            minWidth: column.minWidth
                                                                ? column.minWidth
                                                                : 'auto',
                                                        },
                                                    })}
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <React.Fragment key={row.id}>
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map((cell) => (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                                {row.isExpanded ? (
                                                    <tr key={row.id}>
                                                        <td
                                                            colSpan={visibleColumns.length}
                                                            className="document-details"
                                                        >
                                                            {renderRowSubComponent({ row })}
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {page.length === 0 && (
                                <NoDocumentsReferenceFound heading="NO DOCUMENT" />
                            )}
                        </div>
                    </div>
                    {/* {showModal && (
                <AddRoleModal isModalOpen={showModal} onModalClose={setShowModal} isEdit />
            )} */}
                </div>
            </div>
        </>
    );
};

export default LinkedDocuments;
