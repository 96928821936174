/* eslint-disable no-restricted-syntax */
/* eslint-disable max-lines-per-function */
// import { eventManager } from 'react-toastify/dist/core';
import { IAPIResponseStatus, INotifications } from '../interfaces';
import { IDocEvent } from '../interfaces/document';
import {
    INotificationFilterable,
    INotificationGroup,
    INotificationList,
} from '../interfaces/settings';
import { getUserFullName } from '../utils';
import { deleteRequest, getRequest, postRequest } from '../utils/api-request';

const getNotifications = async (
    email: string,
    olderThan: number,
    newerThan: number,
    pageSize = 10,
): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          notifications: INotifications[];
          notificationIds: { min: number; max: number };
      }
    | undefined
> => {
    try {
        let queryParams = `email=${email}&page_size=${pageSize}`;
        if (newerThan) {
            queryParams += `&newer_than=${newerThan}`;
        } else if (olderThan) {
            queryParams += `&older_than=${newerThan}`;
        } else {
            queryParams += `&newer_than=1`;
        }
        const res = await getRequest(`notifications/in-app?${queryParams}`);
        if (res?.apiStatus === 'SUCCESS') {
            const notifications: INotifications[] = [];
            const data = res.response;
            const ids: number[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const notification of data) {
                ids.push(notification.id);
                notifications.push({
                    id: notification.id,
                    date: new Date(notification.timestamp),
                    isRead: notification.isRead,
                    message: notification.content,
                });
            }

            const notificationIds = {
                min: Math.min.apply(null, ids),
                max: Math.max.apply(null, ids),
            };

            return { apiStatus: res.apiStatus, notifications, notificationIds };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const updateReadNotification = async (
    startId: number,
    endId: number,
): Promise<IAPIResponseStatus | undefined> => {
    try {
        const res = await postRequest(`notifications/in-app/status`, {
            startId,
            endId,
            status: true,
        });
        if (res) {
            return res?.apiStatus;
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const clearSingleNotification = async (id: number): Promise<IAPIResponseStatus | undefined> => {
    try {
        const res = await postRequest(`notifications/in-app/clear`, {
            id,
            cleared: true,
        });
        if (res) {
            return res?.apiStatus;
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const clearAllNotifications = async (): Promise<IAPIResponseStatus | undefined> => {
    try {
        const res = await deleteRequest(`notifications/in-app/clear-all`);
        if (res) {
            return res?.apiStatus;
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getDocumentEvents = async (
    documentId: number,
    allowReviseByAnyone: boolean
): Promise<{ apiStatus: IAPIResponseStatus; timeline: IDocEvent[] } | undefined> => {
    try {            
        const res = await postRequest('events/', {
            bodyFilters: {
                'document.id': documentId,
            },
            allowReviseByAnyone
        });

        if (res?.apiStatus === 'SUCCESS') {
            const timeline: IDocEvent[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const t of res.response) {
                // console.log(t);
                const event: IDocEvent = {
                    date: new Date(t.headers.timestamp),
                    eventType: t.headers.name,
                };
                if(t.headers.name === 'Document.EditedByApprover'){
                    event.actionTakenBy = getUserFullName(
                        t.body.document.editor.firstName,
                        t.body.document.editor.lastName,
                    );
                }
                if (t.headers.name === 'Document.Approved') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                    if (t.body.document.actor.id !== t.body.document.approver.id) {
                        event.delegator = getUserFullName(
                            t.body.document.approver.firstName,
                            t.body.document.approver.lastName,
                        );
                    }
                }
                if (t.headers.name === 'Document.DocumentUnClaimedEvent') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.approver.firstName,
                        t.body.document.approver.lastName,
                    );
                }
                if (t.headers.name === 'Document.DocumentClaimedEvent') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.approver.firstName,
                        t.body.document.approver.lastName,
                    );
                }
                if (t.headers.name === 'Document.CommentAdded') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.newComment.actor.firstName,
                        t.body.document.newComment.actor.lastName,
                    );
                    event.actionTakenOnComment = t.body.document.newComment.action;
                    event.comment = t.body.document.newComment.comment.data;
                }
                if (t.headers.name === 'Document.Submitted' && t.body.document.submitter) {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.submitter.firstName,
                        t.body.document.submitter.lastName,
                    );
                }
                if (t.headers.name === 'Document.Created' && t.body.document.owner) {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.owner.firstName,
                        t.body.document.owner.lastName,
                    );
                }
                if (t.headers.name === 'Document.Withdrawn' && t.body.document.withdrawer) {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.withdrawer.firstName,
                        t.body.document.withdrawer.lastName,
                    );
                }
                if(t.headers.name === 'Document.DocAddedToDCO') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                    event.childDocNumber = t.body.document.childDocument.documentNumber;
                }
                if (t.headers.name === 'Document.Rejected') {
                    if (t.body.document.actor) {
                        event.actionTakenBy = getUserFullName(
                            t.body.document.actor.firstName,
                            t.body.document.actor.lastName,
                        );
                        if (t.body.document.actor.id !== t.body.document.approver.id) {
                            event.delegator = getUserFullName(
                                t.body.document.approver.firstName,
                                t.body.document.approver.lastName,
                            );
                        }
                    }
                }
                if (t.headers.name === 'Document.AssignedToApprover') {
                    if (t.body.document.group) {
                        event.group = t.body.document.group;
                    } else {
                        event.actionTakenBy = getUserFullName(
                            t.body.document.approver.firstName,
                            t.body.document.approver.lastName,
                        );
                    }
                }
                if (t.headers.name === 'Document.Forwarded') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                    event.additionalText = getUserFullName(
                        t.body.document.approver.firstName,
                        t.body.document.approver.lastName,
                    );
                    if (t.body.document.actor.id !== t.body.document.forwardingApprover.id) {
                        event.delegator = getUserFullName(
                            t.body.document.forwardingApprover.firstName,
                            t.body.document.forwardingApprover.lastName,
                        );
                    }
                }
                if (t.headers.name === 'Document.AdhocApproverAdded') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                    let addedApprovers = '';
                    // eslint-disable-next-line no-restricted-syntax
                    for (const approver of t.body.document.addedApprovers) {
                        addedApprovers += `${getUserFullName(
                            approver.firstName,
                            approver.lastName,
                        )}, `;
                    }
                    event.additionalText = addedApprovers;
                    if (t.body.document.actor.id !== t.body.document.addingApprover.id) {
                        event.delegator = getUserFullName(
                            t.body.document.addingApprover.firstName,
                            t.body.document.addingApprover.lastName,
                        );
                    }
                }
                if (t.headers.name === 'Document.AssignedToReviewer') {
                    event.actionTakenBy = t.body.document.reviewer
                        ? getUserFullName(
                              t.body.document.reviewer.firstName,
                              t.body.document.reviewer.lastName,
                          )
                        : '';
                }
                if (t.headers.name === 'Document.ReviewCompleted') {
                    event.actionTakenBy = t.body.document.reviewer
                        ? getUserFullName(
                              t.body.document.reviewer.firstName,
                              t.body.document.reviewer.lastName,
                          )
                        : '';
                }
                if (t.headers.name === 'Document.AssignedToDelegatee') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.approver.firstName,
                        t.body.document.approver.lastName,
                    );
                    event.delegator = getUserFullName(
                        t.body.document.delegatee.firstName,
                        t.body.document.delegatee.lastName,
                    );
                }
                if (t.headers.name === 'Owner.InactiveApproverRemoved') {
                    event.user1 = getUserFullName(
                        t.body.document.removedApprover.firstName,
                        t.body.document.removedApprover.lastName,
                    );
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                }
                if (t.headers.name === 'Owner.InactiveApproverReassigned') {
                    event.user1 = getUserFullName(
                        t.body.document.forwardingApprover.firstName,
                        t.body.document.forwardingApprover.lastName,
                    );
                    event.user2 = getUserFullName(
                        t.body.document.approver.firstName,
                        t.body.document.approver.lastName,
                    );
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                }
                if(t.headers.name === 'Document.OwnerReplaced') {
                    event.actionTakenBy = getUserFullName(
                        t.body.document.actor.firstName,
                        t.body.document.actor.lastName,
                    );
                    event.user1 = getUserFullName(
                        t.body.document.oldOwner.firstName,
                        t.body.document.oldOwner.lastName,
                    );
                    event.user2 = getUserFullName(
                        t.body.document.owner.firstName,
                        t.body.document.owner.lastName,
                    );
                }
                if(t.headers.name === 'Document.OwnerChanged') {
                    event.user1 = getUserFullName(
                        t.body.oldOwner.firstName,
                        t.body.oldOwner.lastName,
                    );
                    event.user2 = getUserFullName(
                        t.body.newOwner.firstName,
                        t.body.newOwner.lastName,
                    );
                }
                timeline.push(event);
            }
            return { apiStatus: res.apiStatus, timeline };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getNotificationFilters = async (): Promise<
    | {
          apiStatus: IAPIResponseStatus;
          notificationFilters: INotificationGroup[];
          selectedEvents: string[];
      }
    | undefined
> => {
    try {
        const res = await getRequest(`notifications-admin/filters`);
        if (res?.apiStatus === 'SUCCESS') {
            const notificationFilters: INotificationGroup[] = [];
            const selectedEvents: string[] = [];
            for (const filterables of res.response) {
                selectedEvents.push(filterables.event);
                for (const filter of filterables.notificationGroups) {
                    const notifications: INotificationList[] = [];
                    for (const notification of filter.notifications) {
                        notifications.push({
                            name: notification.name,
                            type: notification.type,
                            value: notification.isEnabled,
                        });
                    }
                    notificationFilters.push({
                        description: filter.description ? filter.description : filter.groupName,
                        groupName: filter.groupName,
                        notifications,
                        event: filterables.event,
                    });
                }
            }

            return { apiStatus: res.apiStatus, notificationFilters, selectedEvents };
        }

        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const setNotificationFilters = async (
    notificationFilters: INotificationGroup[],
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const filters: { name: string; value: boolean }[] = [];
        for (const filters1 of notificationFilters) {
            for (const notification of filters1.notifications) {
                filters.push({
                    name: notification.name,
                    value: notification.value,
                });
            }
        }

        const res = await postRequest('notifications-admin/filters', { filters });
        if (res?.apiStatus) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getNotificationFilterable = async (): Promise<
    | { apiStatus: IAPIResponseStatus; notificationFilterables: INotificationFilterable[] }
    | undefined
> => {
    try {
        const res = await getRequest(`notifications-admin/filterables`);
        if (res?.apiStatus === 'SUCCESS') {
            const notificationFilterables: INotificationFilterable[] = [];
            for (const filterables of res.response) {
                const notificationGroup: INotificationGroup[] = [];
                for (const filter of filterables.notificationGroups) {
                    const notifications: INotificationList[] = [];
                    for (const notification of filter.notifications) {
                        notifications.push({
                            name: notification.name,
                            type: notification.type,
                            value: true,
                        });
                    }
                    notificationGroup.push({
                        description: filter.description ? filter.description : filter.groupName,
                        notifications,
                        event: filterables.event,
                        groupName: filter.groupName,
                    });
                }
                notificationFilterables.push({
                    event: filterables.event,
                    eventDescription: filterables.eventDescription
                        ? filterables.eventDescription
                        : filterables.event,
                    notificationGroup,
                });
            }

            return { apiStatus: 'SUCCESS', notificationFilterables };
        }

        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export {
    getNotifications,
    getDocumentEvents,
    getNotificationFilters,
    setNotificationFilters,
    getNotificationFilterable,
    updateReadNotification,
    clearSingleNotification,
    clearAllNotifications,
};
