import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const useInitializeGA = (): void => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`, {
        debug: false,
    });
};

const useGAUser = (employeeId: number): void => {
    ReactGA.set({ userId: employeeId });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGAPageView = (): { triggerPageView: (loc?: any) => void } => {
    const location = useLocation();
    const triggerPageView = (loc = location) => {
        ReactGA.pageview(`${loc.pathname}${loc.search}`);
    };

    return { triggerPageView };
};

type IEvents = 'DMS_CREATE' | 'DMS_SEARCH';

type IEventsType = {
    [key in IEvents]: {
        category: string;
        action: string;
    };
};

const EVENTS_TYPE: IEventsType = {
    DMS_CREATE: {
        category: 'CREATE',
        action: 'User clicked create',
    },
    DMS_SEARCH: {
        category: 'SEARCH',
        action: 'User searched document',
    },
};

const useGAEvents = (): { triggerEvent: (type: IEvents) => void } => {
    const triggerEvent = (type: IEvents): void => {
        ReactGA.event({
            category: EVENTS_TYPE[type].category,
            action: EVENTS_TYPE[type].action,
        });
    };

    return { triggerEvent };
};

export { useInitializeGA, useGAPageView, useGAEvents, useGAUser };
