/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react'
import "./Toggle.scss"

interface Props {
    isChecked: boolean;
    isDisabled: boolean;
    onclick: () => void;
}

const Toggle: React.FC<Props> = ({isChecked, isDisabled, onclick}: Props) => {
    const toggle = useRef<HTMLInputElement>(null);
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            className={`toggle ${isDisabled ? 'disabled' : ''}`}
            onClick={() => !isDisabled && onclick()}
        >
            <input
                ref={toggle}
                checked={isChecked}
                disabled={isDisabled}
                className="switch"
                type="checkbox"
                onChange={() => {}}
            />
            <span className="slider round" />
        </div>
    );
}

export default Toggle
