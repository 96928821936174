import React, { useState } from 'react';
import { ArrowUpCircle } from 'react-bootstrap-icons';

const ScrollButton: React.FC = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0, // set to 150 to go to user guide
            behavior: 'smooth',
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
      <ArrowUpCircle onClick={scrollToTop} style={{ cursor:'pointer', height: '3rem', width: '3rem', position:'fixed', right: '2rem', bottom: '2rem' , display: visible ? 'inline' : 'none' }} />
    );
};

export default ScrollButton;
